import { DEFAULT_COUNTRY_CODE } from '@iabbb/utils/culture/constants';

/**
 * Gets the three letter country code from the cultureId and defaults to USA
 * @param {string} cultureId The locale/culture id, ie 'en-us'
 */
const getCountryCodeFromCultureOrDefault = (cultureId?: string): string => {
  if (!cultureId) {
    return DEFAULT_COUNTRY_CODE;
  }

  switch (cultureId.toLowerCase()) {
    case 'en-us':
      return 'USA';
    case 'en-ca':
      return 'CAN';
    default:
      return DEFAULT_COUNTRY_CODE;
  }
};

export default getCountryCodeFromCultureOrDefault;
