import styled from '@emotion/styled';

import Clear from '@iabbb/shared/components/General/InputGroup/styles/Clear';

import Container from './Container';
import Input from './Input';
import Suggestions from './Suggestions';

const Typeahead = styled.div``;

Typeahead.Clear = Clear;
Typeahead.Container = Container;
Typeahead.Input = Input;
Typeahead.Suggestions = Suggestions;

Typeahead.displayName = 'StyledTypeahead';
export default Typeahead;
