import { createSelector } from 'reselect';

import { getBaseUrl } from '@iabbb/shared/states/Global/selectors';
import { DEFAULT_BBB_ID } from '@iabbb/utils/bbbInfo/constants';
import getGeoUrlOrFallback from '@iabbb/utils/bbbInfo/getGeoUrlOrFallback';

import getIn from '@iabbb/utils/object/getIn';
import CultureData from '@iabbb/utils/culture/allCultureData';
import {
  BbbResource,
  BbbInfoResource,
  PopularCategoriesResourceList,
  LocationContactResource,
} from '@iabbb/utils/bbbInfo/types';
import { UserResource } from '@iabbb/utils/types';
import { Culture } from '@iabbb/utils/culture/allCultureData';

const selectCookieByName = (cookieName: string) => (state: Record<string, object>) =>
  getIn(state, ['user', 'cookies', cookieName]);

export const selectUserState = (state: Record<string, object>): UserResource => getIn(state, ['user']);
const selectCultures = () => CultureData;
const selectUserLocationBbbInfo = (state: Record<string, object>): BbbInfoResource =>
  getIn(state, ['user', 'location', 'bbbInfo']);

const selectPopularCategoriesList = (state: Record<string, object>): PopularCategoriesResourceList[] | [] =>
  getIn(state, ['user', 'location', 'popularCategories', 'list'], []);
const selectDefaultPopularCategoriesList = (state: Record<string, object>): PopularCategoriesResourceList[] | [] =>
  getIn(state, ['user', 'defaultLocation', 'popularCategories', 'list'], []);

const getIsUserFromEu =
  () =>
  (state: Record<string, object>): boolean =>
    getIn(state, ['user', 'countryOfOrigin', 'isInEu'], false);

const getUserLocation =
  () =>
  (state: Record<string, object>): BbbResource =>
    getIn(state, ['user', 'location']);

const getUserLocationBbbInfo =
  () =>
  (state: Record<string, object>): BbbInfoResource | null =>
    getIn(state, ['user', 'location', 'bbbInfo'], null);

const getHasUserLocalBbbSelected = () =>
  createSelector(selectUserLocationBbbInfo, (bbbInfo) => !(bbbInfo.bbbId === DEFAULT_BBB_ID));

const getUserLocationBbbContact = () =>
  createSelector(selectUserLocationBbbInfo, (bbbInfoState): LocationContactResource | null =>
    getIn(bbbInfoState, ['locations', 'byId', getIn(bbbInfoState, ['locations', 'primary']), 'contact'], null),
  );

const getUserLocationBbbCountryCode =
  () =>
  (state: Record<string, object>): string | null =>
    getIn(
      state,
      [
        'user',
        'location',
        'bbbInfo',
        'locations',
        'byId',
        getIn(state, ['user', 'location', 'bbbInfo', 'locations', 'primary']),
        'address',
        'country',
      ],
      null,
    );
const getUserCulture = () =>
  createSelector([selectCultures, selectUserState], (culturesState, userState): Culture | null =>
    userState.cultureId ? getIn(culturesState, ['byId', userState.cultureId]) : null,
  );

const getUserCultureId =
  () =>
  (state: Record<string, object>): string =>
    getIn(state, ['user', 'cultureId']);

const getLocaleUrl = () =>
  createSelector(getUserLocation(), getBaseUrl(), (savedLoc, baseUrl) => getGeoUrlOrFallback(savedLoc, baseUrl));

const getCategoriesUrl =
  () =>
  (state: Record<string, object>): string =>
    getIn(state, ['user', 'location', 'popularCategories', 'url']);
const getPopularCategoriesList = () => selectPopularCategoriesList;

const getPopularCategoriesListOrDefault = () =>
  createSelector(
    [selectPopularCategoriesList, selectDefaultPopularCategoriesList],
    (userCategories, defaultCategories) => (userCategories.length > 0 ? userCategories : defaultCategories),
  );

export {
  getUserCulture,
  getUserLocation,
  getUserCultureId,
  getUserLocationBbbInfo,
  getHasUserLocalBbbSelected,
  getUserLocationBbbContact,
  getUserLocationBbbCountryCode,
  getIsUserFromEu,
  getLocaleUrl,
  getCategoriesUrl,
  getPopularCategoriesList,
  getPopularCategoriesListOrDefault,
  selectCookieByName,
};
