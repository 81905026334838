import { produce } from 'immer';

import createInitialState from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createInitialState';
import createReducer from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createReducer';
import * as actionTypes from '@iabbb/shared/containers/Header/FindTypeahead/actionTypes';
import reduceReducers from '@iabbb/context/Redux/reduceReducers';

export const initialState = createInitialState();

const { SELECT_CATEGORY } = actionTypes;

const findTypeaheadReducer = produce((draft, action) => {
  switch (action.type) {
    case SELECT_CATEGORY:
      draft.selected = action.selected;
      break;
    default:
  }
}, initialState);

const typeaheadReducer = createReducer({ actionTypes, initialState });

const reducer = reduceReducers(findTypeaheadReducer, typeaheadReducer, initialState);

export default reducer;
