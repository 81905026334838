import styled from '@emotion/styled';

// InputGroup.Clear
export default styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--bds-color-gray-70);
  cursor: pointer;
  display: flex;
  font-size: var(--bds-font-size-20);
  margin-inline-end: 0.5rem;
  padding: 0;

  &:focus {
    outline: 2px solid var(--bds-color-blue-light);

    &:not(:focus-visible) {
      outline: none;
    }
  }
`;
