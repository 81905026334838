import IconAngleDownSolid from '@iabbb/icons/AngleDownSolid';
import { getCultures } from '@iabbb/shared/states/Global/selectors';
import getIn from '@iabbb/utils/object/getIn';
import useClickAwayListener from '@iabbb/utils/useClickAwayListener';
import clsx from 'clsx';
import { useEffect, useId, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CountryCombobox from './styles';

const DefaultCultureId = 'en-us';

export interface CountryComboboxProps extends React.ComponentPropsWithoutRef<'div'> {
  menuItemClassName?: string;
  selectedId?: string;
  setSelectedId: (id: string) => void;
}

function CountryComboboxComponent({
  className,
  menuItemClassName,
  selectedId,
  setSelectedId,
}: CountryComboboxProps) {
  const { allIds: allCultureIds, byId: byCultureId } = useSelector(getCultures());
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const comboboxRef = useRef<HTMLDivElement>(null);
  const activeOptionRef = useRef<HTMLLIElement>(null);

  const labelId = useId();
  const listboxId = useId();

  const lookupCultureId = selectedId ? selectedId.toLowerCase() : DefaultCultureId;

  const selectedCulture = (allCultureIds as string[]).includes(lookupCultureId)
    ? getIn(byCultureId, [lookupCultureId])
    : getIn(byCultureId, [DefaultCultureId]);

  const [activeOptionIndex, setActiveOptionIndex] = useState(allCultureIds.findIndex((id) => id === selectedId));

  useClickAwayListener(
    ref,
    () => {
      setIsOpen(false);
      comboboxRef.current?.focus();
    },
    isOpen,
  );

  // Ensure active index is always set whenever selectedId or allCultureIds is updated, or when menu is opened.
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setActiveOptionIndex(allCultureIds.findIndex((id) => id === selectedId));
  }, [allCultureIds, isOpen, selectedId]);

  // Whenever menu is open, focus the active element.
  useEffect(() => {
    if (!activeOptionRef.current) {
      return;
    }
    if (!isOpen) {
      return;
    }
    activeOptionRef.current.focus();
  }, [activeOptionIndex, isOpen]);

  return (
    <CountryCombobox className={clsx('country-combobox', 'text-size-7', className)} ref={ref}>
      <span className="visually-hidden" id={labelId}>
        Country
      </span>
      <div
        aria-controls={listboxId}
        aria-expanded={isOpen}
        aria-labelledby={labelId}
        className={clsx('bg-gray-30', 'text-blue-dark')}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown' || e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setIsOpen(true);
          }
        }}
        tabIndex={0}
        ref={comboboxRef}
        role="combobox"
      >
        <img {...selectedCulture.flagImgAttrs} alt="" height="30" width="40" />
        <span aria-hidden translate="no">
          {selectedCulture.twoLetterIsoCountryCode}
        </span>
        <span className="visually-hidden">{selectedCulture.countryName}</span>
        <IconAngleDownSolid />
      </div>
      <ul
        aria-labelledby={labelId}
        className={clsx('list-reset', 'bg-gray-30', 'shadow-base')}
        id={listboxId}
        hidden={!isOpen}
        role="listbox"
      >
        {allCultureIds.map((cultureId, index: number) => {
          const culture = getIn(byCultureId, [cultureId]);
          return (
            <li
              aria-selected={selectedId === cultureId}
              className={clsx(menuItemClassName)}
              key={cultureId}
              onClick={() => {
                setSelectedId(cultureId);
                setIsOpen(false);
                comboboxRef.current?.focus();
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown') {
                  e.preventDefault();

                  if (index !== allCultureIds.length - 1) {
                    setActiveOptionIndex(index + 1);
                  }
                }

                if (e.key === 'ArrowUp') {
                  e.preventDefault();

                  if (index !== 0) {
                    setActiveOptionIndex(index - 1);
                  }
                }

                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();

                  setIsOpen(false);
                  setSelectedId(cultureId);
                  comboboxRef.current?.focus();
                }

                if (e.key === 'Escape') {
                  e.preventDefault();

                  setIsOpen(false);
                  comboboxRef.current?.focus();
                }

                if (e.key === 'Tab') {
                  setIsOpen(false);
                  setSelectedId(cultureId);
                }
              }}
              onMouseEnter={() => {
                setActiveOptionIndex(index);
              }}
              tabIndex={activeOptionIndex === index ? -1 : undefined}
              ref={activeOptionIndex === index ? activeOptionRef : undefined}
              role="option"
            >
              <img {...culture.flagImgAttrs} alt="" height="30" width="40" />
              {culture.countryName}
            </li>
          );
        })}
      </ul>
    </CountryCombobox>
  );
}

CountryComboboxComponent.displayName = 'CountrySelect';
export default CountryComboboxComponent;
