import { breakpoints, getMaxWidthInEms } from '@iabbb/shared/styles/media';

interface HideAt {
  smUp: string | undefined;
  xsDown: string;
  smDown: string;
  mdUp: string;
  mdDown: string;
  lgUp: string;
}

const breakpointKeys = Object.keys(breakpoints);

export const styles = breakpointKeys.reduce((acc, size, i) => {
  const nextSize = breakpointKeys[i + 1];

  const up = breakpoints[size] ? `(min-width: ${breakpoints[size]})` : '';

  const down = nextSize ? `(max-width: ${getMaxWidthInEms(breakpoints[nextSize])})` : '';

  const upAndDown = down ? (up ? `${up} and ${down}` : down) : up;

  acc[size] = `
    @media ${upAndDown} {
      display: none !important;
    }
  `;

  if (up) {
    acc[`${size}Up`] = `
      @media ${up} {
        display: none !important;
      }
    `;
  }

  if (down) {
    acc[`${size}Down`] = `
      @media ${down} {
        display: none !important;
      }
    `;
  }

  return acc;
}, {});

const classNames = Object.keys(styles).reduce((acc, key) => {
  acc[key] = `bbb__hideAt-${key}`;
  return acc;
}, {} as HideAt);

export default classNames;
