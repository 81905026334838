import bdsTokens from '@iabbb/bds/tokens.json';

import { convertEmStringToPixels } from './utils';

const breakpoints = {
  xs: 0,
  sm: bdsTokens['breakpoint-sm'],
  md: bdsTokens['breakpoint-md'],
  lg: bdsTokens['breakpoint-lg'],
  xl: bdsTokens['breakpoint-xl'],
};

export const breakpointsInPixels = {
  xs: 0,
  sm: convertEmStringToPixels(breakpoints.sm),
  md: convertEmStringToPixels(breakpoints.md),
  lg: convertEmStringToPixels(breakpoints.lg),
  xl: convertEmStringToPixels(breakpoints.xl),
};

export default breakpoints;
