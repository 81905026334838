import { useState, useEffect } from 'react';

function useShowAndHide(show: boolean, duration = 1500): boolean {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (!show) {
      return () => {};
    }

    setVisible(true);
    const hideTimer = setTimeout(() => {
      setVisible(false);
    }, duration);

    return () => {
      clearTimeout(hideTimer);
    };
  }, [show, duration]);

  return visible;
}

export default useShowAndHide;
