import styled from '@emotion/styled';

type TitleContainerProps = {
  isHQ: boolean;
};

export default styled.div<TitleContainerProps>`
  ${({ isHQ }) =>
    isHQ && {
      display: 'flex',
    }}
`;
