import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSealUrls, getAcSealUrls } from './selectors';

import Suggestion from '@iabbb/shared/components/Typeahead/Suggestion/styles';
import useCloudinaryImgAttributes from '@iabbb/utils/cloudinary/useCloudinaryImgAttributes';

interface SuggestionComponentProps {
  title: string;
  altTitleHighlight?: string;
  secondaryTitle?: string | null;
  metaTags?: string[] | string | null;
  entityType: string;
}

const SuggestionComponent = ({ suggestion }: { suggestion: SuggestionComponentProps }) => {
  const sealUrls = useSelector(getSealUrls());
  const sealAcUrls = useSelector(getAcSealUrls());
  const isAccreditedBusiness = useMemo(
    () =>
      suggestion.entityType === 'Organization' &&
      suggestion.metaTags?.includes('accredited') &&
      !suggestion.metaTags.includes('charity'),
    [suggestion],
  );

  const isAccreditedCharity = useMemo(
    () =>
      suggestion.entityType === 'Organization' &&
      suggestion.metaTags?.includes('charityseal') &&
      suggestion.metaTags.includes('charity'),
    [suggestion],
  );

  const isHQ = suggestion.metaTags?.includes('headquarters');

  const indOfComma = suggestion.secondaryTitle && suggestion.secondaryTitle.indexOf(',') + 1;
  const indOfLastSpace = suggestion.secondaryTitle?.lastIndexOf(' ');
  const cityAndStateOnly =
    suggestion.secondaryTitle &&
    indOfComma &&
    indOfLastSpace &&
    suggestion.secondaryTitle.substr(indOfComma, indOfLastSpace - indOfComma);

  const businessSealImgAttrs = useCloudinaryImgAttributes(sealUrls.horizontal, 91);
  const charitySealImgAttrs = useCloudinaryImgAttributes(sealAcUrls.horizontal, 91);

  return (
    <Suggestion>
      <Suggestion.SuggestionContainer>
        <Suggestion.TitleContainer isHQ={isHQ}>
          <Suggestion.Title translate="no" dangerouslySetInnerHTML={{ __html: suggestion.title }} />
          {isHQ && <Suggestion.HqTitle>Headquarters</Suggestion.HqTitle>}
        </Suggestion.TitleContainer>
        {suggestion.altTitleHighlight && (
          <Suggestion.Tagline>{`also known as ${suggestion.altTitleHighlight}`}</Suggestion.Tagline>
        )}
        {!!suggestion.secondaryTitle && (
          <Suggestion.SecondaryTitle className="st-full-address" translate="no">
            {suggestion.secondaryTitle}
          </Suggestion.SecondaryTitle>
        )}
        {cityAndStateOnly && (
          <Suggestion.SecondaryTitle className="st-city-and-state-only" translate="no" style={{ display: 'none' }}>
            {cityAndStateOnly}
          </Suggestion.SecondaryTitle>
        )}
      </Suggestion.SuggestionContainer>
      {isAccreditedBusiness ? (
        <Suggestion.AbBadgeContainer>
          <Suggestion.AbBadgeImage {...businessSealImgAttrs} alt="Accredited Business" width="91" height="34" />
        </Suggestion.AbBadgeContainer>
      ) : isAccreditedCharity ? (
        <Suggestion.AbBadgeContainer>
          <Suggestion.AbBadgeImage {...charitySealImgAttrs} alt="Accredited Charity" width="91" height="34" />
        </Suggestion.AbBadgeContainer>
      ) : (
        ''
      )}
    </Suggestion>
  );
};

SuggestionComponent.propTypes = {
  suggestion: PropTypes.object,
};

export default SuggestionComponent;
