import { createPath, parsePath, History, Action, Location, Listener } from 'history';

type Events<F> = {
  length: number;
  push: (fn: F) => () => void;
  call: (arg: any) => void;
};
/**
 * Go here for implementation details: https://github.com/remix-run/history/blob/8bef6f4d50548f46ab7c97e171b3d8634093e7a7/packages/history/index.ts#L1019
 */
function createEvents<F extends Function>(): Events<F> {
  let handlers: F[] = [];

  return {
    get length() {
      return handlers.length;
    },
    push(fn: F) {
      handlers.push(fn);
      return function () {
        handlers = handlers.filter((handler) => handler !== fn);
      };
    },
    call(arg) {
      handlers.forEach((fn) => fn?.(arg));
    },
  };
}
export function reload() {
  return window.location.reload();
}
export function redirect(url: string): boolean {
  // window?.location can be null when SSR is executed
  if (url && window?.location) {
    window.location.href = url;
    return true;
  }

  return false;
}

function createStaticLocation(href: string): Location {
  return <Location>{
    ...parsePath(href),
  };
}

export function createStaticHistory({ href }: { href: string }): History {
  const listeners = createEvents<Listener>();
  return {
    action: Action.Pop,
    location: createStaticLocation(href),
    createHref(to) {
      return typeof to === 'string' ? to : createPath(to);
    },
    push(to) {
      throw new Error(
        `You cannot use history.push() on the server because it is a stateless 
          environment. This error was probably triggered when you did a 
          \`navigate(${JSON.stringify(to)})\` somewhere in your app.`,
      );
    },
    replace(to) {
      throw new Error(
        `You cannot use history.replace() on the server because it is a stateless 
          environment. This error was probably triggered when you did a 
          \`navigate(${JSON.stringify(to)}, { replace: true })\` somewhere 
          in your app.`,
      );
    },
    go(delta) {
      throw new Error(
        `You cannot use history.go() on the server because it is a stateless 
          environment. This error was probably triggered when you did a 
          \`navigate(${delta})\` somewhere in your app.`,
      );
    },
    back() {
      throw new Error(`You cannot use history.back() on the server because it is a stateless environment.`);
    },
    forward() {
      throw new Error(`You cannot use history.forward() on the server because it is a stateless environment.`);
    },
    block() {
      throw new Error(`You cannot use history.block() on the server because it is a stateless environment.`);
    },
    listen(listener) {
      return listeners.push(listener);
    },
  };
}
