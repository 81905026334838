import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import validator from '@iabbb/utils/validators/validator';

import {
  UPDATE_LEGACY_DATA_LAYER_FIND_METHOD,
  UPDATE_LEGACY_DATA_LAYER_FIND_TYPEAHEAD_INPUT,
  UPDATE_LEGACY_DATA_LAYER_NEAR_METHOD,
  UPDATE_LEGACY_DATA_LAYER_NEAR_TYPEAHEAD_INPUT,
  UPDATE_LEGACY_DATA_LAYER_SEARCH_RESULTS,
  UPDATE_LEGACY_DATA_LAYER_SORT,
  UPDATE_LEGACY_DATA_LAYER_USED_TYPEAHEADS,
} from './actionTypes';

interface SearchResult {
  businessId: string;
  bbbId: string;
  bbbName: string;
  businessName: string;
  rating: number;
  postalcode: string;
  phone?: string[] | null | undefined;
  logoUri?: string | null;
  bbbMember: boolean;
}

interface SearchResults {
  page?: number;
  results?: SearchResult[];
}

function updateSearchResultsAction(legacyDataLayerResults) {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_SEARCH_RESULTS,
    legacyDataLayerResults,
  };
}

export function updateLegacyDataLayerFindInput(input: string) {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_FIND_TYPEAHEAD_INPUT,
    input,
  };
}

export function updateLegacyDataLayerNearInput(input: string) {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_NEAR_TYPEAHEAD_INPUT,
    input,
  };
}

export function updateLegacyDataLayerFindMethod() {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_FIND_METHOD,
    method: 'selected',
  };
}

export function updateLegacyDataLayerNearMethod() {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_NEAR_METHOD,
    method: 'selected',
  };
}

export function updateLegacyDataLayerSortType(sortType: string) {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_SORT,
    sortType,
  };
}

function updateLegacyDataLayerUsedTypeaheadsAction() {
  return {
    type: UPDATE_LEGACY_DATA_LAYER_USED_TYPEAHEADS,
    usedTypeaheads: 'true',
  };
}

export function updateLegacyDataLayerSearchResults(results: SearchResults | null) {
  const currentPageNumber = results?.page ?? 1;
  const dataLayerResults = validator(isNotFalsyOrEmpty)(results?.results)
    ? results?.results?.map((result, index) => ({
        businessId: result.businessId,
        bbbId: result.bbbId,
        bbbName: result.bbbName,
        businessName: result.businessName,
        rating: result.rating,
        postalCode: result.postalcode,
        phoneNumber: validator(isNotFalsyOrEmpty)(result.phone) ? (result.phone?.[0] ?? null) : null,
        position: `page ${currentPageNumber}, position ${index + 1}`,
        accreditationStatus: result.bbbMember ? 'AB' : 'nonAB',
        hasLogoYN: result.logoUri ? 'Y' : 'N',
      }))
    : null;
  return updateSearchResultsAction(dataLayerResults);
}

export function updateLegacyDataLayerUsedTypeaheads() {
  return (dispatch) => {
    dispatch(updateLegacyDataLayerUsedTypeaheadsAction());
  };
}
