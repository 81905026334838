export const URL_SEGMENT_SEARCH = 'search';
export const URL_SEGMENT_CATEGORY = 'category';
export const URL_SEGMENT_NEAR_ME = 'near-me';
export const URL_SEGMENT_COUNTRY_US = 'us';
export const URL_SEGMENT_COUNTRY_CA = 'ca';
export const URL_SEGMENT_COUNTRY_MX = 'mx';

export const URL_SEGMENT_CATEGORY_ACCREDITED = 'accredited';

export const URL_QUERY_FIND_TEXT = 'find_text';
export const URL_QUERY_FIND_LOCATION = 'find_loc';
export const URL_QUERY_FIND_ID = 'find_id';
export const URL_QUERY_FIND_ENTITY = 'find_entity';
export const URL_QUERY_FIND_TYPE = 'find_type';
export const URL_QUERY_FIND_LATLNG = 'find_latlng';
export const URL_QUERY_FIND_COUNTRY = 'find_country';

export const URL_QUERY_FILTER_CATEGORY = 'filter_category';
export const URL_QUERY_FILTER_DISTANCE = 'filter_distance';
export const URL_QUERY_FILTER_RATINGS = 'filter_ratings';
export const URL_QUERY_FILTER_TYPE = 'filter_type';
export const URL_QUERY_FILTER_STATE = 'filter_state';
export const URL_QUERY_FILTER_RAQ = 'filter_raq';
export const URL_QUERY_FILTER_SA = 'filter_sa';
export const URL_QUERY_FILTER_ACCREDITED = 'filter_accredited';

export const FILTER_RAQ_DEFAULT_VALUE = 'default';

export const URL_QUERY_INTERACTION_COUNT = 'touched';

export const URL_QUERY_PAGE = 'page';
export const URL_QUERY_SORT = 'sort';

export const URL_QUERY_STATE = 'state';
export const URL_QUERY_CITY = 'city';

export const URL_QUERY_BACK_URL = 'back_url';

export const FILTER_URL_SEGMENTS = [
  URL_QUERY_FILTER_CATEGORY,
  URL_QUERY_FILTER_RATINGS,
  URL_QUERY_FILTER_DISTANCE,
  URL_QUERY_FILTER_TYPE,
  URL_QUERY_FILTER_STATE,
  URL_QUERY_FILTER_RAQ,
  URL_QUERY_FILTER_SA,
];

export const MULTISELECT_FILTER_URL_SEGMENTS = [
  URL_QUERY_FILTER_CATEGORY,
  URL_QUERY_FILTER_STATE,
  URL_QUERY_FILTER_RAQ,
  URL_QUERY_FILTER_TYPE,
];
