const baseInitialState = {
  cultureId: '',
  input: '',
  results: [],
  selected: {},
  isFetching: false,
};

const createInitialState = (additionalFields = {}) => ({ ...baseInitialState, ...additionalFields });

export default createInitialState;
