import useCookie from '@iabbb/shared/states/User/useCookie';
import CookieNames from '@iabbb/utils/Storage/CookieNames';
import { useState } from 'react';

import { CookiesPolicy } from './types';

const DefaultCookiesPolicy = { necessary: true, marketing: true, performance: true, functional: true };

export type ReturnProps = [CookiesPolicy, (updatedCookiesPolicy: CookiesPolicy) => void, () => void];

export default function useCookiesPolicy(): ReturnProps {
  const [cookiesPolicyCookie, setCookiesPolicyCookie] = useCookie(CookieNames.COOKIES_POLICY) as [
    string,
    (value: string, days: number) => void,
    () => void,
  ];
  const [_cookiesPreferencesSet, setCookiesPreferencesSet] = useCookie(CookieNames.COOKIES_PREFERENCES_SET) as [
    string,
    (value: boolean, days: number) => void,
    () => void,
  ];

  const [cookiesPolicyState, setCookiesPolicyState] = useState<CookiesPolicy>(
    cookiesPolicyCookie ? JSON.parse(cookiesPolicyCookie) : DefaultCookiesPolicy,
  );

  const setCookiesPolicy = (updatedCookiesPolicy: CookiesPolicy) => {
    setCookiesPolicyState(updatedCookiesPolicy);

    setCookiesPolicyCookie(JSON.stringify(updatedCookiesPolicy), 365);
    setCookiesPreferencesSet(true, 365);
  };

  const setAcceptAllCookiesPolicy = () => {
    setCookiesPolicy({
      necessary: true,
      functional: true,
      performance: true,
      marketing: true,
    });
  };

  return [cookiesPolicyState, setCookiesPolicy, setAcceptAllCookiesPolicy];
}
