import React, { useEffect } from 'react';

export default function useClickAwayListener(
  ref: React.MutableRefObject<HTMLElement | null>,
  action: () => void,
  isActive = true,
) {
  useEffect(() => {
    function handleClickAway(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }

    if (isActive) {
      document.addEventListener('click', handleClickAway);
    } else {
      document.removeEventListener('click', handleClickAway);
    }

    return () => {
      document.removeEventListener('click', handleClickAway);
    };
  }, [action, isActive, ref]);
}
