const parseStrToBool = (val: string | null) => {
  if (val != null) {
    if (typeof val === 'string') {
      return val.toLowerCase() === 'true' || val.toLowerCase() === 'false' ? false : val;
    }
  }
  return val;
};

export default parseStrToBool;
