import styled from '@emotion/styled';

// Nav.Container
export default styled.div`
  position: absolute;
  opacity: 0;
  right: 0;
  transform: scale(0.75, 0.5625);
  transform-origin: top right;
  transition:
    opacity 353ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 235ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 100%;
  visibility: hidden;
  z-index: 20;

  &[data-is-visible] {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }

  &[data-is-animating] {
    visibility: visible !important;
  }
`;
