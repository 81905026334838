import { setNearTypeaheadAndSaveCookie } from '@iabbb/shared/containers/Header/NearTypeahead/actions';
import { setFindYourBbbLocateBbbOnMapAndSaveCookie } from '@iabbb/shared/containers/Header/FindYourBbbTypeahead/actions';
import { setUserLocationAndSaveCookies, setUserCultureIdAndSaveCookie } from '@iabbb/shared/states/User/actions';
import { showSpinner } from '@iabbb/shared/states/Global/actions';
import { fetchSuggestBbbLocation } from '@iabbb/utils/api/SuggestApi';
import { fetchBbbLocation } from '@iabbb/utils/api/LocationApi';
import { redirect } from '@iabbb/utils/browser/location';
import getCountryCodeFromCultureOrDefault from '@iabbb/utils/culture/getCountryCodeFromCultureOrDefault';
import getIn from '@iabbb/utils/object/getIn';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import validator from '@iabbb/utils/validators/validator';

export const SELECT_BBB = 'containers/Header/LocaleSelector/SELECT_BBB';
export const CLEAR = 'containers/Header/LocaleSelector/CLEAR';
export const SET_CULTURE_ID = 'containers/Header/LocaleSelector/SET_CULTURE_ID';
export const SHOW_MENU = 'containers/Header/LocaleSelector/SHOW_MENU';

export function setLocaleSelectorCultureId(cultureId) {
  return {
    type: SET_CULTURE_ID,
    cultureId,
  };
}

function setUserLocationFindLocationAndSaveCookies(location) {
  return (dispatch, getState) => {
    dispatch(setUserLocationAndSaveCookies(location));
    // only set near typeahead if it's empty
    const nearSelected = getIn(getState(), ['header', 'nearTypeahead', 'selected']);
    if (!validator(isNotFalsyOrEmpty)(nearSelected)) {
      dispatch(setNearTypeaheadAndSaveCookie(location));
    }
  };
}

/**
 * Attempts to set and save the home location even if user just types in a location and doesn't select it from the typeahead
 */
export function saveUserLocationAndCultureFromLocaleSelectorAsync() {
  return (dispatch, getState) => {
    const state = getState();
    const hTypeahead = getIn(state, ['header', 'localeSelector', 'homeLocationTypeahead']);
    // used to check against, in case a full refresh isn't required (aka, the user has just chosen a new location and not a new culture)
    const initialUserCultureId = getIn(state, ['user', 'cultureId']);

    return new Promise((resolve, reject) => {
      let fetchLocation = () => Promise.reject();

      // home loc typeahead was selected and can fetch
      if (hTypeahead.selected?.id) {
        fetchLocation = () => fetchBbbLocation({ locationId: hTypeahead.selected.id }) as never;
        // try and figure out the location based on input text
      } else if (hTypeahead.input) {
        const userLocationBbbId = getIn(state, ['user', 'location', 'bbbId']);
        const country = getCountryCodeFromCultureOrDefault(hTypeahead.cultureId);
        fetchLocation = () =>
          fetchSuggestBbbLocation({
            country,
            input: hTypeahead.input,
            maxMatches: 1,
            bbbId: userLocationBbbId,
          }) as never;
      } else {
        return reject();
      }
      return fetchLocation().then((responseData) => {
        if (validator(isNotFalsyOrEmpty)(responseData)) {
          const selectedItem = Array.isArray(responseData) ? responseData[0] : responseData;
          dispatch(setUserLocationFindLocationAndSaveCookies(selectedItem));
          return resolve(selectedItem);
        }
        return reject();
      });
    }).then((homeLocationSelected) => {
      // USER CULTURE
      // Only if everything is setting the user location is sucessful, then can set culture
      const localeSelectorCultureId = getIn(state, ['header', 'localeSelector', 'cultureId']);
      dispatch(setUserCultureIdAndSaveCookie(localeSelectorCultureId));
      try {
        // And set findYourBbb and locatebbbOnmap
        dispatch(setFindYourBbbLocateBbbOnMapAndSaveCookie(homeLocationSelected));
      } catch (e) {
        // biome-ignore lint/suspicious/noConsole: for consumption by Sentry
        console.error(e);
      }

      if (initialUserCultureId.toUpperCase() !== localeSelectorCultureId.toUpperCase()) {
        // redirect to the version of the page with the new culture.
        redirect(window.location.href);
        dispatch(showSpinner());
      }

      return Promise.resolve(homeLocationSelected);
    });
  };
}
