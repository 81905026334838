export const DTM_GEOLANDING_SCAMTRACKER_CLICKED = 'geoLandingVisitScamTrackerClick';
export const DTM_GEOLANDING_TOB_CLICK = 'geolandingTobClick';
export const DTM_GEOLANDING_GET_ACCREDITED_BUTTON_CLICK = 'geoLandingGetAccreditedButtonClick';
export const DTM_GEOLANDING_SMALL_BUSINESS_RESOURCES_BUTTON_CLICK = 'geoLandingSmallBusinessResourcesButtonClick';

export const DTM_BP_NAME_FIND_LOCATION_CLICKED = 'bpNameFindLocationClick';
export const DTM_BP_RATING_DEFAULT_LINK_CLICKED = 'bpRatingDefaultLinkClick';
export const DTM_BP_REVIEWS_STARS_SELECTED = 'bpReviewsPageStarsSelected';
export const DTM_BP_OVERVIEW_BLOCK_MEDIA_CLICKED = 'bpOverviewBlockViewMediaClick';
export const DTM_BP_LOCAL_BBB_LINK_CLICKED = 'bpLocalBbbLinkClick';

export const DTM_HEADER_LINK_CLICK = 'headerLinkClick';

export const DTM_SEARCH_COMPLETED = 'searchCompleted';
export const DTM_SEARCH_EXECUTED = 'searchExecuted';
export const DTM_SEARCH_ACCREDITED_FILTER_SELECTED = 'searchAccreditedFilterSelected';
export const DTM_SEARCH_CATEGORIES_FILTER_SELECTED = 'searchCategoriesFilterSelected';
export const DTM_SEARCH_DISTANCE_FILTER_SELECTED = 'searchDistanceFilterSelected';
export const DTM_SEARCH_RATING_FILTER_SELECTED = 'searchRatingFilterSelected';
export const DTM_SEARCH_SHOW_ONLY_FILTER_SELECTED = 'searchShowOnlyFilterSelected';
export const DTM_SEARCH_RESULT_MOBILE_PHONE_CLICKED = 'searchMobilePhoneClicked';
export const DTM_SEARCH_RESULT_RAQ_CLICKED = 'searchRaqClicked';
export const DTM_SEARCH_RESULT_CLICKED = 'searchResultClicked';
export const DTM_ELP_SEARCH_RESULT_CLICKED = 'elpSearchResultClicked';
export const DTM_ELP_SEARCH_RESULT_RAQ_CLICKED = 'elpSearchRaqClicked';
export const DTM_LEAVE_REVIEW_SEARCH_COMPLETED = 'leaveReviewSearchCompleted';
export const DTM_REQUEST_A_QUOTE_SEARCH_BUTTON_CLICK = 'getAQuoteSearchButtonClick';

export const DTM_SEARCH_LANDING_TOB_CLICKED = 'searchLandingTobClicked';
export const DTM_SEARCH_LANDING_WIDGET_CLICKED = 'searchLandingWidgetClicked';
export const DTM_SEARCH_LANDING_BBB_SEARCH = 'searchLandingBbbSearch';

export const DTM_CONTENT_LISTING_ARTICLE_CLICKED = 'contentListingArticleClicked';

export const PREVENT_REDIRECT_PARAM = 's_fe';

export const DTM_FILE_COMPLAINT_LANDING_BUTTON_CLICK = 'fileComplaintLandingButtonClick';

export const DTM_FILE_COMPLAINT_SEARCH_SEARCH_EXECUTED = 'fileComplaintSearchSearchExecuted';
export const DTM_FILE_COMPLAINT_SEARCH_BUSINESS_INFO_BUTTON_CLICK = 'fileComplaintSearchBusinessInfoButtonClick';
export const DTM_FILE_COMPLAINT_SEARCH_QUALIFYING_MODAL_NO_ANSWER = 'fileComplaintSearchBusinessInfoNoAnswer';
export const DTM_FILE_COMPLAINT_SEARCH_CLOSE_QUALIFYING_MODAL = 'fileComplaintSearchCloseQualifyingModal';
export const DTM_FILE_COMPLAINT_SEARCH_QUALIFYING_MODAL_CONTINUE_CLICK =
  'fileComplaintQualifyingModalContinueClick';
export const DTM_FILE_COMPLAINT_SEARCH_RESULT_SELECT_CLICK = 'fileComplaintSearchResultSelectClick';
export const DTM_FILE_COMPLAINT_SEARCH_RESULT_TOGGLE_LOCATIONS = 'fileComplaintSearchResultToggleLocations';

export const DTM_FILE_COMPLAINT_FORM_BACK_BUTTON_CLICK = 'fileComplaintFormBackButtonClick';
export const DTM_FILE_COMPLAINT_FORM_QUIT_BUTTON_CLICK = 'fileComplaintFormQuitButtonClick';
export const DTM_FILE_COMPLAINT_FORM_SAVE_BUTTON_CLICK = 'fileComplaintFormSaveButtonClick';
export const DTM_FILE_COMPLAINT_FORM_UNQUALIFIED_MODAL_LEAVE_REVIEW_CLICK =
  'fileComplaintFormUnqualifiedModalLeaveReviewClick';
export const DTM_FILE_COMPLAINT_FORM_UNQUALIFIED_MODAL_RETURN_TO_BBB_CLICK =
  'fileComplaintFormUnqualifiedModalReturnToBbbClick';
export const DTM_FILE_COMPLAINT_FORM_UNQUALIFIED_MODAL_CLOSE = 'fileComplaintFormUnqualifiedModalClose';
export const DTM_FILE_COMPLAINT_FORM_MILITARY_MODAL_SUBMIT_CLICK = 'fileComplaintFormMilitaryModalSubmitClick';
export const DTM_FILE_COMPLAINT_FORM_MILITARY_MODAL_CLOSE = 'fileComplaintFormMilitaryModalClose';
export const DTM_FILE_COMPLAINT_FORM_EDIT_LINK_CLICK = 'fileComplaintFormEditLinkClick';
export const DTM_FILE_COMPLAINT_FORM_CONTINUE_BUTTON_CLICK = 'fileComplaintFormContinueButtonClick';
export const DTM_FILE_COMPLAINT_FORM_SUBMIT_FOR_REVIEW_BUTTON_CLICK =
  'fileComplaintFormSubmitForReviewButtonClick';
export const DTM_FILE_COMPLAINT_FORM_FINAL_SUBMIT_BUTTON_CLICK = 'fileComplaintFormFinalSubmitButtonClick';
export const DTM_FILE_COMPLAINT_FORM_OTHER_LINK_CLICK = 'fileComplaintFormOtherLinkClick';

export const DTM_AB_FILTER_PREFERENCE_MODAL_RADIO_CLICK = 'abFilterPreferenceModalRadioClick';

export const DTM_AB_SUBMIT_REQUEST_CLICK = 'getListedSubmit';
export const DTM_AB_CUSTOMER_REVIEWS_CLICK = 'getListedStartReviewButtonClick';
export const DTM_ACCREDITED_LEARN_MORE_FORM_SUBMIT = 'getAccreditedLearnMoreFormSubmit';

export const DTM_GET_ACCREDITED_WITH_CONTACT_METHOD_SUBMIT = 'getAccreditedWithContactMethodSubmit';
