import Button from '@iabbb/bds-react/Button';
import styled from '@emotion/styled';

export default styled(Button)`
  padding: 8px 55px;

  &:focus {
    outline: 2px auto Highlight;
    outline: 2px auto -webkit-focus-ring-color;
  }
`;
