import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputGroupStyled from '../styles/InputGroupStyled';
import InputGroupLabel from '../styles/InputGroupLabel';
import Typeahead from '@iabbb/shared/components/Typeahead/Provider';
import TypeaheadAutocomplete from '@iabbb/shared/components/Typeahead/Provider/Autocomplete';
import TypeaheadClear from '@iabbb/shared/components/Typeahead/Provider/Clear';
import TypeaheadCountryCombobox from '@iabbb/shared/components/Typeahead/CountryCombobox';
import TypeaheadLabel from '@iabbb/shared/components/Typeahead/Provider/Label';
import IconMapMarker from '@iabbb/icons/MapMarker';
import { updateLegacyDataLayerUsedTypeaheads } from '@iabbb/shared/states/Analytics/LegacyDataLayer/Search/actions';
import getIn from '@iabbb/utils/object/getIn';

import {
  clearNearTypeaheadExceptCultureId,
  fetchAndUpdateNearTypeaheadResults,
  setNearTypeaheadInput,
  setNearTypeaheadSelected,
  setNearTypeaheadCultureId,
  nearTypeaheadSuggestionSelected,
} from './actions';
import {
  getInput,
  getIsFetching,
  getPlaceholder,
  getResultsWithCurrentLocation,
  getSelected,
  getTypeaheadCultureId,
} from './selectors';
import Suggestion from './Suggestion';

interface NearTypeaheadSuggestionProps {
  displayText?: string;
  displayTextOriginal?: string;
  currentLocation?: string;
}

interface NearTypeahead {
  className?: string;
  showIconLabel?: boolean;
  styled?: React.CSSProperties;
}

const name = 'find_loc';

function NearTypeahead({ className, showIconLabel = false, ...props }: NearTypeahead) {
  const dispatch = useDispatch();
  const userPhysicalLocation = useSelector((state: Record<string, object>) =>
    getIn(state, ['user', 'physicalLocation']),
  );
  const placeholder = useSelector(getPlaceholder());
  const suggestions = useSelector(getResultsWithCurrentLocation());
  const input = useSelector(getInput());
  const isFetching = useSelector(getIsFetching());
  const selected = useSelector(getSelected());
  const typeaheadCultureId = useSelector(getTypeaheadCultureId());

  const clear = () => dispatch(clearNearTypeaheadExceptCultureId() as never);
  const fetchAndUpdateResults = (data) => dispatch(fetchAndUpdateNearTypeaheadResults(data) as never);
  const setNearTypeaheadCultureIdConnect = (data) => dispatch(setNearTypeaheadCultureId(data) as never);
  const setInput = (data) => dispatch(setNearTypeaheadInput(data) as never);
  const setSelected = (data) => dispatch(setNearTypeaheadSelected(data) as never);
  const typeaheadSuggestionSelected = () => dispatch(nearTypeaheadSuggestionSelected());
  const updateLegacyDataLayerUsedTypeaheadsConnect = () =>
    dispatch(updateLegacyDataLayerUsedTypeaheads() as never);

  const onItemSelected = (item: NearTypeaheadSuggestionProps | null) => {
    typeaheadSuggestionSelected();
    if (item?.displayText) {
      updateLegacyDataLayerUsedTypeaheadsConnect();

      // 👇 new data layer
      if (window.webDigitalData?.search_info?.used_type_aheads) {
        window.webDigitalData.search_info.used_type_aheads = 'true';
      }

      if (item.currentLocation) {
        const [userCity, userState] = userPhysicalLocation.split(', ');
        setSelected({});
        setInput(`${userCity}, ${userState}`);
      } else {
        setSelected(item);
      }
    }
  };

  const onChangeCountrySelectRenderInputComponent = (cultureId) => {
    setNearTypeaheadCultureIdConnect(cultureId);
    clear();
  };

  // the 'current location' option doesn't populate the typeahead with 'current location';
  // when selected, use the original displayText value;
  const getSuggestionValue = (suggestion: NearTypeaheadSuggestionProps) =>
    suggestion.displayTextOriginal ? suggestion.displayTextOriginal : suggestion.displayText;

  const renderSuggestion = (suggestion: NearTypeaheadSuggestionProps, { query }: { query: string }) => {
    const suggestionProps = {
      suggestion,
      query,
    };

    return <Suggestion {...suggestionProps} />;
  };

  const typeaheadProps = {
    fetchAndUpdateResults,
    getSuggestionValue,
    inputProps: {
      name,
      placeholder,
      value: input,
    },
    isFetching,
    onClickClear: clear,
    onItemSelected,
    renderSuggestion,
    selected,
    setInput,
    setSelected,
    shouldRenderSuggestions: () => true,
    suggestions,
    ...props,
  };

  return (
    <InputGroupStyled as={Typeahead} {...typeaheadProps} className={clsx('dtm-header-near-typeahead', className)}>
      <TypeaheadLabel as={InputGroupLabel}>
        {showIconLabel ? (
          <>
            <IconMapMarker className="text-size-30" />
            <span className="visually-hidden">Near</span>
          </>
        ) : (
          'Near'
        )}
      </TypeaheadLabel>
      <TypeaheadAutocomplete />
      <TypeaheadClear />
      <TypeaheadCountryCombobox
        selectedId={typeaheadCultureId}
        menuItemClassName="dtm-header-near-typeahead-country-item"
        setSelectedId={onChangeCountrySelectRenderInputComponent}
      />
    </InputGroupStyled>
  );
}

NearTypeahead.displayName = 'NearTypeahead';

export default NearTypeahead;
