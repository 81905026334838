import { produce } from 'immer';

import trimTrailingSlash from '@iabbb/utils/url/trimTrailingSlash';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import validator from '@iabbb/utils/validators/validator';

const createNavigationLinks = produce((draft) => {
  if (draft) {
    draft.forEach((navItem, index) => {
      draft[index].linkLabel = navItem.text;
      draft[index].text = navItem.text;
      draft[index].href = trimTrailingSlash(navItem.href);
      draft[index].links = validator(isNotFalsyOrEmpty)(navItem.links) ? createNavigationLinks(navItem.links) : [];

      if (!navItem.id) {
        draft[index].id = navItem.text;
      }

      if (navItem.title) {
        draft[index].title = navItem.title;
      }
    });
  }
}, []);

export { createNavigationLinks };
