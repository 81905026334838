import styled from '@emotion/styled';

export default styled.li`
  position: relative;

  &.has-subnav a {
    background: var(--bds-color-blue-medium);
  }

  &.has-subnav li {
    position: relative;

    & + li::after {
      background-color: var(--bds-color-white);
      content: '';
      height: 1px;
      margin-inline-start: 1.5rem;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: calc(100% - 3rem);
    }

    a {
      padding-inline-start: 1.75rem;
    }
  }

  a {
    display: flex;
    font-weight: var(--bds-font-weight-semibold);
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    width: 100%;
    letter-spacing: 0.3px;

    svg {
      margin-inline-end: 0.75rem;
    }
  }

  &:not(.hide-bottom) + &:not(.has-subnav)::after {
    background-color: var(--bds-color-white);
    content: '';
    height: 1px;
    margin-inline-start: 1.5rem;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: calc(100% - 3rem);
  }

  button {
    line-height: 1;
    margin: 0.25rem 0.5rem;
    padding: 0.33rem;
    position: absolute;
    right: 0;
    top: 0;

    & + ul {
      display: none;
    }

    &[aria-expanded='true'] {
      svg {
        transform: rotate(180deg);
      }

      & + ul {
        display: block;
      }
    }
  }
`;
