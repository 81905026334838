import { fetchSuggestLocation } from '@iabbb/utils/api/SuggestApi';
import { DEFAULT_CULTURE_ID } from '@iabbb/utils/culture/constants';
import getCountryCodeFromCultureOrDefault from '@iabbb/utils/culture/getCountryCodeFromCultureOrDefault';
import getIn from '@iabbb/utils/object/getIn';
import validator from '@iabbb/utils/validators/validator';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';

interface FetchAndUpdateTypeaheadResultsProps {
  searchTerm: string;
  maxMatches: number;
  cultureId: string;
  bbbId: string;
  latLng?: string;
}
const createLocationResultsAction = ({ actions, suggestApiFetch = fetchSuggestLocation }) => {
  /**
   * Common results location based typeaheads such as NearTypeahead and HomeLocationTypeahead
   *
   * @export
   * @param {string} searchTerm - search text
   * @param {number} maxMatches - number of matches that should be returned, defaults to 10
   * @param {string} cultureId - cultureId that will be used if it exists, defaults to null
   * @returns location suggestions
   */
  function fetchAndUpdateTypeaheadResults({
    searchTerm,
    maxMatches,
    cultureId,
    bbbId,
  }: FetchAndUpdateTypeaheadResultsProps) {
    return (dispatch, getState) => {
      dispatch(actions.fetchingTypeaheadResults());
      const state = getState();
      const userCultureId = cultureId ?? (getIn(state, ['user', 'cultureId']) || DEFAULT_CULTURE_ID);

      const country = getCountryCodeFromCultureOrDefault(userCultureId);

      const fetchParams = {
        country,
        input: searchTerm,
        maxMatches,
        bbbId,
      };

      return suggestApiFetch(fetchParams)
        .then((results) => {
          dispatch(actions.fetchingDoneTypeaheadResults());
          if (validator(isNotFalsyOrEmpty)(results)) {
            dispatch(actions.updateTypeaheadResults(results));
          } else {
            dispatch(actions.updateTypeaheadResults([]));
          }
        })
        .catch(() => {
          dispatch(actions.fetchingDoneTypeaheadResults());
        });
    };
  }

  return { fetchAndUpdateTypeaheadResults };
};

export default createLocationResultsAction;
