import IconAirConditioner from '@iabbb/icons/AirConditioner';
import IconBalanceScale from '@iabbb/icons/BalanceScale';
import IconBath from '@iabbb/icons/Bath';
import IconBolt from '@iabbb/icons/Bolt';
import IconBriefcase from '@iabbb/icons/Briefcase';
import IconBrush from '@iabbb/icons/Brush';
import IconBug from '@iabbb/icons/Bug';
import IconCar from '@iabbb/icons/Car';
import IconChimney from '@iabbb/icons/Chimney';
import IconClinic from '@iabbb/icons/Clinic';
import IconConcrete from '@iabbb/icons/Concrete';
import IconDog from '@iabbb/icons/Dog';
import IconDigging from '@iabbb/icons/Digging';
import IconFence from '@iabbb/icons/Fence';
import IconFlame from '@iabbb/icons/Flame';
import IconFlower from '@iabbb/icons/Flower';
import IconGarageCar from '@iabbb/icons/GarageCar';
import IconHome from '@iabbb/icons/Home';
import IconMoneyCheck from '@iabbb/icons/MoneyCheck';
import IconRoof from '@iabbb/icons/Roof';
import IconRulerTriangle from '@iabbb/icons/RulerTriangle';
import IconShieldCheck from '@iabbb/icons/ShieldCheck';
import IconSiding from '@iabbb/icons/Siding';
import IconSnowplow from '@iabbb/icons/Snowplow';
import IconStore from '@iabbb/icons/Store';
import IconSwimmer from '@iabbb/icons/Swimmer';
import IconToolbox from '@iabbb/icons/Toolbox';
import IconTools from '@iabbb/icons/Tools';
import IconTooth from '@iabbb/icons/Tooth';
import IconTree from '@iabbb/icons/Tree';
import IconTruck from '@iabbb/icons/Truck';
import IconUserHardHat from '@iabbb/icons/UserHardHat';
import IconUtensils from '@iabbb/icons/Utensils';
import IconVacuum from '@iabbb/icons/Vacuum';
import IconWindow from '@iabbb/icons/Window';
import IconWrench from '@iabbb/icons/Wrench';

export const DEFAULT_ICON = IconBriefcase;

export const CATEGORIES = {
  'auto detailing': { icon: IconCar },
  'auto repair': { icon: IconCar },
  'bathroom remodel': { icon: IconBath },
  'carpet cleaning': { icon: IconVacuum },
  'concrete contractors': { icon: IconConcrete },
  contractor: { icon: IconToolbox },
  construction: { icon: IconUserHardHat },
  'construction services': { icon: IconUserHardHat },
  dentist: { icon: IconTooth },
  dog: { icon: IconDog },
  'drywall contractor': { icon: IconToolbox },
  electrician: { icon: IconBolt },
  electricians: { icon: IconBolt },
  'fence contractors': { icon: IconFence },
  'financial services': { icon: IconMoneyCheck },
  'garage door repair': { icon: IconGarageCar },
  'garage door services': { icon: IconGarageCar },
  'general contractor': { icon: IconToolbox },
  handyman: { icon: IconTools },
  health: { icon: IconClinic },
  'heating and air conditioning': { icon: IconFlame },
  'home builders': { icon: IconHome },
  'home cleaning': { icon: IconVacuum },
  'home improvement': { icon: IconRulerTriangle },
  'house cleaning': { icon: IconVacuum },
  hvac: { icon: IconAirConditioner },
  insurance: { icon: IconShieldCheck },
  'janitor service': { icon: IconVacuum },
  'landing contractors': { icon: IconFlower },
  lawyers: { icon: IconBalanceScale },
  llc: { icon: IconBriefcase },
  'mason contractors': { icon: IconChimney },
  'movers companies': { icon: IconTruck },
  'moving companies': { icon: IconTruck },
  'painting contractors': { icon: IconBrush },
  'paving contractors': { icon: IconDigging },
  'pest control': { icon: IconBug },
  plumbers: { icon: IconWrench },
  plumbing: { icon: IconWrench },
  'pool contractors': { icon: IconSwimmer },
  'property management': { icon: IconStore },
  'real estate': { icon: IconStore },
  'remodel contractors': { icon: IconRulerTriangle },
  remodeling: { icon: IconRulerTriangle },
  restaurants: { icon: IconUtensils },
  roofer: { icon: IconRoof },
  roofers: { icon: IconRoof },
  'roofing contractors': { icon: IconRoof },
  'siding contractors': { icon: IconSiding },
  'snow removal services': { icon: IconSnowplow },
  'tree service': { icon: IconTree },
  'used car dealers': { icon: IconCar },
  'window washing': { icon: IconWindow },
};
