import breakpoints from './breakpoints';
import { convertEmStringToPixels, convertPixelsToEmString } from './utils';

export function getMaxWidthInEms(sizeInEms: string) {
  const sizeInPixels = convertEmStringToPixels(sizeInEms);
  return convertPixelsToEmString(sizeInPixels - 1);
}

const media = {
  sm: `@media (min-width: ${breakpoints.sm})`,
  md: `@media (min-width: ${breakpoints.md})`,
  lg: `@media (min-width: ${breakpoints.lg})`,
  xl: `@media (min-width: ${breakpoints.xl})`,
  xsDown: `@media (max-width: ${getMaxWidthInEms(breakpoints.sm)})`,
  smDown: `@media (max-width: ${getMaxWidthInEms(breakpoints.md)})`,
  mdDown: `@media (max-width: ${getMaxWidthInEms(breakpoints.lg)})`,
  lgDown: `@media (max-width: ${getMaxWidthInEms(breakpoints.xl)})`,
  smOnly: `@media (min-width: ${breakpoints.sm}) and (max-width: ${getMaxWidthInEms(breakpoints.md)})`,
  mdOnly: `@media (min-width: ${breakpoints.md}) and (max-width: ${getMaxWidthInEms(breakpoints.lg)})`,
  lgOnly: `@media (min-width: ${breakpoints.lg}) and (max-width: ${getMaxWidthInEms(breakpoints.xl)})`,
};

export default media;
