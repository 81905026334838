import clsx from 'clsx';
import IconSearch from '@iabbb/icons/Search';

import InputGroupStyled from '../styles/InputGroupStyled';
import InputGroupLabel from '../styles/InputGroupLabel';
import Typeahead from '@iabbb/shared/components/Typeahead/Provider';
import TypeaheadAutocomplete from '@iabbb/shared/components/Typeahead/Provider/Autocomplete';
import TypeaheadClear from '@iabbb/shared/components/Typeahead/Provider/Clear';
import TypeaheadLabel from '@iabbb/shared/components/Typeahead/Provider/Label';
import { trackSearchCompleted } from '@iabbb/shared/containers/Header/analytics';
import { updateLegacyDataLayerUsedTypeaheads } from '@iabbb/shared/states/Analytics/LegacyDataLayer/Search/actions';
import removeHtmlTags from '@iabbb/utils/html/removeHtmlTags';
import {
  fetchAndUpdateFindTypeaheadResults,
  goToOrganizationUrlActionCreator,
  setFindTypeaheadSelected,
  setFindTypeaheadInput,
  clearFindTypeahead,
  findTypeaheadSuggestionSelected,
  goToNearMeActionCreator,
  goToPopularCategoryUrlActionCreator,
  goToNearMeTransformedUrlActionCreator,
} from './actions';
import { NEAR_ME_ENTITY_TYPE, ORGANIZATION_ENTITY_TYPE } from './entityType';
import { getInput, getIsFetching, getResults, getSelected } from './selectors';
import { selectNearSelected } from './selectors';
import { useDispatch, useSelector } from 'react-redux';
import Suggestion from './Suggestion';
import { getPopularCategoriesListOrDefault } from '@iabbb/shared/states/User/selectors';

interface FindTypeaheadProps {
  className?: string;
  otherInputProps?: unknown;
  showIconLabel?: boolean;
  withPopularCategoriesTitle?: boolean;
  styled?: React.CSSProperties;
}

interface FindTypeaheadResultsSetProps {
  suggestions: FindTypeaheadSuggestionProps[];
  title: string;
}

interface FindTypeaheadSuggestionProps {
  url: boolean | null;
  title: string;
  altTitleHighlight: string;
  secondaryTitle?: string;
  metaTags?: string[];
  entityType: string;
}

export function FindTypeahead({
  className,
  otherInputProps = {},
  showIconLabel = false,
  withPopularCategoriesTitle = false,
  ...props
}: FindTypeaheadProps) {
  const dispatch = useDispatch();

  const results = useSelector(getResults());
  const input = useSelector(getInput());
  const isFetching = useSelector(getIsFetching());
  const selected = useSelector(getSelected());
  const popularSuggestions = useSelector(getPopularCategoriesListOrDefault());

  const setInput = (data) => dispatch(setFindTypeaheadInput(data) as never);
  const setSelected = (data) => dispatch(setFindTypeaheadSelected(data) as never);
  const fetchAndUpdateResults = (data) => dispatch(fetchAndUpdateFindTypeaheadResults(data) as never);
  const clear = () => dispatch(clearFindTypeahead() as never);
  const goToOrganizationUrl = (data) => dispatch(goToOrganizationUrlActionCreator(data) as never);
  const goToNearMeUrl = (data) => dispatch(goToNearMeActionCreator(data) as never);
  const goToPopularUrl = (data) => dispatch(goToPopularCategoryUrlActionCreator(data) as never);
  const goToNearMeTransformedUrl = () => dispatch(goToNearMeTransformedUrlActionCreator() as never);
  const typeaheadSuggestionSelected = () => dispatch(findTypeaheadSuggestionSelected() as never);
  const updateLegacyDataLayerUsedTypeaheadsConnect = () =>
    dispatch(updateLegacyDataLayerUsedTypeaheads() as never);

  const nearSelected = useSelector(selectNearSelected);

  const onItemSelected = (item: FindTypeaheadSuggestionProps | null) => {
    typeaheadSuggestionSelected();

    if (item?.entityType) {
      updateLegacyDataLayerUsedTypeaheadsConnect();

      // 👇 new data layer
      if (window.webDigitalData?.search_info?.used_type_aheads) {
        window.webDigitalData.search_info.used_type_aheads = 'true';
      }

      setSelected(item);
      switch (item.entityType) {
        case ORGANIZATION_ENTITY_TYPE:
          trackSearchCompleted();
          goToOrganizationUrl(item);
          break;
        case NEAR_ME_ENTITY_TYPE:
          goToNearMeUrl(item);
          break;
        default:
          break;
      }
    }
    if (item && !item.entityType && item.url) {
      if (nearSelected?.displayText) {
        goToNearMeTransformedUrl();
      } else {
        goToPopularUrl(item);
      }
    }
  };

  const getSuggestionValue = (suggestion: FindTypeaheadSuggestionProps) =>
    suggestion?.title ? removeHtmlTags(suggestion.title) : suggestion;

  const renderSuggestion = (suggestion: FindTypeaheadSuggestionProps, { query }: { query: string }) => {
    const suggestionProps = {
      query,
      suggestion,
    };

    // notFound set to true in the actions if no results come back
    return <Suggestion {...suggestionProps} />;
  };

  const name = 'find_text';

  const typeaheadProps = {
    ...props,
    fetchAndUpdateResults,
    getSectionSuggestions: (resultsSet: FindTypeaheadResultsSetProps) => resultsSet.suggestions,
    getSuggestionValue,
    inputProps: {
      name,
      placeholder: 'businesses, category',
      type: 'search',
      value: input,
      ...(typeof otherInputProps === 'object' ? otherInputProps : {}),
    },
    isFetching,
    multiSection: true,
    onClickClear: clear,
    onItemSelected,
    renderSectionTitle: (resultsSet: FindTypeaheadResultsSetProps) => resultsSet.title,
    renderSuggestion,
    selected,
    setInput,
    setSelected,
    suggestions: results,
    popularSuggestions,
  };

  return (
    <InputGroupStyled as={Typeahead} {...typeaheadProps} className={clsx('dtm-header-find-typeahead', className)}>
      <TypeaheadLabel as={InputGroupLabel}>
        {showIconLabel ? (
          <>
            <IconSearch className="text-size-30" />
            <span className="visually-hidden">Find</span>
          </>
        ) : (
          'Find'
        )}
      </TypeaheadLabel>
      <TypeaheadAutocomplete withPopularCategoriesTitle={withPopularCategoriesTitle} />
      <TypeaheadClear />
    </InputGroupStyled>
  );
}

FindTypeahead.displayName = 'FindTypeahead';

export default FindTypeahead;
