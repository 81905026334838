import styled from '@emotion/styled';
import clsx from 'clsx';

const StyledContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  margin-left: 12px;
  width: 100%;
`;

export default function Container({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return <StyledContainer className={clsx('ta-container', className)} {...props} />;
}
