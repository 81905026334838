import LogoTorch from '@iabbb/shared/components/Graphics/Logos/Torch';

import LogoMobile from './styles';

function LogoMobileComponent(props: React.ComponentPropsWithoutRef<'a'>) {
  return (
    <LogoMobile aria-label="Better Business Bureau Homepage" href={process.env.NEXT_PUBLIC_BASE_URL} {...props}>
      <LogoTorch />
    </LogoMobile>
  );
}

export default LogoMobileComponent;
