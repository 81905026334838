import styled from '@emotion/styled';
import { css } from '@emotion/react';

type TypeaheadErrorProps = {
  hide: boolean;
};

const TypeaheadError = styled.div<TypeaheadErrorProps>`
  background-color: var(--bds-color-gray-40);
  border-bottom: 1px solid var(--bds-color-gray-60);
  border-left: 1px solid var(--bds-color-gray-40);
  border-right: 1px solid var(--bds-color-gray-40);
  font-weight: var(--bds-font-weight-bold);
  padding: 6px 12px;
  position: absolute;
  transition: none;
  width: 100%;
  z-index: 1;

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
      transition: 900ms ease all;
      visibility: hidden;
      height: 0;
      padding: 0;
    `}
`;

export default TypeaheadError;
