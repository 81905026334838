import styled from '@emotion/styled';
import Typeahead from '@iabbb/shared/components/Typeahead/Provider/styles';

const MobileSearchContainer = styled.div`
  background-color: var(--bds-color-blue-brand);
  padding: 0.75rem 1rem;
  position: relative;
`;

const MobileSearchDialog = styled.dialog`
  background: var(--bds-color-gray-20);
  border: none;
  height: 100%;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  width: 100%;
`;

const MobileTypeaheadWrapper = styled.div`
  .input-group {
    align-items: center;
    border: none;
    position: static;
  }
`;

const MobileTypeahead = styled(Typeahead)`
  > label {
    padding-right: 0;
  }
`;

MobileTypeahead.Clear = Typeahead.Clear;
MobileTypeahead.Container = Typeahead.Container;
MobileTypeahead.Input = Typeahead.Input;

MobileTypeahead.Suggestions = styled(Typeahead.Suggestions)`
  position: absolute;
  max-height: calc(100vh - 144px);
  width: 100%;
  top: 100%;
`;

MobileTypeahead.Suggestions.Ul = styled(Typeahead.Suggestions.Ul)`
  position: static;
  max-height: max-content;
  border-top: none;
`;

MobileTypeahead.Suggestions.Li = styled(Typeahead.Suggestions.Li, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})`
  color: var(--bds-color-black);
  ${({ isActive }) =>
    isActive &&
    `
    color: var(--bds-color-white);
  `}

  padding: 0.75rem 1.3125rem;
`;

MobileTypeahead.Suggestions.SectionTitle = styled(Typeahead.Suggestions.SectionTitle)`
  padding: 0.625rem 1.3125rem 0.5625rem;
`;

export { MobileSearchContainer, MobileSearchDialog, MobileTypeahead, MobileTypeaheadWrapper };
