import { combineReducers, Reducer } from 'redux';
import global from '@iabbb/shared/states/Global/reducer';
import header from '@iabbb/shared/containers/Header/reducer';
import user from '@iabbb/shared/states/User/reducer';
import analytics from '@iabbb/shared/states/Analytics/reducer';

const createRootReducer = ({ injectedReducers = {} }: { injectedReducers?: Record<string, Reducer> } = {}) => {
  return combineReducers({
    analytics,
    user,
    global,
    header,
    ...injectedReducers,
  });
};

export default createRootReducer;
