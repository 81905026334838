import createSelectors from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createSelectors';
import { createSelector } from 'reselect';
import { abSealUrls, abSealCanUrls } from '@iabbb/shared/components/Business/AccreditedBusinessBadge/constants';
import { acSealUrls, acSealCanUrls } from '@iabbb/shared/components/Business/AccreditedCharityBadge/constants';
import { getUserLocationBbbCountryCode } from '@iabbb/shared/states/User/selectors';
import { bbbCountryCodeToKey } from '@iabbb/utils/string/formatPhoneNumber';
import getIn from '@iabbb/utils/object/getIn';
import { TYPEAHEAD_STATE_PATH } from './constants';

const getSealUrlsByCountryCode = (bbbCountryCode: number | string | null) => {
  const countryKey = bbbCountryCodeToKey(bbbCountryCode as number);
  let sealUrls;
  switch (countryKey) {
    case 'can':
      sealUrls = abSealCanUrls;
      break;
    default:
      sealUrls = abSealUrls;
      break;
  }
  return sealUrls;
};

const getAcSealUrlsByCountryCode = (bbbCountryCode: number | string | null) => {
  const countryKey = bbbCountryCodeToKey(bbbCountryCode as number);
  let sealUrls;
  switch (countryKey) {
    case 'can':
      sealUrls = acSealCanUrls;
      break;
    default:
      sealUrls = acSealUrls;
      break;
  }
  return sealUrls;
};

export const getSealUrls = () =>
  createSelector(getUserLocationBbbCountryCode(), (bbbCountryCode) => getSealUrlsByCountryCode(bbbCountryCode));

export const getAcSealUrls = () =>
  createSelector(getUserLocationBbbCountryCode(), (bbbCountryCode) => getAcSealUrlsByCountryCode(bbbCountryCode));

const selectors = createSelectors({ typeaheadStatePath: TYPEAHEAD_STATE_PATH });

export const selectNearSelected = (state: Record<string, object>) =>
  getIn(state, ['header', 'nearTypeahead', 'selected']);

export const { getTypeahead, getSelected, getResults, getInput, getIsFetching } = selectors;
