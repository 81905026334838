import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';
import Center from '@iabbb/shared/components/General/Center';
import hideAt from '@iabbb/shared/styles/hideAt';
import print from '@iabbb/shared/styles/print';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { trackHeaderLinkClick } from './analytics';
import { getBbbLinks } from './selectors';

const Toolbar = styled.div`
  border-block-end: 1px solid var(--bds-color-gray-40);
  min-block-size: var(--utility-bar-height);
  padding-block: 0.25rem;
`;

const StyledUl = styled.ul`
  --cluster-horizontal-alignment: flex-end;
  --gutter: 0.5rem;

  // 👇 the header used outside of the main app does not zero out <ul> block margin
  margin-block: 0;

  ${media.md} {
    --gutter: 1rem;
    padding-inline-start: 5.5625rem;
    // 👆 unlikely to happen, but ensure (long/zoomed) utility links don't appear behind logo
  }
`;

const bbbLinksSelector = getBbbLinks();

function BbbLinksToolbar() {
  const links = useSelector(bbbLinksSelector);

  const handleClick = (e) => {
    trackHeaderLinkClick({
      text: e.target.innerText,
      href: e.target.href,
    });
  };

  return (
    <Toolbar className={clsx('bg-gray-20', 'text-size-5', print.nothing, { [hideAt.smDown]: links.length === 0 })}>
      {links.length > 0 && (
        <Center>
          <StyledUl className={clsx('list-reset', 'cluster')}>
            {links.map((link) => {
              return (
                <li key={link.id}>
                  <a className="text-black" href={link.href} onClick={handleClick} style={{ display: 'block' }}>
                    {link.text}
                  </a>
                </li>
              );
            })}
          </StyledUl>
        </Center>
      )}
    </Toolbar>
  );
}

BbbLinksToolbar.displayName = 'Header.BbbLinksToolbar';

export default BbbLinksToolbar;
