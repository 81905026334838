import env from '@iabbb/utils/env';
import { legacy_createStore, applyMiddleware, compose, Reducer, Middleware } from 'redux';
import { thunk } from 'redux-thunk';

interface StoreHolder {
  injectedReducers: Record<string, Reducer>;
  store: {
    replaceReducer(nextReducer: Reducer): void;
  };
  createReducer: (props: { injectedReducers: Record<string, Reducer> }) => Reducer;
}

interface CreateStoreProps {
  createReducer: () => Reducer;
  initialState?: Record<string, object>;
  middleware?: Middleware[];
}

/**
 * Basically an expanded Redux store context to handle injected reducers.
 * This has been superceeded by Contexts and specifically ReactReduxContext, so once implemented in injectReducer this can be removed
 */
export const STORE_HOLDER: StoreHolder = {
  injectedReducers: {},
  store: {
    replaceReducer() {},
  },
  createReducer: () => {
    return (r) => r;
  },
};

const applyMiddlewareAndEnhancers = (customMiddleware: Middleware[]) => {
  const middleware = [thunk, ...customMiddleware];
  const composeEnhancers =
    !env.isProduction && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  return composeEnhancers(applyMiddleware(...middleware));
};

const create = ({ createReducer, initialState = {}, middleware = [] }: CreateStoreProps) => {
  const store = legacy_createStore(createReducer(), initialState, applyMiddlewareAndEnhancers(middleware));

  STORE_HOLDER.store = store;
  STORE_HOLDER.createReducer = createReducer;

  // Add additional fields (extensions) to the store
  return store;
};

export default { create };
