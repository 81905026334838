import { useMemo } from 'react';

import buildCloudinaryImgAttributes from './buildCloudinaryImgAttributes';
import type { ImgAttributes } from './types';

export default function useCloudinaryImgAttributes(
  url: string,
  width: number | null,
  additionalTransformations?: string[],
  includeHighResVersion?: boolean,
): ImgAttributes {
  return useMemo(() => {
    return buildCloudinaryImgAttributes(url, width, additionalTransformations, includeHighResVersion);
  }, [additionalTransformations, includeHighResVersion, url, width]);
}
