import { useIsomorphicLayoutEffect } from '@iabbb/utils/useIsomorphicLayoutEffect';
import React from 'react';
import { Provider } from 'react-redux';
import { Reducer } from 'redux';

import { remove as removeInitialState } from './initialStateUtils';
import reduxStore from './reduxStore';

interface ReduxProviderProps {
  children: React.ReactNode;
  initialState: Record<string, object>;
  createRootReducer: () => Reducer;
}

export function ReduxProvider({ children, initialState, createRootReducer }: ReduxProviderProps) {
  const storeRef = React.useRef(reduxStore.create({ initialState, createReducer: createRootReducer }));

  // run only once
  if (storeRef.current == null) {
    storeRef.current = reduxStore.create({
      initialState,
      createReducer: createRootReducer,
    });
  }

  // Can remove the initial state
  useIsomorphicLayoutEffect(() => {
    removeInitialState();
  }, []);

  return <Provider store={storeRef.current}>{children}</Provider>;
}
