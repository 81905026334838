import urljoin from 'url-join';
import sanitizeStr from '@iabbb/utils/string/sanitizeStr';

const buildGeoUrl = (
  baseUrl: string,
  country: string | null,
  state: string | null,
  city: string | null,
  cities: string[] | null,
): string => {
  const co = country ? country.toLowerCase() : '';
  const sa = state ? state.toLowerCase() : '';
  const ci = city ? sanitizeStr(city) : cities?.[0] ? sanitizeStr(cities[0]) : '';
  return urljoin(baseUrl, co, sa, ci);
};

export default buildGeoUrl;
