import Button from '@iabbb/bds-react/Button';
import Typography from '@iabbb/bds-react/Typography';
import Dialog from '@iabbb/shared/components/UI/Dialog';
import FieldRadio from '@iabbb/bds-react/FieldRadio';
import useCookiesPolicy from '@iabbb/utils/cookies/useCookiesPolicy';
import clsx from 'clsx';
import type { SetStateAction } from 'react';

import CookiesGroup from './CookiesGroup';

export type DialogCookiesPolicyProps = {
  close: () => void;
  isOpen: boolean;
  setSavedCookies: React.Dispatch<SetStateAction<boolean>>;
};

export default function DialogCookiesPolicy({ close, isOpen, setSavedCookies }: DialogCookiesPolicyProps) {
  const [_cookiesPolicy, setCookiesPolicy, setAcceptAllCookiesPolicy] = useCookiesPolicy();

  const handleAcceptAllCookiesClick = (e) => {
    e.preventDefault();

    setAcceptAllCookiesPolicy();

    setSavedCookies(true);
    close();
  };

  const handleSubmit = (event) => {
    if ('FormData' in window) {
      const formData = new FormData(event.target);

      event.preventDefault();

      setCookiesPolicy({
        necessary: true,
        functional: formData.get('functional') === 'true',
        performance: formData.get('performance') === 'true',
        marketing: formData.get('marketing') === 'true',
      });

      setSavedCookies(true);
      close();
    }
  };

  return (
    <Dialog
      className={clsx('cookies-policy-dialog', 'stack')}
      style={{ '--dialog-max-width': '48rem' } as React.CSSProperties}
      withCloseButton={false}
      close={close}
      isOpen={isOpen}
    >
      <Typography variant="h2">Cookie Preferences</Typography>
      <Typography>
        Many websites use cookies or similar tools to store information on your browser or device. We use cookies
        on BBB websites to remember your preferences, improve website performance and enhance user experience, and
        to recommend content we believe will be most relevant to you. Most cookies collect anonymous information
        such as how users arrive at and use the website. Some cookies are necessary to allow the website to
        function properly, but you may choose to not allow other types of cookies below.
      </Typography>
      <form action="/manage-cookies" className="stack" method="post" onSubmit={handleSubmit}>
        <CookiesGroup
          name="Necessary Cookies"
          details="These cookies are necessary for the site to function and cannot be switched off in our systems. They are usually only set in response to actions made by you that amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not work. These cookies do not store any personally identifiable information."
          asFieldset={false}
        >
          <Typography>Necessary cookies must always be enabled.</Typography>
        </CookiesGroup>
        <CookiesGroup
          name="Functional Cookies"
          details="These cookies enable the site to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies, some or all of these services may not function properly."
        >
          <div className="stack">
            <FieldRadio label="Use functional cookies" name="functional" value="true" defaultChecked />
            <FieldRadio label="Do not use functional cookies" name="functional" value="false" />
          </div>
        </CookiesGroup>
        <CookiesGroup
          name="Performance Cookies"
          details="These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance."
        >
          <div className="stack">
            <FieldRadio label="Use performance cookies" name="performance" value="true" defaultChecked />
            <FieldRadio label="Do not use performance cookies" name="performance" value="false" />
          </div>
        </CookiesGroup>
        <CookiesGroup
          name="Marketing Cookies"
          details="These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant content on other sites. They do not store personal information directly, but are based on uniquely identifying your browser or device. If you do not allow these cookies, you will experience less targeted advertising."
        >
          <div className="stack">
            <FieldRadio label="Use marketing cookies" name="marketing" value="true" defaultChecked />
            <FieldRadio label="Do not use marketing cookies" name="marketing" value="false" />
          </div>
        </CookiesGroup>
        <div className={clsx('stack-space-24', 'cluster')}>
          <Button>Save Changes</Button>
          <Button
            name="allow-all"
            value="true"
            onClick={handleAcceptAllCookiesClick}
            type="button"
            variant="unstyled"
          >
            Accept All Cookies
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
