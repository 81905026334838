import {
  updateLegacyDataLayerFindInput,
  updateLegacyDataLayerNearInput,
  updateLegacyDataLayerFindMethod,
  updateLegacyDataLayerNearMethod,
} from '@iabbb/shared/states/Analytics/LegacyDataLayer/Search/actions';

const createActions = ({
  actionTypes: {
    TYPEAHEAD_SUGGESTION_SELECTED,
    TYPEAHEAD_RESULTS_FETCHING,
    TYPEAHEAD_RESULTS_FETCHING_DONE,
    UPDATE_TYPEAHEAD_RESULTS,
    SET_INPUT,
    SELECT_ITEM,
  },
  type,
}) => {
  function typeaheadSuggestionSelected() {
    return { type: TYPEAHEAD_SUGGESTION_SELECTED };
  }

  function fetchingTypeaheadResults() {
    return { type: TYPEAHEAD_RESULTS_FETCHING };
  }

  function fetchingDoneTypeaheadResults() {
    return { type: TYPEAHEAD_RESULTS_FETCHING_DONE };
  }

  function updateTypeaheadResults(results) {
    return {
      type: UPDATE_TYPEAHEAD_RESULTS,
      results,
    };
  }

  function setTypeaheadInput(input: string) {
    return (dispatch) => {
      dispatch({ type: SET_INPUT, input });

      if (type === 'name') {
        dispatch(updateLegacyDataLayerFindInput(input));

        // 👇 new data layer
        if (window.webDigitalData?.search_info?.find_query) {
          window.webDigitalData.search_info.find_query = input;
        }
      } else if (type === 'location') {
        dispatch(updateLegacyDataLayerNearInput(input));

        // 👇 new data layer
        if (window.webDigitalData?.search_info?.near_query) {
          window.webDigitalData.search_info.near_query = input;
        }
      }
    };
  }

  function setTypeaheadSelected(selected) {
    return (dispatch) => {
      dispatch({ type: SELECT_ITEM, selected });

      // 👇 this function can be called with an empty object ({})
      // when the user just types a new query. This will differentiate from actual autocomplete selections.
      const isSelectingItem = !(Object.keys(selected).length === 0);

      if (type === 'name') {
        dispatch(updateLegacyDataLayerFindMethod());

        // 👇 new data layer
        if (window.webDigitalData?.search_info?.find_method) {
          window.webDigitalData.search_info.find_method = isSelectingItem ? 'selected' : 'typed';
        }
      } else if (type === 'location') {
        dispatch(updateLegacyDataLayerNearMethod());

        // 👇 new data layer
        if (window.webDigitalData?.search_info?.near_method) {
          window.webDigitalData.search_info.near_method = isSelectingItem ? 'selected' : 'typed';
        }
      }
    };
  }

  function clearTypeahead() {
    return (dispatch) => {
      dispatch(updateTypeaheadResults([]));
      dispatch(setTypeaheadSelected({}));
      dispatch(setTypeaheadInput(''));
    };
  }

  return {
    typeaheadSuggestionSelected,
    fetchingTypeaheadResults,
    fetchingDoneTypeaheadResults,
    updateTypeaheadResults,
    setTypeaheadInput,
    setTypeaheadSelected,
    clearTypeahead,
  };
};

export default createActions;
