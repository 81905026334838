import urljoin from 'url-join';

import { isTob } from '@iabbb/shared/containers/Header/FindTypeahead/entityType';
import cleanMerge from '@iabbb/utils/cleanMerge';
import { DEFAULT_CULTURE_ID } from '@iabbb/utils/culture/constants';
import getIn from '@iabbb/utils/object/getIn';
import {
  URL_QUERY_FIND_COUNTRY,
  URL_QUERY_FIND_ENTITY,
  URL_QUERY_FIND_ID,
  URL_QUERY_FIND_LATLNG,
  URL_QUERY_FIND_LOCATION,
  URL_QUERY_FIND_TEXT,
  URL_QUERY_FIND_TYPE,
  URL_QUERY_PAGE,
  URL_QUERY_SORT,
  URL_QUERY_CITY,
  URL_QUERY_STATE,
  URL_QUERY_FILTER_ACCREDITED,
  URL_SEGMENT_CATEGORY,
  URL_SEGMENT_COUNTRY_US,
  URL_SEGMENT_COUNTRY_CA,
  URL_SEGMENT_COUNTRY_MX,
  URL_SEGMENT_SEARCH,
  URL_QUERY_FILTER_RATINGS,
  URL_QUERY_FILTER_SA,
  URL_QUERY_INTERACTION_COUNT,
} from '@iabbb/utils/search/constants';
import getFiltersFromCurrentUrl from '@iabbb/utils/search/getFiltersFromCurrentUrl';
import isCategoryUrl from '@iabbb/utils/search/isCategoryUrl';
import isNearMeUrl from '@iabbb/utils/search/isNearMeUrl';
import toLower from '@iabbb/utils/string/toLower';
import trim from '@iabbb/utils/string/trim';
import qs from '@iabbb/utils/url/qs';
import CultureData from '@iabbb/utils/culture/allCultureData';
import { IABBBStage } from '@iabbb/utils/types';

export type ParseUrl = {
  state: string;
  city: string;
};

const categoryRegex = new RegExp(`/${URL_SEGMENT_CATEGORY}/`);
const countryRegex = new RegExp(
  `/${URL_SEGMENT_COUNTRY_US}|/${URL_SEGMENT_COUNTRY_CA}|/${URL_SEGMENT_COUNTRY_MX}`,
);

/**
 * Parses the segments of a category page in order to correctly redirect it in case of filtering or sorting
 */

const parseCategoryUrl = ({ pathname }: { pathname: string }): ParseUrl => {
  const [locationPart] = pathname.split(categoryRegex);

  const [state, city = ''] = trim(locationPart.split(countryRegex)[1], '/').split('/');

  return {
    state,
    city,
  };
};

const parseNearMeUrl = (state: { state: string; citySeo: string }): ParseUrl => ({
  state: state.state,
  city: state.citySeo,
});

const getUserCultureId = ({ state }: { state: IABBBStage | Record<string, object> }): string =>
  getIn(state, ['user', 'cultureId']) || DEFAULT_CULTURE_ID;

export const getCultureId = ({
  state,
  locationTypeaheadStatePath,
}: {
  state: IABBBStage | Record<string, object>;
  locationTypeaheadStatePath: string[];
}): string => getIn(state, [...locationTypeaheadStatePath, 'cultureId']) || getUserCultureId({ state });

/*
 * Used specifically for header search button clicks.
 */
export const createSearchQueries = ({
  state,
  location = typeof window !== 'undefined' ? window.location : null,
  queries = {},
  typeaheadStatePaths: {
    nameTypeaheadStatePath = [] as string[],
    locationTypeaheadStatePath = [] as string[],
  } = {},
  cultureId = DEFAULT_CULTURE_ID,
  isAccreditedCategory = undefined,
}: {
  state: IABBBStage | Record<string, object>;
  location?: Location | URL | null;
  queries: Record<number | string, unknown>;
  typeaheadStatePaths?: {
    nameTypeaheadStatePath?: string[];
    locationTypeaheadStatePath?: string[];
  };
  cultureId: string;
  isAccreditedCategory?: number | undefined;
}): Record<number | string, unknown> => {
  const findInput = getIn(state, [...nameTypeaheadStatePath, 'input']);
  const findSelected = getIn(state, [...nameTypeaheadStatePath, 'selected']);
  const nearInput = getIn(state, [...locationTypeaheadStatePath, 'input']);
  const nearSelected = getIn(state, [...locationTypeaheadStatePath, 'selected']);
  const nearResults = getIn(state, [...locationTypeaheadStatePath, 'results']);

  // Get the cultureId any way possible
  // const cultureId = getCultureId(state);

  const country = getIn(CultureData, ['byId', cultureId, 'threeLetterIsoCountryCode']);

  // When the header search button is clicked, we always want to reset to page 1.
  const page = 1;
  const sortTypesState = getIn(state, ['searchResult', 'sortTypes'])
    ? getIn(state, ['searchResult', 'sortTypes'])
    : [{}];
  const activeSortIndex = sortTypesState.findIndex((sortType) => sortType.isActive);

  const sortType = activeSortIndex !== -1 ? getIn(sortTypesState, [activeSortIndex, 'value']) : null;

  let locationLatLng: string | null = null;

  // If near selected has a value, our preference is to use that.
  // Otherwise, we can check to see if we have results data that matches the user's input.
  if (nearSelected?.latLng) {
    locationLatLng = nearSelected.latLng;
  } else if (nearResults) {
    const matchingResultIndex = nearResults.findIndex(
      (result) => toLower(result.displayText) === toLower(nearInput),
    );
    locationLatLng = matchingResultIndex >= 0 ? getIn(nearResults, [matchingResultIndex, 'latLng']) : null;
  }

  const findId = findSelected && isTob(findSelected.entityType) ? findSelected.id : null;
  const entityId = findSelected && isTob(findSelected.entityType) ? findSelected.entityId : null;
  const entityType = findSelected && isTob(findSelected.entityType) ? findSelected.entityType : null;

  let ratingFilter = '';

  if (findId?.endsWith('_rated')) {
    ratingFilter = 'B';
  }

  let serviceAreaFilter = '';
  if (locationLatLng && findId?.endsWith('_serving')) {
    serviceAreaFilter = '1';
  }

  const inputText = findInput ? findInput.trim() : null;
  const locationText = nearInput ? nearInput.trim() : null;

  const currentQueries = qs.parse(location?.search);

  const category =
    location && isCategoryUrl(location)
      ? parseCategoryUrl(location)
      : location && isNearMeUrl(location)
        ? parseNearMeUrl(getIn(state, ['user', 'location']))
        : {
            state: currentQueries.state,
            city: currentQueries.city,
          };
  // 👆 persist state/city queries after first page

  const searchParams = {
    [URL_QUERY_FIND_TEXT]: inputText,
    [URL_QUERY_FIND_LOCATION]: locationText,
    [URL_QUERY_FIND_ID]: findId,
    [URL_QUERY_FIND_ENTITY]: entityId,
    [URL_QUERY_FIND_TYPE]: entityType,
    [URL_QUERY_FIND_LATLNG]: locationLatLng,
    [URL_QUERY_FIND_COUNTRY]: country,
    [URL_QUERY_PAGE]: page,
    [URL_QUERY_SORT]: sortType,

    // if a category url then will have these
    [URL_QUERY_STATE]: category.state,
    [URL_QUERY_CITY]: category.city,
    [URL_QUERY_FILTER_RATINGS]: ratingFilter,
    [URL_QUERY_FILTER_SA]: serviceAreaFilter,
  };

  if (isAccreditedCategory) {
    searchParams[URL_QUERY_FILTER_ACCREDITED] = isAccreditedCategory;
  }

  const currentFilters = (location && getFiltersFromCurrentUrl(location)) ?? {};
  const initialQueries = cleanMerge({}, searchParams, currentFilters);

  // Merge in the additional queries that have been possibly passed to this function as they supersede everything else
  return cleanMerge({}, { ...initialQueries, ...queries });
};

export const createSearchPageUrl = ({
  state,
  location,
  queries = {},
  isAccreditedCategory = undefined,
}: {
  state: IABBBStage;
  location?: Location | URL;
  queries?: Record<number | string, unknown>;
  isAccreditedCategory?: number | undefined;
}): string => {
  const searchQueries = createSearchQueries({
    state,
    location,
    queries,
    typeaheadStatePaths: {
      nameTypeaheadStatePath: ['header', 'findTypeahead'],
      locationTypeaheadStatePath: ['header', 'nearTypeahead'],
    },
    cultureId: getCultureId({ state, locationTypeaheadStatePath: ['header', 'nearTypeahead'] }),
    isAccreditedCategory,
  });

  return urljoin(process.env.NEXT_PUBLIC_BASE_URL, URL_SEGMENT_SEARCH, `?${qs.stringify(searchQueries)}`);
};

export const createNewHistoryEntry = ({ newQueries }: { newQueries: Record<number | string, any> }): string => {
  const { pathname, search } = window.location;
  const currentQueries = qs.parse(search);

  // First merge of old & new queries and allow empty values, then clean it all up
  const allQueries = cleanMerge(
    {},
    {
      ...currentQueries,
      ...newQueries,
      // used by our ad vendor, epom, to track whether a user interaction was performed
      // by default, they don't support client side nav, so this is a workaround
      [URL_QUERY_INTERACTION_COUNT]: Number.parseInt(currentQueries[URL_QUERY_INTERACTION_COUNT] || 0, 0) + 1,
    },
  );

  // No matter what make this a search off of /{something}
  const destUrl = `${pathname}?${qs.stringify(allQueries)}`;

  return destUrl;
};
