import { useDispatch, useSelector } from 'react-redux';

import { removeCookie, setCookie } from './actions';
import { selectCookieByName } from './selectors';

/**
 * Given a cookie name, gets a cookie's value.
 * While reading a cookie value works on the server-side, updating and deleting will only work on the client-side.
 * This essentially wraps `react-use`'s `useCookie` with partial SSR support.
 * @param cookieName - name of cookie to fetch
 * @returns [cookie, setCookie, deleteCookie]
 */
export default function useCookie<T>(cookieName: string) {
  const dispatch = useDispatch();

  const cookie: T = useSelector(selectCookieByName(cookieName));

  const updateCookie = (value: T, expirationInDays: number | null = null) => {
    if (typeof window === 'undefined') {
      // biome-ignore lint/suspicious/noConsole: debugging
      console.error(
        `Cannot set cookie ${cookieName}=${value} on the server. If you need to do this on page load, wrap this call in a useEffect hook.`,
      );

      return;
    }

    dispatch(
      setCookie({
        expiration: expirationInDays,
        name: cookieName,
        value,
      }) as never,
    );
  };

  const deleteCookie = () => {
    if (typeof window === 'undefined') {
      // biome-ignore lint/suspicious/noConsole: debugging
      console.error(
        `Cannot delete cookie ${cookieName} on the server. If you need to do this on page load, wrap this call in a useEffect hook.`,
      );

      return;
    }

    dispatch(removeCookie(cookieName) as never);
  };

  return [cookie, updateCookie, deleteCookie];
}
