import { useNavigator } from '@iabbb/context/Navigator';
import trimTrailingSlash from '@iabbb/utils/url/trimTrailingSlash';
import clsx from 'clsx';

import { NavLink } from '../../../types';

export interface SubNavItemProps {
  className?: string;
  link: NavLink;
}

function SubNavItem({ className, link }: SubNavItemProps) {
  const navigator = useNavigator();

  const url = new URL(trimTrailingSlash(link.href), process.env.NEXT_PUBLIC_BASE_URL);

  return (
    <li>
      <a
        aria-current={navigator.url.href.replace(/\/$/, '') === url.href.replace(/\/$/, '') ? 'page' : undefined}
        className={clsx(className, 'dtm-header-subnav-menu-link')}
        href={url.href}
      >
        {link.text}
      </a>
    </li>
  );
}

export default SubNavItem;
