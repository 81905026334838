import { fetchSuggestFind } from '@iabbb/utils/api/SuggestApi';
import getCountryCodeFromCultureOrDefault from '@iabbb/utils/culture/getCountryCodeFromCultureOrDefault';

const entityTypeMap = (type: string) => {
  switch (type) {
    case 'Category':
    case 'NearMe':
    case 'Spellcheck':
      return 'Categories';
    case 'Organization':
      return 'Businesses';
    default:
      return type;
  }
};

const createResultsAction = ({ entityTypes, actions, suggestNameApiFetch = fetchSuggestFind }) => {
  function fetchAndUpdateTypeaheadResults({ searchTerm, maxMatches = 10, cultureId, latLng, primaryOnly }) {
    return (dispatch) => {
      dispatch(actions.fetchingTypeaheadResults());
      if (!searchTerm) {
        return null;
      }

      const country = getCountryCodeFromCultureOrDefault(cultureId);
      const fetchParams = { country, input: searchTerm, latLng, maxMatches, entityTypes, primaryOnly };
      return suggestNameApiFetch(fetchParams)
        .then((response) => {
          dispatch(actions.fetchingDoneTypeaheadResults());

          let suggestions;
          if (response.length === 0) {
            suggestions = [];
          } else {
            suggestions = response.reduce((draft, suggestion) => {
              const sectionTitle = entityTypeMap(suggestion.entityType);
              const sectionIndex = draft.findIndex((s) => s.title === sectionTitle);

              if (sectionIndex === -1) {
                draft.push({
                  title: sectionTitle,
                  suggestions: [suggestion],
                });
              } else {
                draft[sectionIndex].suggestions.push(suggestion);
              }
              return draft;
            }, []);
          }

          dispatch(actions.updateTypeaheadResults(suggestions));
        })
        .catch(() => {
          dispatch(actions.fetchingDoneTypeaheadResults());
        });
    };
  }
  return { fetchAndUpdateTypeaheadResults };
};

export default createResultsAction;
