import { trackSearchCompleted, trackSearchExecuted } from '@iabbb/shared/containers/Header/analytics';
import { redirect } from '@iabbb/utils/browser/location';
import getIn from '@iabbb/utils/object/getIn';
import { createSearchPageUrl } from '@iabbb/utils/search/searchUrlUtils';
import { setCookie } from '@iabbb/shared/states/User/actions';
import CookieNames from '@iabbb/utils/Storage/CookieNames';
import { produce } from 'immer';
import { IABBBStage } from '@iabbb/utils/types';

export const SUBMIT_SEARCH_SUCCESSFUL = 'Header/SUBMIT_SEARCH_SUCCESSFUL';
export const SUBMIT_SEARCH_UNSUCCESSFUL = 'Header/SUBMIT_SEARCH_UNSUCCESSFUL';

export function submitSearchSuccessful() {
  return { type: SUBMIT_SEARCH_SUCCESSFUL };
}

export function submitSearchUnsuccessful() {
  return { type: SUBMIT_SEARCH_UNSUCCESSFUL };
}

/**
 * Submits a search
 * @returns {function(*, *)}
 */
export function submitSearch({
  isBot,
  queries,
  isAccreditedCategory,
}: {
  isBot?: boolean;
  queries?: Record<number | string, unknown>;
  isAccreditedCategory?: boolean;
}) {
  return (dispatch, getState) => {
    // If it's a bot, let's stop what we're doing here.
    if (isBot) {
      return dispatch(submitSearchUnsuccessful());
    }

    const state = getState();

    const findInput = getIn(state, ['header', 'findTypeahead', 'input']);
    let findSelected = getIn(state, ['header', 'findTypeahead', 'selected']);
    const typeaheadResults = getIn(state, ['header', 'findTypeahead', 'results']);
    //const isAccreditedCategory = getIn(state, ['searchResult', 'accredited']);

    if (!findSelected?.id && typeaheadResults) {
      typeaheadResults.forEach((group) => {
        group.suggestions.forEach((suggestion) => {
          if (suggestion.title.replace(/(<([^>]+)>)/gi, '').toLowerCase() === findInput.toLowerCase()) {
            findSelected = suggestion;
          }
        });
      });
    }

    const changedState = produce<IABBBStage>(state, (draft) => {
      draft.header.findTypeahead.selected = findSelected;
    });

    if (findSelected?.id?.endsWith('_accredited')) {
      dispatch(
        setCookie({
          name: CookieNames.ACCREDITED_SEARCH,
          value: true,
        } as never),
      );
      dispatch(
        setCookie({
          name: CookieNames.ACCREDITED_TOGGLE_STATE,
          value: 'seen',
        } as never),
      );
    }

    trackSearchCompleted();
    trackSearchExecuted();

    // Go to url
    const searchUrl = createSearchPageUrl({
      state: changedState,
      queries,
      isAccreditedCategory: isAccreditedCategory ? 1 : 0,
    });

    redirect(searchUrl);

    return dispatch(submitSearchSuccessful());
  };
}
