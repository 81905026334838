import getIn from '@iabbb/utils/object/getIn';
import createSelectors from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createSelectors';
import { TYPEAHEAD_STATE_PATH } from './constants';

const {
  getTypeahead,
  getInput,
  getIsFetching,
  getPlaceholder,
  getSelected,
  getResultsWithCurrentLocation,
  getTypeaheadCultureId,
} = createSelectors({
  typeaheadStatePath: TYPEAHEAD_STATE_PATH,
  selectFns: { selectTypeaheadCultureId: (state) => getIn(state, ['header', 'localeSelector', 'cultureId']) },
});

export {
  getTypeahead,
  getResultsWithCurrentLocation,
  getInput,
  getIsFetching,
  getPlaceholder,
  getSelected,
  getTypeaheadCultureId,
};
