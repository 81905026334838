export const SET_SPINNER_VISIBILITY = 'Global/SET_SPINNER_VISIBILITY';

let spinnerLastShown: number | null = null;

export function showSpinner() {
  spinnerLastShown = Date.now();
  return {
    type: SET_SPINNER_VISIBILITY,
    show: true,
  };
}

export function hideSpinner() {
  return (dispatch) => {
    let timeSinceSpinnerLastShown = 0;
    if (spinnerLastShown !== null) {
      timeSinceSpinnerLastShown = Date.now() - spinnerLastShown;
    }

    return new Promise<void>((resolve) => {
      // show the spinner for at least 1 second.
      // If the spinner has been visible for more than a second, hide it immediately
      window.setTimeout(
        () => {
          dispatch({
            type: SET_SPINNER_VISIBILITY,
            show: false,
          });

          resolve();
        },
        Math.max(0, 1000 - timeSinceSpinnerLastShown),
      );
    });
  };
}
