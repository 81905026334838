import aaDirectCall from '@iabbb/utils/analytics/adobe/directCall';
import { DTM_SEARCH_COMPLETED, DTM_SEARCH_EXECUTED } from '@iabbb/utils/analytics/adobe/constants';

const trackSearchCompleted = () => {
  aaDirectCall({ name: DTM_SEARCH_COMPLETED });
};

const trackSearchExecuted = () => {
  aaDirectCall({ name: DTM_SEARCH_EXECUTED });
};

export { trackSearchCompleted, trackSearchExecuted };
