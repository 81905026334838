import { createSelector } from 'reselect';
import { DEFAULT_BBB_ID } from '@iabbb/utils/bbbInfo/constants';
import getIn from '@iabbb/utils/object/getIn';
import { createNavigationLinks } from '@iabbb/utils/navUtils';

const selectTopNavLinks = (state: Record<string, object>) =>
  getIn(state, ['user', 'location', 'bbbInfo', 'navigation', 'topNavLinks'], []);

const selectBbbId = (state: Record<string, object>) => getIn(state, ['user', 'location', 'bbbInfo', 'bbbId']);

export const getBbbLinks = () =>
  createSelector([selectTopNavLinks, selectBbbId], (topNavLinks, bbbId) =>
    bbbId !== DEFAULT_BBB_ID ? createNavigationLinks(topNavLinks) : [],
  );
