import Button from '@iabbb/bds-react/Button';
import { useNavigator } from '@iabbb/context/Navigator';
import Center from '@iabbb/shared/components/General/Center';
import useCookie from '@iabbb/shared/states/User/useCookie';
import loadScript from '@iabbb/utils/browser/loadScript';
import useCookiesPolicy from '@iabbb/utils/cookies/useCookiesPolicy';
import CookieNames from '@iabbb/utils/Storage/CookieNames';
import { useEffect, useId, useRef, useState } from 'react';

import DialogCookiesPolicy from './DialogCookiesPolicy';

import './styles.css';

export default function CookiesMessageComponent() {
  const navigator = useNavigator();
  const isManageCookiesPage = navigator.url.pathname === '/manage-cookies';

  const [_cookiesPolicy, _setCookiesPolicy, setAcceptAllCookiesPolicy] = useCookiesPolicy();
  const [cookiesPreferencesSet] = useCookie(CookieNames.COOKIES_PREFERENCES_SET);
  const [isCookiesPolicyDialogOpen, setIsCookiesPolicyDialogOpen] = useState(false);
  const [showCookiesMessage, setShowCookiesMessage] = useState(!cookiesPreferencesSet && !isManageCookiesPage);
  const [savedCookies, setSavedCookies] = useState(false);
  const [showManageCookiesAsButton, setShowManageCookiesAsButton] = useState(true);
  const savedCookiesMessageRef = useRef<HTMLParagraphElement>(null);
  const cookiesMessageSectionRef = useRef<HTMLDivElement>(null);

  const handleManageCookiesClick = () => {
    setIsCookiesPolicyDialogOpen(true);
  };

  const headingId = useId();

  const handleAcceptAllCookiesSubmit = (event) => {
    event.preventDefault();
    setAcceptAllCookiesPolicy();
    setSavedCookies(true);
  };

  const handleHideThisMessageClick = () => {
    setShowCookiesMessage(false);
    loadScript('/TerminusContent/dist/page-load-dialogs.min.js');
  };

  // If cookies are saved, focus the message
  useEffect(() => {
    if (!savedCookies) {
      return;
    }

    if (!savedCookiesMessageRef.current) {
      return;
    }

    savedCookiesMessageRef.current.focus();
  }, [savedCookies]);

  // 👇 progressive enhancement: until JS loads, just link to /manage-cookies
  useEffect(() => {
    setShowManageCookiesAsButton(true);
  }, []);

  // Add scroll padding to prevent focused elements from being obscured by this message.
  // Credit: https://codepen.io/TPG/pen/QWVMXMg
  // Changed: Added overflow: auto to inline styles, as scroll-padding was otherwise not taking effect
  // Changed: references to header were switched to cookies message container, and scroll-padding-top was switched to scroll-padding-bottom
  useEffect(() => {
    if (!showCookiesMessage) {
      return;
    }

    if (!cookiesMessageSectionRef.current) {
      return;
    }

    // Config: specify a limiting media query
    // n.b. this is so small-screen/high-zoom layouts can un-stick the header
    // in which case, scroll-padding is unnecessary and wastes valuable space
    // so it will only be applied if the page matches this media query
    const MEDIA_MATCH = '(min-width: 20em)';

    // Config: specify extra padding to allow for focus outlines
    // n.b. this avoids the possibility of the outline being slightly clipped,
    // when a focused element is close to the threshold, since scroll-padding
    // applies to the layout box, and outline isn't part of the layout box
    const EXTRA_PADDING = 6;

    //create a style node and get its stylesheet reference
    const style = document.head.appendChild(document.createElement('style'));

    const scrollFocusedElementIntoView = (height: number) => {
      if (!cookiesMessageSectionRef.current) {
        return;
      }

      // if an element outside the cookies message is currently focused,
      // and the change in height has caused it to become obscured,
      // then scroll it into view (as though the user had tabbed there)
      // n.b. this prevents active focus being obscured on the fly
      // but is not technically required for 2.4.11 conformance
      const focused = document.activeElement || document.body;
      if (focused !== document.body && !cookiesMessageSectionRef.current.contains(focused)) {
        if (focused.getBoundingClientRect().top < height) {
          focused.scrollIntoView();
        }
      }
    };

    const applyScrollPadding = () => {
      if (!style) {
        return;
      }

      if (!cookiesMessageSectionRef.current) {
        return;
      }

      // get the cookies message height, and add a little extra as specified
      const height = cookiesMessageSectionRef.current.offsetHeight + EXTRA_PADDING;

      // create a new rule for scroll padding from the cookies message height
      // filtered using :has() if that's supported
      // so that it doesn't apply to elements inside the cookies message
      // n.b. it's fine to use pixels, because any change in height
      // caused by text resizing will trigger the resize observer
      if ('CSS' in window && CSS.supports('selector(:has(*))')) {
        style.innerHTML = `html:has(:not(.cookies-message *, .cookies-policy-dialog *):focus)  { scroll-padding-bottom:${height}px; overflow: auto; }`;
      } else {
        style.innerHTML = `html { scroll-padding-bottom:${height}px; overflow: auto; }`;
      }

      scrollFocusedElementIntoView(height);
    };

    if ('ResizeObserver' in window) {
      // define a resize observer to monitor the size of the header
      // n.b. this fires on first observation, then for anything that resizes
      // the elements, eg. font size, text spacing, window size or orientation
      const observer = new ResizeObserver(applyScrollPadding);

      // function: enable resize monitor and apply scroll-padding
      // n.b. this is abstracted so we can turn it on and off
      // in response to changes in the media query match status
      const enableResizeMonitor = () => {
        if (!cookiesMessageSectionRef.current) {
          return;
        }

        observer.observe(cookiesMessageSectionRef.current);
      };

      // function: disable resize monitor and remove applied scroll-padding
      const disableResizeMonitor = () => {
        if (!cookiesMessageSectionRef.current) {
          return;
        }

        if (!style) {
          return;
        }

        observer.unobserve(cookiesMessageSectionRef.current);
        style.innerHTML = '';
      };

      // pass the limiting media query to matchMedia to get the initial match
      // then define a change listener to update this when the match changes
      // and either enable or disable the resize monitor accordingly
      const query = window.matchMedia(MEDIA_MATCH);
      query.addEventListener('change', (e) => {
        if (e.matches) {
          enableResizeMonitor();
        } else {
          disableResizeMonitor();
        }
      });

      // if we already match the current media, enable the monitor
      if (query.matches) {
        enableResizeMonitor();
      }
    }
  }, [showCookiesMessage]);

  if (!showCookiesMessage) {
    return null;
  }

  return (
    <>
      <section aria-labelledby={headingId} className="cookies-message" ref={cookiesMessageSectionRef}>
        <Center>
          <h2 className="visually-hidden" id={headingId}>
            Cookies on BBB.org
          </h2>
          {!savedCookies ? (
            <div className="layout">
              <p>
                We use cookies to give users the best content and online experience. By clicking “Accept All
                Cookies”, you agree to allow us to use all cookies. Visit our{' '}
                <a href={`${process.env.NEXT_PUBLIC_PRIVACY_POLICY_URL}#cookies`} rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>{' '}
                to learn more.
              </p>
              <div className="cluster">
                <form action="/manage-cookies" onSubmit={handleAcceptAllCookiesSubmit} method="post">
                  <Button name="allow-all" value="true" variant="standard">
                    Accept All Cookies
                  </Button>
                </form>
                {showManageCookiesAsButton ? (
                  <Button onClick={handleManageCookiesClick} variant="unstyled">
                    Manage Cookies
                  </Button>
                ) : (
                  <a href="/manage-cookies">Manage Cookies</a>
                )}
              </div>
            </div>
          ) : (
            <div className="layout">
              <p ref={savedCookiesMessageRef} tabIndex={-1}>
                Your cookie settings have been saved. You can{' '}
                <a href="/manage-cookies">change your cookie settings</a> at any time.
              </p>
              <Button onClick={handleHideThisMessageClick}>Hide This Message</Button>
            </div>
          )}
        </Center>
      </section>
      {showManageCookiesAsButton && (
        <DialogCookiesPolicy
          isOpen={isCookiesPolicyDialogOpen}
          close={() => {
            setIsCookiesPolicyDialogOpen(false);
          }}
          setSavedCookies={setSavedCookies}
        />
      )}
    </>
  );
}
