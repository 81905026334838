import { useDispatch, useSelector } from 'react-redux';

import IconMapMarker from '@iabbb/icons/MapMarker';
import InputGroup from '@iabbb/shared/components/General/InputGroup';
import TypeaheadAutocomplete from '@iabbb/shared/components/Typeahead/Provider/Autocomplete';
import TypeaheadClear from '@iabbb/shared/components/Typeahead/Provider/Clear';
import TypeaheadLabel from '@iabbb/shared/components/Typeahead/Provider/Label';
import Suggestion from '@iabbb/shared/containers/Header/NearTypeahead/Suggestion';

import getIn from '@iabbb/utils/object/getIn';

import Typeahead from './Typeahead';
import {
  clearHomeLocationTypeahead,
  fetchAndUpdateHomeLocationTypeaheadResults,
  setHomeLocationTypeaheadInput,
  setHomeLocationTypeaheadSelected,
} from './actions';
import { getInput, getIsFetching, getPlaceholder, getResultsWithCurrentLocation } from './selectors';

const name = 'homeLocationTypeahead';

interface HomeLocationTypeaheadProps {
  displayText?: string;
  displayTextOriginal?: string;
  currentLocation?: string;
}

export function HomeLocationTypeahead() {
  const dispatch = useDispatch();
  const placeholder = useSelector(getPlaceholder());
  const suggestions = useSelector(getResultsWithCurrentLocation());
  const input = useSelector(getInput());
  const isFetching = useSelector(getIsFetching());
  const userPhysicalLocation = useSelector((state: Record<string, object>) =>
    getIn(state, ['user', 'physicalLocation']),
  );

  const setInput = (data) => dispatch(setHomeLocationTypeaheadInput(data) as never);
  const setSelected = (data) => dispatch(setHomeLocationTypeaheadSelected(data) as never);
  const fetchAndUpdateResults = (data) => dispatch(fetchAndUpdateHomeLocationTypeaheadResults(data) as never);
  const clear = () => dispatch(clearHomeLocationTypeahead() as never);

  const onItemSelected = (item: HomeLocationTypeaheadProps | null) => {
    if (item?.displayText) {
      if (item.currentLocation) {
        const [userCity, userState] = userPhysicalLocation.split(', ');
        setSelected({});
        setInput(`${userCity}, ${userState}`);
      } else {
        setSelected(item);
      }
    }
  };

  // the 'current location' option doesn't populate the typeahead with 'current location';
  // when selected, use the original displayText value;
  const getSuggestionValue = (suggestion: HomeLocationTypeaheadProps) =>
    suggestion.displayTextOriginal ? suggestion.displayTextOriginal : suggestion.displayText;

  const renderSuggestion = (suggestion: HomeLocationTypeaheadProps, { query }: { query: string }) => {
    const props = {
      suggestion,
      query,
    };

    return <Suggestion {...props} />;
  };

  return (
    <Typeahead
      fetchAndUpdateResults={fetchAndUpdateResults}
      getSuggestionValue={getSuggestionValue}
      inputProps={{ value: input, name, placeholder }}
      isFetching={isFetching}
      onClickClear={clear}
      onItemSelected={onItemSelected}
      renderSuggestion={renderSuggestion}
      setInput={setInput}
      setSelected={setSelected}
      shouldRenderSuggestions={() => true}
      suggestions={suggestions}
    >
      <TypeaheadLabel as={InputGroup.Label} className="dtm-locale-selector-location-pin" variant="location">
        <IconMapMarker />
        Near
      </TypeaheadLabel>
      <TypeaheadAutocomplete />
      <TypeaheadClear />
    </Typeahead>
  );
}

HomeLocationTypeahead.displayName = 'HomeLocationTypeahead';

export default HomeLocationTypeahead;
