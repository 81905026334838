import { Draft, produce } from 'immer';

import { SUBMIT_FORM } from './actionTypes';

interface FormState {
  name?: string;
  fields?: unknown[];
  step?: number | null;
  id?: string;
}

interface SubmitFormAction {
  type: typeof SUBMIT_FORM;
  name: string;
  emptyFields: unknown[];
  step?: number | null;
  id: string;
}

const initialState = {};

export default produce((draft: Draft<FormState>, action: SubmitFormAction) => {
  switch (action.type) {
    case SUBMIT_FORM:
      draft.name = action.name;
      draft.fields = action.emptyFields;
      draft.step = action.step ?? null;
      draft.id = action.id;
      break;

    default:
  }
}, initialState);
