import { useEffect } from 'react';

/*
 ** Uses ResizeObserver where supported, and falls back to a `resize` event listener when it is not.
 */
export default function useEnhancedResizeListener(
  elementToObserve: Element | null,
  functionToExecute: (element: Element) => void,
) {
  useEffect(() => {
    if (!elementToObserve) {
      return;
    }

    const refreshInternalState = (element: Element = elementToObserve) => {
      functionToExecute(element);
    };

    const resizeListener = () => {
      refreshInternalState();
    };

    // run immediately to cover initial callback ref setting
    refreshInternalState();

    let observer: ResizeObserver | undefined; // we want this available in the `cleanup`

    if ('ResizeObserver' in window) {
      observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          refreshInternalState(entry.target);
        }
      });

      observer.observe(elementToObserve);
    } else if (typeof window !== 'undefined') {
      const windowObj = window as Window;
      windowObj.addEventListener('resize', resizeListener, { passive: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      } else {
        window.removeEventListener('resize', resizeListener);
      }
    };
  }, [elementToObserve, functionToExecute]);
}
