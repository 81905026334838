import { useDebounce } from '@iabbb/utils/function/debounce';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import React, { useId } from 'react';

import DefaultStyled from './styles';

export function useTypeaheadContext() {
  const context = React.useContext(TypeaheadContext);
  if (!context) {
    throw new Error(`Typeahead compound components cannot be rendered outside the Typeahead component`);
  }
  return context;
}

const TypeaheadContext = React.createContext();

function TypeaheadComponent(props) {
  const {
    children,
    fetchAndUpdateResults,
    getSectionSuggestions = () => {},
    getSuggestionValue,
    inputProps,
    isFetching = false,
    Label,
    multiSection = false,
    onClickClear,
    onItemSelected = () => {},
    renderSectionTitle = () => {},
    renderSuggestion,
    selected = {},
    setInput,
    setSelected,
    shouldRenderSuggestions = (value) => value && value.trim().length >= 1,
    showLabelOnRight,
    styled: Typeahead = DefaultStyled,
    suggestions = [],
    popularSuggestions = [],
    fetchAndUpdateResultsParams = {},
    ...otherProps
  } = props;
  const inputId = useId();
  const labelId = useId();
  const menuId = useId();

  const debouncedFetchAndUpdateResults = useDebounce(
    fetchAndUpdateResults,
    process.env.NEXT_PUBLIC_TYPEAHEAD_DEBOUNCE_DELAY,
  );

  const context = {
    getSectionSuggestions,
    inputProps,
    isFetching,
    multiSection,
    onClickClear,
    renderSectionTitle,
    renderSuggestion,
    shouldRenderSuggestions,
    styled: Typeahead,
    suggestions,
    popularSuggestions,
  };

  /**
   * Executed whenever the input changes
   * @param {string} value - value in the text box
   */
  const onInputValueChange = (value) => {
    if (typeof value === 'string') {
      setInput(value);

      debouncedFetchAndUpdateResults(value, fetchAndUpdateResultsParams);
    }
  };

  const onStateChange = (changes, _stateAndHelpers) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        setSelected({}); // WEB-3846: ensure that typing clears previously selected
        break;
      default:
        break;
    }
  };

  return (
    <Downshift
      inputId={inputId}
      labelId={labelId}
      menuId={menuId}
      inputValue={inputProps.value}
      itemToString={getSuggestionValue}
      onChange={onItemSelected}
      onInputValueChange={onInputValueChange}
      onStateChange={onStateChange}
      selectedItem={selected}
    >
      {({ getRootProps, ...otherDownshiftProps }) => {
        return (
          <Typeahead
            {...getRootProps(
              {
                // 👇 all of these should be on the input instead
                // seems like Downshift may have fixed this with their `use...` hooks
                // if we migrate to those, we probably won't have to monkey patch this
                role: undefined,
                'aria-expanded': undefined,
                'aria-haspopup': undefined,
                'aria-labelledby': undefined,
                'aria-owns': undefined,
              },
              { suppressRefError: true },
            )}
            {...otherProps}
          >
            <TypeaheadContext.Provider value={{ ...context, ...otherDownshiftProps }}>
              {children}
            </TypeaheadContext.Provider>
          </Typeahead>
        );
      }}
    </Downshift>
  );
}

TypeaheadComponent.propTypes = {
  fetchAndUpdateResults: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  inputProps: PropTypes.shape({
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onClickClear: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  setInput: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  getSectionSuggestions: PropTypes.func,
  isFetching: PropTypes.bool,
  onItemSelected: PropTypes.func,
  multiSection: PropTypes.bool,
  renderSectionTitle: PropTypes.func,
  selected: PropTypes.object,
  shouldRenderSuggestions: PropTypes.func,
  styled: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  suggestions: PropTypes.array,
  popularSuggestions: PropTypes.array,
};

TypeaheadComponent.displayName = 'Typeahead';
export default TypeaheadComponent;
