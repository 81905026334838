const initialState = {
  pageName: '',
  siteSection: '',
  siteSubsection1: '',
  siteSubsection2: '',
  siteSubsection3: '',
  siteSubsection4: '',
  siteSubsection5: '',
  locationLevel: '',
  location: '',
  type: '',
  city: '',
  state: '',
  country: '',
  accredited: '',
  category: '',
};

export default function pageReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
