import { combineReducers } from 'redux';

import bbbInfo from './BbbInfo/reducer';
import businessInfo from './BusinessInfo/reducer';
import form from './Form/reducer';
import hasLoaded from './HasLoaded/reducer';
import search from './Search/reducer';
import page from './Page/reducer';
import settings from './Settings/reducer';
import user from './User/reducer';

const legacyDataLayerReducer = combineReducers({
  bbbInfo,
  businessInfo,
  form,
  hasLoaded,
  search,
  page,
  settings,
  user,
});

export default legacyDataLayerReducer;
