import urljoin from 'url-join';

import request from '@iabbb/utils/api/request';
import { API_BASE_URL } from '@iabbb/utils/api/constants';
import queryString from '@iabbb/utils/url/qs';
import { FetchBbbLocationParams } from './types';

const buildApiUrl = (...segments: string[]) =>
  urljoin(process.env.NEXT_PUBLIC_BASE_WEB_API_URL, API_BASE_URL, 'location', ...segments);

/**
 * Fetches from /bbblocation which converts the solr location and bbb info into one bbbLocation
 * @param {string} baseUrl - base url of the endpoint
 */
const fetchBbbLocation = ({ locationId, bbbId }: FetchBbbLocationParams) => {
  const url = buildApiUrl(`/bbblocation?${queryString.stringify({ locationId, bbbId })}`);
  return request(url);
};

/**
 * Fetches from /getbbbid which converts the solr location and bbb info into one bbbLocation
 */
const fetchBbbIdByPostalCode = ({ postalCode }: { postalCode: string }) => {
  const url = buildApiUrl(`/getbbbid?${queryString.stringify({ postalCode })}`);
  return request(url);
};

/**
 * Fetches from /getbostonzips which gets postal codes associated with the Boston BBB
 */
const fetchPostalCodesForBoston = () => {
  const url = buildApiUrl(`/getbostonzips`);
  return request(url);
};

export { fetchBbbIdByPostalCode, fetchBbbLocation, fetchPostalCodesForBoston };
