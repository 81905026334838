import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import useShowAndHide from '@iabbb/utils/display/useShowAndHide';

import TypeaheadError from './TypeaheadError';

interface TypeaheadErrorContainerProps {
  children: React.ReactNode;
  errorText: string;
  hasError: boolean;
  style?: React.CSSProperties;
  fullWidth?: boolean;
}

const Div = styled.div`
  height: 100%;
  position: relative;
`;

function TypeaheadErrorContainer({
  children,
  errorText,
  hasError,
  fullWidth,
  style,
  ...props
}: TypeaheadErrorContainerProps) {
  const errorIsVisible = useShowAndHide(hasError);

  return (
    <Div style={fullWidth ? { ...{ width: '100%' }, ...(style ?? {}) } : style} {...props}>
      {children}
      <TypeaheadError
        className="text-black text-size-7"
        hide={!errorIsVisible}
        data-testid={`typeahead-error${errorIsVisible ? `--is-visible` : ''}`}
      >
        {errorText}
      </TypeaheadError>
    </Div>
  );
}

TypeaheadErrorContainer.propTypes = {
  children: PropTypes.node.isRequired,
  errorText: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
};

TypeaheadErrorContainer.displayName = 'TypeaheadErrorContainer';
export default TypeaheadErrorContainer;
