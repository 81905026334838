import { SocialMediaType } from '@iabbb/utils/socialMedia';
import React from 'react';

import Icon from './Icon';
import { StyledLink } from './styles';
export interface SocialLinkProps extends React.ComponentPropsWithoutRef<'a'> {
  id: SocialMediaType;
  name: string;
  url: string;
}

function SocialLinkComponent({ id, name, url, ...props }: SocialLinkProps) {
  return (
    <StyledLink href={url} target="_blank" {...props}>
      <Icon type={id} />
      <span className="visually-hidden">our {name} (opens in a new tab)</span>
    </StyledLink>
  );
}

export default SocialLinkComponent;
