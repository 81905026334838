import styled from '@emotion/styled';

export default styled.div`
  position: relative;

  & > [role='combobox'] {
    align-items: center;
    block-size: 100%;
    border-left: 1px solid var(--bds-color-gray-40);
    border: none;
    cursor: pointer;
    display: flex;
    gap: 0.25rem;
    padding: 0.5rem;

    img {
      block-size: auto;
      inline-size: 1.0625rem;
      transform: translateY(0.05em);
    }

    svg {
      inline-size: 0.625em;
    }
  }

  & > [role='listbox'] {
    margin-block: 0;
    position: absolute;
    right: 0;
    width: max-content;
    z-index: 1;

    & > [role='option'] {
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem 0.875rem;

      &:focus {
        background-color: var(--bds-color-gray-50);
      }

      img {
        block-size: auto;
        transform: translateY(0.05em);
        inline-size: 1.0625rem;
      }

      span {
        flex-shrink: 0;
      }
    }
  }
`;
