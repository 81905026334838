import { produce } from 'immer';

import { CLEAR, SET_CULTURE_ID, SHOW_MENU } from '@iabbb/shared/containers/Header/LocaleSelector/actions';
import {
  SET_INPUT,
  UPDATE_TYPEAHEAD_RESULTS,
  TYPEAHEAD_RESULTS_FETCHING,
  TYPEAHEAD_RESULTS_FETCHING_DONE,
  SELECT_ITEM,
} from '@iabbb/shared/containers/Header/LocaleSelector/Modal/ChooseLocationState/HomeLocationTypeahead/actionTypes';
import { SHOW_MENU as NAV_SHOW_MENU } from '@iabbb/shared/containers/Header/NavMenu/actions';
import { SET_CULTURE_ID as SET_USER_CULTURE_ID } from '@iabbb/shared/states/User/actionTypes';

const initialState = {
  menuOpen: false,
  cultureId: '',
  homeLocationTypeahead: {
    input: '',
    results: [],
    selected: {},
    isFetching: false,
  },
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_INPUT:
      draft.homeLocationTypeahead.input = action.input;
      break;
    case UPDATE_TYPEAHEAD_RESULTS:
      draft.homeLocationTypeahead.results = action.results;
      break;
    case SELECT_ITEM:
      draft.homeLocationTypeahead.selected = action.selected;
      break;
    case TYPEAHEAD_RESULTS_FETCHING:
      draft.homeLocationTypeahead.isFetching = true;
      break;
    case TYPEAHEAD_RESULTS_FETCHING_DONE:
      draft.homeLocationTypeahead.isFetching = false;
      break;
    case SET_CULTURE_ID:
    case SET_USER_CULTURE_ID:
      draft.cultureId = action.cultureId;
      break;
    case SHOW_MENU:
      draft.menuOpen = action.show;
      break;
    case NAV_SHOW_MENU:
      if (action.show) {
        draft.menuOpen = false;
      }
      break;
    case CLEAR:
      draft = initialState;
      break;
    default:
  }
}, initialState);
