const initialState = {
  sharedGoogleAnalyticsId: '',
  localGoogleAnalyticsId: '',
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
