import { produce } from 'immer';

import {
  UPDATE_LEGACY_DATA_LAYER_FIND_METHOD,
  UPDATE_LEGACY_DATA_LAYER_FIND_TYPEAHEAD_INPUT,
  UPDATE_LEGACY_DATA_LAYER_NEAR_METHOD,
  UPDATE_LEGACY_DATA_LAYER_NEAR_TYPEAHEAD_INPUT,
  UPDATE_LEGACY_DATA_LAYER_SEARCH_RESULTS,
  UPDATE_LEGACY_DATA_LAYER_SORT,
  UPDATE_LEGACY_DATA_LAYER_USED_TYPEAHEADS,
} from './actionTypes';

interface LegacyDataLayerState {
  findQuery: string;
  findMethod: string;
  nearQuery: string;
  nearMethod: string;
  sort: string;
  results?: unknown;
  usedTypeaheads?: unknown;
}

const initialState: LegacyDataLayerState = {
  findQuery: '',
  findMethod: 'typed',
  nearQuery: '',
  nearMethod: 'typed',
  sort: '',
};

export default produce((draft, action) => {
  switch (action.type) {
    case UPDATE_LEGACY_DATA_LAYER_FIND_TYPEAHEAD_INPUT:
      draft.findQuery = action.input;
      break;
    case UPDATE_LEGACY_DATA_LAYER_NEAR_TYPEAHEAD_INPUT:
      draft.nearQuery = action.input;
      break;
    case UPDATE_LEGACY_DATA_LAYER_FIND_METHOD:
      draft.findMethod = action.method;
      break;
    case UPDATE_LEGACY_DATA_LAYER_NEAR_METHOD:
      draft.nearMethod = action.method;
      break;
    case UPDATE_LEGACY_DATA_LAYER_SORT:
      draft.sort = action.sortType;
      break;
    case UPDATE_LEGACY_DATA_LAYER_SEARCH_RESULTS:
      draft.results = action.legacyDataLayerResults;
      break;
    case UPDATE_LEGACY_DATA_LAYER_USED_TYPEAHEADS:
      draft.usedTypeaheads = action.usedTypeaheads;
      break;
    default:
  }
}, initialState);
