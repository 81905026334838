import styled from '@emotion/styled';
import InputGroup from '@iabbb/shared/components/General/InputGroup';
import Typeahead from '@iabbb/shared/components/Typeahead/Provider';

const Styled = styled(InputGroup)`
  border-color: var(--bds-color-gray-70);
`;

export default function TypeaheadComponent(props) {
  return <Styled as={Typeahead} {...props} />;
}
