import Center from '@iabbb/shared/components/General/Center';
import print from '@iabbb/shared/styles/print';
import getIn from '@iabbb/utils/object/getIn';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import validator from '@iabbb/utils/validators/validator';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import BottomLinkList from './BottomLinkList';
import FooterSocialLink from './FooterSocialLink';
import LinkList from './LinkList';
import {
  aboutBbbLinkSelector,
  bottomLinksSelector,
  forAllBusinessesLinkSelector,
  forConsumersLinkSelector,
  links1Selector,
  links2Selector,
  links3Selector,
  socialMediaLinksSelector,
} from './selectors';
import { Bottom, BottomContainer, SocialLinkList, Top, TopContainer } from './styles';

const logoSrc = `${process.env.NEXT_PUBLIC_BASE_URL}/TerminusContent/dist/img/BBB_US_Torch_sm.svg`;
const currentYear = new Date().getFullYear();

FooterComponent.displayName = 'Footer';

export function FooterComponent() {
  const pageHasNewHeaderFooter = useSelector((state: any) => getIn(state, ['newHeaderFooter', 'enabled']));

  const bottomLinks = useSelector(bottomLinksSelector);
  const links1 = useSelector(links1Selector);
  const links2 = useSelector(links2Selector);
  const links3 = useSelector(links3Selector);
  const socialLinks: any[] = useSelector(socialMediaLinksSelector);
  const forConsumersLink: any = useSelector(forConsumersLinkSelector);
  const forAllBusinessesLink: any = useSelector(forAllBusinessesLinkSelector);
  const aboutBbbLink: any = useSelector(aboutBbbLinkSelector);

  if (pageHasNewHeaderFooter) {
    return null;
  }

  return (
    <footer>
      <Top className={clsx('bg-gray-70', 'text-white', print.nothing)}>
        <Center>
          <TopContainer>
            <a href={process.env.NEXT_PUBLIC_BASE_URL}>
              <img src={logoSrc} alt="BBB" height="144" loading="lazy" width="85" />
            </a>
            <div
              className={clsx('switcher', 'gutter-24')}
              style={{ '--switcher-threshold': '50rem' } as React.CSSProperties}
            >
              <div className="stack">
                <h2 className="text-size-10">
                  <a
                    href={forConsumersLink.href}
                    target={forConsumersLink.target || undefined}
                    className="dtm-footer-link"
                  >
                    For Consumers
                  </a>
                </h2>
                <LinkList links={links1} />
              </div>
              <div className="stack">
                <h2 className="text-size-10">
                  <a
                    href={forAllBusinessesLink.href}
                    target={forAllBusinessesLink.target || undefined}
                    className="dtm-footer-link"
                  >
                    For Businesses
                  </a>
                </h2>
                <LinkList links={links2} />
              </div>
              <div className="stack">
                <h2 className="text-size-10">
                  <a
                    href={aboutBbbLink?.href}
                    target={aboutBbbLink?.target || undefined}
                    className="dtm-footer-link"
                  >
                    About BBB®
                  </a>
                </h2>
                <LinkList links={links3} />
                {validator(isNotFalsyOrEmpty)(socialLinks) && (
                  <SocialLinkList className={clsx('list-reset', 'cluster')}>
                    {socialLinks.map((socialLink) => (
                      <li key={socialLink.url}>
                        <FooterSocialLink socialLink={socialLink} />
                      </li>
                    ))}
                  </SocialLinkList>
                )}
              </div>
            </div>
          </TopContainer>
        </Center>
      </Top>
      <Bottom className={clsx('bg-blue-brand', 'text-white')}>
        <Center>
          <BottomContainer>
            <nav className={print.nothing}>
              <BottomLinkList links={bottomLinks} />
            </nav>
            <p
              className={clsx('copyright', 'font-bold')}
              dangerouslySetInnerHTML={{
                __html: `&copy; ${currentYear}, International Association of Better Business Bureaus, Inc., separately incorporated Better Business Bureau organizations in the US and Canada, and BBB Institute for Marketplace Trust, Inc. All rights reserved. *In Canada, trademark(s) of the International Association of Better Business Bureaus, used under License.`,
              }}
            />
          </BottomContainer>
        </Center>
      </Bottom>
    </footer>
  );
}

FooterComponent.displayName = 'Footer';
export default React.memo(FooterComponent);
