/**
 * Combine validators together an execute against the params
 */
function validator<T>(
  ...validators: Array<boolean | ((...params: Array<T>) => boolean) | undefined>
): (...params: Array<T>) => boolean {
  const isValidParam = (param: T) => {
    return validators.every((currentValidator) => {
      if (typeof currentValidator === 'undefined') {
        throw Error('Validator is undefined');
      }

      if (typeof currentValidator !== 'function' && typeof currentValidator !== 'boolean') {
        throw Error(`Validator type '${validator}' is not supported. Only 'function' and 'boolean' types.`);
      }

      /* Execute the validator if it's a function otherwise just read its value  */
      if (typeof currentValidator === 'function' ? !currentValidator(param) : !currentValidator) {
        /* Escape the entire validation process if one outcome is false */
        return false;
      }

      return true;
    });
  };

  /* Can have any number of values we are validating */
  return (...params) => {
    if (params.length === 0) {
      throw Error('There must be values to validate.');
    }
    /* Validate each item using each validator */
    for (let p = 0; p < params.length; p++) {
      if (!isValidParam(params[p])) {
        return false;
      }
    }
    return true;
  };
}
export default validator;
