import styled from '@emotion/styled';

import Combobox from './Combobox';
import Item from './Item';
import Listbox from './Listbox';
import ToggleIcon from './ToggleIcon';

const DropDown = styled.div`
  position: relative;
`;

DropDown.Combobox = Combobox;
DropDown.Item = Item;
DropDown.Listbox = Listbox;
DropDown.ToggleIcon = ToggleIcon;

export default DropDown;
