import clsx from 'clsx';
import type React from 'react';

import './styles.css';

export interface IconPropsWithComponent {
  Component: React.ComponentType;
}

export type IconProps = Omit<IconPropsWithComponent, 'Component' | 'ref'>;

function Icon({
  Component,
  className,
  ...props
}: IconPropsWithComponent & React.ComponentPropsWithRef<SVGElement>) {
  const IconComponent = Component ?? 'svg';

  return (
    <IconComponent
      aria-hidden
      className={clsx('icon', className)}
      focusable="false"
      height="1em"
      width="1em"
      {...props}
    />
  );
}

export default Icon;
