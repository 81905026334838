export const expandUrlMixin = (tag: string, urlAttr: string, propValue: boolean | 'absolute' | 'relative') => {
  let selector = tag;

  switch (propValue) {
    case true:
      selector += `[${urlAttr}]`;
      break;
    case 'absolute':
      selector += `[${urlAttr}^="http"]`;
      break;
    case 'relative':
      selector += `[${urlAttr}]:not([${urlAttr}^="http"])`;
      break;
    default:
      return '';
  }

  return `
    ${selector}:not([${urlAttr}^="#"]) {
      word-wrap: break-word;

      &:after {
        content: " (" attr(${urlAttr}) ")";
      }
    }
  `;
};
