import createSelectors from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createSelectors';
import { TYPEAHEAD_STATE_PATH } from './constants';

const {
  getTypeahead,
  getInput,
  getIsFetching,
  getSelected,
  getResultsWithCurrentLocation,
  getTypeaheadCultureId,
  getPlaceholder,
} = createSelectors({ typeaheadStatePath: TYPEAHEAD_STATE_PATH });

export {
  getTypeahead,
  getPlaceholder,
  getResultsWithCurrentLocation,
  getInput,
  getIsFetching,
  getSelected,
  getTypeaheadCultureId,
};
