import styled from '@emotion/styled';

import media from '@iabbb/shared/styles/media';

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  width: 100%;
`;

const Li = styled('li', { shouldForwardProp: (prop) => prop !== 'isActive' })`
  color: var(--bds-color-gray-70);
  cursor: pointer;
  padding: 8px 10px;
  text-decoration: none;
  white-space: normal;

  & + & {
    border-top: 1px solid var(--bds-color-gray-60);
  }

  &.near-me-hidden {
    display: none;
  }

  ${({ isActive }) =>
    isActive &&
    `
      background-color: var(--bds-color-blue-medium-87);
      color: var(--bds-color-white);
    `}

  .highlight {
    font-weight: var(--bds-font-weight-bold);
  }
`;

const SectionTitle = styled.div`
  background: var(--bds-color-gray-30);
  color: var(--bds-color-black);
  padding: 6px 11px;
`;

const Suggestions = styled.div`
  background-color: var(--bds-color-white);
  background-clip: padding-box;
  box-shadow: var(--bds-shadow-base);
  display: none;
  left: 0;
  max-height: 200px;
  min-width: 285px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  // this is high to overlay any ads
  z-index: 999;

  ${media.md} {
    max-height: none;
  }

  input[aria-expanded='true'] + & {
    display: block;
  }
`;

Suggestions.Ul = Ul;
Suggestions.Li = Li;
Suggestions.SectionTitle = SectionTitle;

export default Suggestions;
