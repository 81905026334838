export const SHOW_MENU = 'containers/Header/NavMenu/SHOW_MENU';
export const TOGGLE_SUBNAV = 'containers/Header/NavMenu/TOGGLE_SUBNAV';

function showNavMenuAction(show: boolean) {
  return {
    type: SHOW_MENU,
    show,
  };
}

export function showNavMenu(show: boolean) {
  return (dispatch) => dispatch(showNavMenuAction(show));
}
