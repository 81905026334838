import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

const HeaderSection = styled.div`
  background-color: var(--bds-color-white);
  position: relative;
  padding-block: 0.75rem 0.625rem;

  ${media.md} {
    padding-block: 0;
  }
`;

export default HeaderSection;
