import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

export default styled.a`
  color: var(--bds-color-blue-brand);
  display: flex;
  width: 2.3125rem;

  svg {
    width: 100%; // 👈 otherwise, SVG is hidden in iOS
  }

  ${media.md} {
    display: none;
  }
`;
