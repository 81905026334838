import useClickAwayListener from '@iabbb/utils/useClickAwayListener';
import clsx from 'clsx';
import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setLocaleSelectorCultureId } from '@iabbb/shared/containers/Header/LocaleSelector/actions';
import { clearHomeLocationTypeahead } from '@iabbb/shared/containers/Header/LocaleSelector/Modal/ChooseLocationState/HomeLocationTypeahead/actions';
import CountryDropdownItem from '@iabbb/shared/containers/Header/LocaleSelector/Modal/ChooseLocationState/CountryDropdownItem';
import { getActiveCultureOrDefault } from '@iabbb/shared/containers/Header/LocaleSelector/selectors';

import { getCultures } from '@iabbb/shared/states/Global/selectors';

import CustomSelect from './styles';

const activeCultureSelector = getActiveCultureOrDefault();
const culturesSelector = getCultures();

function CountrySelectionDropdownComponent() {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const labelId = useId();
  const listboxId = useId();
  const comboboxRef = useRef<HTMLButtonElement>(null);
  const activeOptionRef = useRef<HTMLDivElement>(null);

  // selectors
  const activeCulture: any = useSelector(activeCultureSelector);
  const cultures = useSelector(culturesSelector);

  const [activeOptionIndex, setActiveOptionIndex] = useState(
    cultures.allIds.findIndex((id) => id === activeCulture.id),
  );

  const dispatch = useDispatch();
  // actions
  const clearHome = useCallback(() => dispatch(clearHomeLocationTypeahead() as never), [dispatch]);

  const setLocale = useCallback(
    (_cultureId: string) => dispatch(setLocaleSelectorCultureId(_cultureId)),
    [dispatch],
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickAway = () => setIsExpanded(false);

  const setSelectedCulture = (selectedCulture) => {
    // Only doing anything if active culture is different then just selected culture
    if (activeCulture !== selectedCulture) {
      clearHome();
      const selectedCultureId = selectedCulture.id;

      // We want to update the active resource, but we don't want to update the cookie;
      // We'll only do that when they actually press the button.
      setLocale(selectedCultureId);

      setIsExpanded(false);
    }
  };

  useClickAwayListener(dropdownRef, handleClickAway, isExpanded);

  // Ensure active index is always set whenever selectedId or allCultureIds is updated, or when menu is opened.
  useEffect(() => {
    if (!isExpanded) {
      return;
    }
    setActiveOptionIndex(cultures.allIds.findIndex((x) => x === activeCulture.id));
  }, [activeCulture.id, cultures.allIds, isExpanded]);

  // Whenever menu is open, focus the active element.
  useEffect(() => {
    if (!activeOptionRef.current) {
      return;
    }
    if (!isExpanded) {
      return;
    }
    activeOptionRef.current.focus();
  }, [activeOptionIndex, isExpanded]);

  return (
    <CustomSelect ref={dropdownRef}>
      <div className="visually-hidden" id={labelId}>
        Country
      </div>
      <CustomSelect.Combobox
        aria-controls={listboxId}
        aria-expanded={isExpanded}
        aria-haspopup="listbox"
        aria-labelledby={labelId}
        className={clsx('bg-white', 'font-normal')}
        onClick={() => setIsExpanded(!isExpanded)}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            setIsExpanded(true);
          }
        }}
        ref={comboboxRef}
        role="combobox"
        type="button"
      >
        <CountryDropdownItem culture={activeCulture} />
        <CustomSelect.ToggleIcon />
      </CustomSelect.Combobox>
      {isExpanded && (
        <CustomSelect.Listbox
          aria-labelledby={labelId}
          className={clsx('bg-white', 'shadow-base')}
          id={listboxId}
          role="listbox"
        >
          {cultures.allIds.map((cultureId, index) => {
            const culture = cultures.byId[cultureId];
            return (
              <CustomSelect.Item
                key={culture.cultureInfo}
                className="dtm-locale-selector-country"
                aria-selected={activeCulture.id === cultureId}
                role="option"
                onClick={() => {
                  setSelectedCulture(culture);
                  setIsExpanded(false);
                  comboboxRef.current?.focus();
                }}
                onKeyDown={(e) => {
                  if (e.key === 'ArrowDown') {
                    e.preventDefault();

                    if (index !== cultures.allIds.length - 1) {
                      setActiveOptionIndex(index + 1);
                    }
                  }

                  if (e.key === 'ArrowUp') {
                    e.preventDefault();

                    if (index !== 0) {
                      setActiveOptionIndex(index - 1);
                    }
                  }

                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();

                    setIsExpanded(false);

                    if (activeCulture.id !== cultureId) {
                      setSelectedCulture(culture);
                    }

                    comboboxRef.current?.focus();
                  }

                  if (e.key === 'Escape') {
                    e.preventDefault();
                    e.stopPropagation(); // 👈 prevent bubbling up to parent dialog; only close listbox

                    setIsExpanded(false);
                    comboboxRef.current?.focus();
                  }

                  if (e.key === 'Tab') {
                    setIsExpanded(false);

                    if (activeCulture.id !== cultureId) {
                      setSelectedCulture(culture);
                    }
                  }
                }}
                onMouseEnter={() => {
                  setActiveOptionIndex(index);
                }}
                tabIndex={activeOptionIndex === index ? -1 : undefined}
                ref={activeOptionIndex === index ? activeOptionRef : undefined}
              >
                <CountryDropdownItem culture={culture} />
              </CustomSelect.Item>
            );
          })}
        </CustomSelect.Listbox>
      )}
    </CustomSelect>
  );
}

CountrySelectionDropdownComponent.displayName = 'CountrySelectionDropdown';

export default CountrySelectionDropdownComponent;
