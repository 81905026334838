import IconCrosshairs from '@iabbb/icons/Crosshairs';
import IconEdit from '@iabbb/icons/Edit';
import IconFlag from '@iabbb/icons/Flag';
import IconPage from '@iabbb/icons/Page';
import urljoin from 'url-join';

import { NavLink } from './types';

const GLOBAL_NAV_LINKS: Array<NavLink> = [
  {
    id: 'bckozkvrx',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/get-a-quote'),
    Icon: IconPage,
    text: 'Get a Quote',
  },
  {
    id: 'ailelxrhx',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/leave-a-review'),
    Icon: IconEdit,
    text: 'Start a Review',
  },
  {
    id: '2hkdd9dh',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/file-a-complaint'),
    Icon: IconFlag,
    text: 'File a Complaint',
  },
  {
    id: 'jlozpjirh',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/scamtracker'),
    Icon: IconCrosshairs,
    text: 'BBB Scam Tracker',
    hideBottomBorder: true,
  },
  {
    id: 'jcm123bbb',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/all/business-resources'),
    text: 'Business Resources',
  },
  {
    id: '7b726d3ac',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, 'get-accredited'),
    text: 'Get Accredited',
  },
  {
    id: 'lji4y0jpis',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/all/consumer-resources'),
    text: 'For Consumers',
  },

  {
    id: 'ji4y0jpisl',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/all/employment'),
    text: 'Careers',
  },

  {
    id: 'c56tc26sj',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, 'bbb-directory'),
    text: 'BBB Directory',
  },
];

const DEFAULT_BBB_NAV_LINKS: Array<NavLink> = [
  {
    id: 'qwtff3crq',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, 'news'),
    text: 'News',
  },
  {
    id: 'zenwtcg7u',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, 'get-accredited'),
    text: 'Get Accredited',
  },
  {
    id: 'jsewoduhp',
    href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, 'about-bbb'),
    text: 'About BBB',
    links: [
      {
        id: 'gzo29yrex',
        href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, 'mission-and-vision'),
        text: 'Mission & Vision',
      },
      {
        id: 'mwomtso',
        href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/all/faq'),
        text: 'FAQs',
      },
    ],
  },
];

export { DEFAULT_BBB_NAV_LINKS, GLOBAL_NAV_LINKS };
