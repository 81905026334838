const initialState = {
  accreditedStatus: '',
  businessId: '',
  hasLogo: '',
  name: '',
  phone: '',
  rating: '',
  zipCode: '',
};

export default function businessInfoReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
