const initialState = {
  bbbId: '',
  bbbName: '',
};

export default function bbbInfoReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
