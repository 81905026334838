const cache = {};

export const bbbCountryCodeToKey = (code: number): string | undefined =>
  ({
    0: 'usa',
    1: 'can',
  })[code];

export const businessCountryCodeToKey = (code: number): string | undefined =>
  ({
    2: 'usa',
    4: 'can',
  })[code];

const countryCodes = {
  usa: '1',
  can: '1',
};

const countryCodeFormatTokenRegex = /(\[)(.*)(\])/;

const replaceCountryCode = (formattedPhoneNumber: string, countryCode: string): string => {
  const matches = formattedPhoneNumber.match(countryCodeFormatTokenRegex);
  if (!matches) {
    return formattedPhoneNumber;
  }
  const countryCodeFormatString = matches[2];
  const formattedCountryCode = countryCodeFormatString.replace('+', `+${countryCode}`);
  return formattedPhoneNumber.replace(countryCodeFormatTokenRegex, formattedCountryCode);
};

const normalize = (phoneNumber: string): string => phoneNumber.replace(/\D/g, '');

const formatPhoneNumber = (
  phoneNumber: string,
  threeLetterCountryCode: string | null | undefined,
  format = '[+-]XXX-XXX-XXXX',
) => {
  const cacheKey = `${phoneNumber}-${threeLetterCountryCode}-${format}`;

  if (cache[cacheKey]) {
    return cache[cacheKey] as string;
  }

  const normalizedPhoneNumber = normalize(phoneNumber);
  const reversedPhoneNumberDigits = normalizedPhoneNumber.split('').reverse();
  let reversedFormattedPhoneNumber = format.split('').reverse().join('');
  let extractedCountryCode;
  for (let i = 0, l = reversedPhoneNumberDigits.length; i < l; i++) {
    if (!reversedFormattedPhoneNumber.includes('X')) {
      extractedCountryCode = reversedPhoneNumberDigits.slice(i).reverse().join('');
      break;
    }
    reversedFormattedPhoneNumber = reversedFormattedPhoneNumber.replace('X', reversedPhoneNumberDigits[i]);
  }

  let formattedPhoneNumber = reversedFormattedPhoneNumber.split('').reverse().join('');

  if (extractedCountryCode) {
    formattedPhoneNumber = replaceCountryCode(formattedPhoneNumber, extractedCountryCode);
  } else if (threeLetterCountryCode && typeof threeLetterCountryCode === 'string') {
    const codeKey = threeLetterCountryCode.toLowerCase();
    formattedPhoneNumber = replaceCountryCode(formattedPhoneNumber, countryCodes[codeKey]);
  } else {
    formattedPhoneNumber = formattedPhoneNumber.replace(countryCodeFormatTokenRegex, '');
  }

  cache[cacheKey] = formattedPhoneNumber;
  return formattedPhoneNumber;
};

export default formatPhoneNumber;
