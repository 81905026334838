import styled from '@emotion/styled';
import IconClose from '@iabbb/icons/Close';
import getIn from '@iabbb/utils/object/getIn';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DisplayLocationStateName, ChooseLocationStateName } from './constants';
import ChooseLocationState from './ChooseLocationState';
import DisplayLocationState from './DisplayLocationState';
import Modal from './Modal';

const LocationBackground = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;

const LocaleSelectorModal = ({ toggleModal }) => {
  const hasUserLocation = useSelector(
    (state: Record<string, object>) => !!getIn(state, ['user', 'location', 'id']),
  );

  const [modalState, setModalState] = useState(() =>
    hasUserLocation ? DisplayLocationStateName : ChooseLocationStateName,
  );

  const modalWindow = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        toggleModal();
      }
      if (e.key === 'Tab') {
        const focusable = modalWindow.current?.querySelectorAll('input,button,select,textarea,a');
        if (focusable?.length) {
          const first = focusable[0] as HTMLElement | null;
          const last = focusable[focusable.length - 1] as HTMLElement | null;
          const shift = e.shiftKey;
          if (shift) {
            if (e.target === first) {
              // shift-tab pressed on first input in dialog
              last?.focus();
              e.preventDefault();
            }
          } else if (e.target === last) {
            // tab pressed on last input in dialog
            first?.focus();
            e.preventDefault();
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleModal]);

  return (
    <>
      <LocationBackground onClick={toggleModal} />
      <Modal className="bg-white" ref={modalWindow}>
        <Modal.Header>
          <Modal.CloseButton onClick={toggleModal} autoFocus variant="unstyled">
            <IconClose />
            <span className="visually-hidden">close locale selector</span>
          </Modal.CloseButton>
        </Modal.Header>
        {modalState === DisplayLocationStateName ? (
          <DisplayLocationState setModalState={setModalState} />
        ) : (
          <ChooseLocationState setModalState={setModalState} />
        )}
      </Modal>
    </>
  );
};

LocaleSelectorModal.displayName = 'LocaleSelector.Modal';

export default React.memo(LocaleSelectorModal);
