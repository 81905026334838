import IconAngleDownSolid from '@iabbb/icons/AngleDownSolid';
import styled from '@emotion/styled';

// DropDown.ToggleIcon
export default styled(({ icon, ...props }) => (
  <div {...props}>
    <IconAngleDownSolid />
  </div>
))`
  align-items: center;
  background-color: var(--bds-color-blue-medium-87);
  color: var(--bds-color-white);
  display: flex;
  height: 35px;
  justify-content: center;
  margin: 0 0 0 12px;
  width: 35px;
`;
