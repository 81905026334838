import styled from '@emotion/styled';
import IconSearch from '@iabbb/icons/Search';
import media from '@iabbb/shared/styles/media';
import clsx from 'clsx';
import React from 'react';

import MobileSearchDialog from './Dialog';

const ButtonOpenDialog = styled.button`
  --icon-space: 1rem;

  border: 1px solid var(--bds-color-gray-70);
  min-height: 2.1875rem;
  margin-block-start: 0.75rem;
  padding-inline: 0.75rem;
  width: 100%;

  ${media.md} {
    display: none !important;
  }
`;

export type MobileSearchProps = {
  onClickToggleModal: () => void;
  showModal?: boolean;
  value?: string;
};

function MobileSearch({ onClickToggleModal, showModal = false, value = '' }: MobileSearchProps) {
  return (
    <>
      <ButtonOpenDialog
        className={clsx('with-icon', 'bg-white', 'text-gray-70')}
        onClick={onClickToggleModal}
        type="button"
      >
        <IconSearch />
        {value ? (
          <>
            <span className="visually-hidden">continue search for </span>
            {value}
          </>
        ) : (
          'Find local businesses'
        )}
      </ButtonOpenDialog>
      <MobileSearchDialog handleCloseModal={onClickToggleModal} open={showModal} />
    </>
  );
}

export default React.memo(MobileSearch);
