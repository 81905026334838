import NearTypeahead from '@iabbb/shared/containers/Header/NearTypeahead';

import { MobileTypeahead, MobileTypeaheadWrapper } from '../styles';

function MobileNearTypeaheadComponent() {
  return (
    <MobileTypeaheadWrapper>
      <NearTypeahead showIconLabel styled={MobileTypeahead} />
    </MobileTypeaheadWrapper>
  );
}

export default MobileNearTypeaheadComponent;
