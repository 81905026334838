import { API_BASE_URL } from '@iabbb/utils/api/constants';
import request from '@iabbb/utils/api/request';
import urljoin from 'url-join';

const buildApiUrl = (...segments: string[]) =>
  urljoin(process.env.NEXT_PUBLIC_BASE_WEB_API_URL, API_BASE_URL, 'adobetarget', ...segments);

/**
 * Sends click notifications to track Adobe Target conversions
 * @param {Object} id - User's tntId to send to Target
 * @param {string} eventTokens - List of event tokens to send to Target
 */
const sendClickNotification = (featureFlags: { id: string; eventTokens: string[] }[], tntId: string) => {
  const url = buildApiUrl(`/sendclicknotification?tntId=${tntId}`);

  return request(url, {
    method: 'POST',
    body: JSON.stringify(featureFlags),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
};

export { sendClickNotification };
