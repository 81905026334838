import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

import Button from './Button';

const SearchBar = styled.div`
  display: none;

  ${media.md} {
    display: block;
    padding-block: 0.75rem 0.6875rem;
  }
`;

SearchBar.Button = Button;

export default SearchBar;
