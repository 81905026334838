import Button from '@iabbb/bds-react/Button';
import styled from '@emotion/styled';

export default styled(Button)`
  && {
    color: var(--bds-color-gray-70);
    font-size: 1.5rem;
    line-height: 1;
    padding: 0.25rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;

    &:hover {
      background: transparent;
      color: var(--bds-color-black);
    }
  }
`;
