import { createSelector } from 'reselect';

import { createNavigationLinks } from '@iabbb/utils/navUtils';
import getIn from '@iabbb/utils/object/getIn';

const bbbInfoStatePath = ['user', 'location', 'bbbInfo'];
const selectSocialMedia = (state: Record<string, object>) => getIn(state, [...bbbInfoStatePath, 'socialMedia']);
const selectFooterBottomLinks = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'footerBottomLinks']);
const selectFooterLinks1 = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'footerLinks1']);
const selectFooterLinks2 = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'footerLinks2']);
const selectFooterLinks3 = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'footerLinks3']);
const selectForConsumersLink = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'forConsumersFooterLink']);
const selectForAllBusinessesLink = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'forAllBusinessesFooterLink']);
const selectBbbLinkSelector = (state: Record<string, object>) =>
  getIn(state, [...bbbInfoStatePath, 'navigation', 'forAboutBbbFooterLink']);
const createLinks = (links) => {
  return createNavigationLinks(links);
};

const bottomLinksSelector = createSelector([selectFooterBottomLinks], createLinks);

const links1Selector = createSelector([selectFooterLinks1], createLinks);

const links2Selector = createSelector([selectFooterLinks2], createLinks);

const links3Selector = createSelector([selectFooterLinks3], createLinks);

const socialMediaLinksSelector = selectSocialMedia;
const forConsumersLinkSelector = selectForConsumersLink;
const forAllBusinessesLinkSelector = selectForAllBusinessesLink;
const aboutBbbLinkSelector = selectBbbLinkSelector;

export {
  bottomLinksSelector,
  links1Selector,
  links2Selector,
  links3Selector,
  socialMediaLinksSelector,
  forConsumersLinkSelector,
  forAllBusinessesLinkSelector,
  aboutBbbLinkSelector,
};
