import { forwardRef } from 'react';

import NavMenuButton from './styles';

const NavMenuButtonComponent = forwardRef<HTMLButtonElement, React.ComponentPropsWithoutRef<'button'>>(
  (props, ref) => {
    return (
      <NavMenuButton ref={ref} variant="unstyled" {...props}>
        <span />
        <span />
        <span />
        <span />
        <span className="visually-hidden">Menu</span>
      </NavMenuButton>
    );
  },
);

export default NavMenuButtonComponent;
