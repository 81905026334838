import styled from '@emotion/styled';

import SecondaryTitle from './SecondaryTitle';
import Tagline from './Tagline';
import Title from './Title';
import AbBadgeImage from './AbBadgeImage';
import AbBadgeContainer from './AbBadgeContainer';
import SuggestionContainer from './SuggestionContainer';
import TitleContainer from './TitleContainer';
import HqTitle from './HqTitle';

const StyledSuggestion = styled.div`
  display: flex;
`;

function Suggestion(props) {
  return <StyledSuggestion role="button" {...props} />;
}

Suggestion.SecondaryTitle = SecondaryTitle;
Suggestion.Tagline = Tagline;
Suggestion.Title = Title;
Suggestion.AbBadgeImage = AbBadgeImage;
Suggestion.AbBadgeContainer = AbBadgeContainer;
Suggestion.SuggestionContainer = SuggestionContainer;
Suggestion.TitleContainer = TitleContainer;
Suggestion.HqTitle = HqTitle;

export default Suggestion;
