// UTILS
import getIn from '@iabbb/utils/object/getIn';
import CookieNames from '@iabbb/utils/Storage/CookieNames';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import validator from '@iabbb/utils/validators/validator';
import { fetchSuggestBbbLocation } from '@iabbb/utils/api/SuggestApi';

// ACTIONS
import createActions from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createActions';
import { setHomeLocationTypeahead } from '@iabbb/shared/containers/Header/LocaleSelector/Modal/ChooseLocationState/HomeLocationTypeahead/actions';
import { setLocateBbbTypeahead } from '@iabbb/shared/containers/LocateBbbOnMap/LocateBbbTypeahead/actions';
import { setCookie, setUserLocationAndSaveCookies } from '@iabbb/shared/states/User/actions';
import { TYPEAHEAD_STATE_PATH, MAX_TYPEAHEAD_RESULTS } from './constants';
import * as actionTypes from './actionTypes';

const {
  updateTypeaheadResults,
  setTypeaheadInput,
  setTypeaheadSelected,
  clearTypeahead,
  setLocationTypeaheadCultureId,
  setLocationTypeahead,
  fetchAndUpdateTypeaheadResults,
} = createActions({ actionTypes, suggestApiFetch: fetchSuggestBbbLocation });

export const setFindYourBbbTypeaheadInput = setTypeaheadInput;
export const clearFindYourBbbTypeahead = clearTypeahead;
export const updateFindYourBbbTypeaheadResults = updateTypeaheadResults;
export const setFindYourBbbTypeaheadCultureId = setLocationTypeaheadCultureId;
export const setFindYourBbbTypeahead = setLocationTypeahead;
export const setFindYourBbbTypeaheadSelected = setTypeaheadSelected;

// COMPONENT SPECIFIC

export function fetchAndUpdateFindYourBbbTypeaheadResults(searchTerm: string) {
  return (dispatch, getState) => {
    const state = getState();
    const cultureId = getIn(state, [...TYPEAHEAD_STATE_PATH, 'cultureId']);
    const bbbId = getIn(state, ['user', 'location', 'bbbId']);
    dispatch(fetchAndUpdateTypeaheadResults({ searchTerm, maxMatches: MAX_TYPEAHEAD_RESULTS, cultureId, bbbId }));
  };
}

function saveFindYourBbbLocationCookieActionCreator(locationId: string, expiration: number) {
  return (dispatch) => {
    dispatch(
      setCookie({
        name: CookieNames.FINDYOURBBB_LOCATION,
        value: locationId,
        expiration,
      }),
    );
  };
}

export function setFindYourBbbAndSaveCookie(location) {
  return (dispatch) => {
    // Set input and selected
    dispatch(setFindYourBbbTypeahead(location));

    // Always save the select location as a cookie
    const cookieExpiration = process.env.NEXT_PUBLIC_COOKIE_EXPIRATION;
    dispatch(saveFindYourBbbLocationCookieActionCreator(location.id, cookieExpiration));
  };
}

/**
 *
 *
 * @export setFindYourBbbLocateBbbOnMapAndSaveCookie
 * @param {object} location
 */
export function setFindYourBbbLocateBbbOnMapAndSaveCookie(location) {
  return (dispatch) => {
    dispatch(setFindYourBbbAndSaveCookie(location));

    // Always try and update locateBbbOnMap, won't update if the reducer doesn't exist
    dispatch(setLocateBbbTypeahead(location));
  };
}

/**
 * Sets user's location state, saves location to cookie and updates home location typeahead
 * @export
 * @param {object} location - bbb location
 * @returns dispatch
 */
function setUserLocationSaveCookieAndHomeLocationTypeahead(location) {
  return (dispatch) => {
    dispatch(setUserLocationAndSaveCookies(location));
    dispatch(setHomeLocationTypeahead(location));
  };
}

/**
 * Sets the find your bbb location typeahead, saves the findyourbbb_location cookie, sets the user location & home location typeahead if empty and set's locateBbbOnMap widget
 *
 * @export setFindYourBbbLocateBbbOnMapAndUser
 * @param {object} location - bbb location
 * @returns dispatch
 */
export function setFindYourBbbLocateBbbOnMapAndUser(location) {
  return (dispatch) => {
    dispatch(setFindYourBbbLocateBbbOnMapAndSaveCookie(location));
    dispatch(setUserLocationSaveCookieAndHomeLocationTypeahead(location));
  };
}

export function clearFindYourBbbTypeaheadResultsIfNotEmpty() {
  return (dispatch, getState) => {
    const results = getIn(getState(), [...TYPEAHEAD_STATE_PATH, 'results']);
    if (validator(isNotFalsyOrEmpty)(results)) {
      dispatch(updateFindYourBbbTypeaheadResults([]));
    }
  };
}
