import styled from '@emotion/styled';

const FieldsetReset = styled.fieldset`
  border: none;
  padding: 0;

  legend + div {
    --stack-space: 0;
  }
`;

function LegendReset({ children, ...props }: React.ComponentPropsWithoutRef<'p'>) {
  return (
    <>
      {/* layout issues with fieldset legends: https://adrianroselli.com/2022/07/use-legend-and-fieldset.html#Hide */}
      <legend className="visually-hidden">{children}</legend>
      <div aria-hidden {...props}>
        {children}
      </div>
    </>
  );
}

export { FieldsetReset, LegendReset };
