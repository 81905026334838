import { useNavigator } from '@iabbb/context/Navigator';
import trimTrailingSlash from '@iabbb/utils/url/trimTrailingSlash';
import Button from '@iabbb/bds-react/Button';
import IconAngleDown from '@iabbb/icons/AngleDownRegular';
import clsx from 'clsx';
import { useId, useState } from 'react';

import { NavLink } from '../types';
import StyledListItem from './styles';
import SubNavigation from './SubNavigation';

export interface NavItemProps {
  link: NavLink;
}

function NavItem({ link }: NavItemProps) {
  const [hasOpenSubnav, setHasOpenSubnav] = useState(false);
  const linkId = useId();
  const navigator = useNavigator();
  const subnavId = useId();

  const handleToggleClick = async () => {
    setHasOpenSubnav(!hasOpenSubnav);
  };

  const url = new URL(trimTrailingSlash(link.href), process.env.NEXT_PUBLIC_BASE_URL);

  return (
    <StyledListItem
      className={clsx({
        'has-subnav': link.links?.length,
        'bg-blue-brand': !link.Icon,
        'hide-bottom': link.hideBottomBorder,
      })}
    >
      <a
        aria-current={navigator.url.href.replace(/\/$/, '') === url.href.replace(/\/$/, '') ? 'page' : undefined}
        className="dtm-header-nav-menu-link"
        href={url.href}
        id={link.links?.length ? linkId : undefined}
      >
        {link.Icon && <link.Icon className="text-size-30" />}
        {link.text}
      </a>
      {link.links?.length && (
        <>
          <Button
            aria-controls={subnavId}
            aria-expanded={hasOpenSubnav}
            aria-labelledby={linkId}
            className={clsx('text-size-30', 'text-white')}
            onClick={handleToggleClick}
            variant="unstyled"
          >
            <IconAngleDown />
          </Button>
          <SubNavigation id={subnavId} isOpen={hasOpenSubnav} links={link.links} />
        </>
      )}
    </StyledListItem>
  );
}

export default NavItem;
