import urljoin from 'url-join';

import request from '@iabbb/utils/api/request';
import { API_BASE_URL } from '@iabbb/utils/api/constants';
import queryString from '@iabbb/utils/url/qs';
import {
  FetchSuggestLocationParams,
  FetchSuggestNameParams,
  FetchSuggestBbbParams,
  FetchSuggestAccreditationCategoryParams,
} from './types';

const buildApiUrl = (...segments) =>
  urljoin(process.env.NEXT_PUBLIC_BASE_WEB_API_URL, API_BASE_URL, 'suggest', ...segments);

/**
 * Fetches location suggestions for typeahead
 * @param {string} baseUrl - base url of the endpoint
 * @param {Object} country - country to search on
 * @param {string} input - search text
 * @param {number} maxMatches - number of matches to return
 */
const fetchSuggestLocation = ({ country, input, bbbId, maxMatches }: FetchSuggestLocationParams) => {
  const url = buildApiUrl(`/location?${queryString.stringify({ country, input, bbbId, maxMatches })}`);
  return request(url);
};

const fetchSuggestBbbLocation = ({ country, input, bbbId, maxMatches }: FetchSuggestLocationParams) => {
  const url = buildApiUrl(`/bbblocation?${queryString.stringify({ country, input, bbbId, maxMatches })}`);
  return request(url);
};

const fetchSuggestBbbLocationByPostalCode = ({
  country,
  input,
  bbbId,
  maxMatches,
}: FetchSuggestLocationParams) => {
  const url = buildApiUrl(
    `/bbblocationbypostalcode?${queryString.stringify({ country, input, bbbId, maxMatches })}`,
  );
  return request(url);
};

const fetchSuggestLeaveAReviewName = ({
  country,
  input,
  latLng,
  maxMatches,
  entityTypes,
}: FetchSuggestNameParams) => {
  const url = buildApiUrl(
    `/leaveareviewname?${queryString.stringify({ country, input, latLng, maxMatches, entityTypes })}`,
  );
  return request(url);
};

const fetchSuggestFileAComplaintName = ({
  country,
  input,
  latLng,
  maxMatches,
  entityTypes,
}: FetchSuggestNameParams) => {
  const url = buildApiUrl(
    `/fileacomplaintname?${queryString.stringify({ country, input, latLng, maxMatches, entityTypes })}`,
  );
  return request(url);
};

/**
 * Fetches business names and tob suggestions for typeahead
 * @param {string} baseUrl - base url of the endpoint
 * @param {string} country - three letter country code
 * @param {string} input - search text
 * @param {string} location - location to search on
 */
const fetchSuggestApplicationAccreditationName = ({
  country,
  input,
  latLng,
  maxMatches,
  entityTypes,
}: FetchSuggestNameParams) => {
  const url = buildApiUrl(
    `/accreditationapplicationname?${queryString.stringify({ country, input, latLng, maxMatches, entityTypes })}`,
  );
  return request(url);
};

/**
 * Fetches business names and tob suggestions for typeahead
 * @param {string} baseUrl - base url of the endpoint
 * @param {string} country - three letter country code
 * @param {string} input - search text
 * @param {string} location - location to search on
 */
const fetchSuggestFind = ({ country, input, latLng, maxMatches, entityTypes }: FetchSuggestNameParams) => {
  const url = buildApiUrl(`/find?${queryString.stringify({ country, input, latLng, maxMatches, entityTypes })}`);
  return request(url);
};

const fetchSuggestBbb = ({ country, input, maxMatches }: FetchSuggestBbbParams) => {
  const url = buildApiUrl(`/bbb?${queryString.stringify({ country, input, maxMatches })}`);
  return request(url);
};

const fetchSuggestAccreditationCategory = ({
  country,
  input,
  latLng,
  maxMatches,
  entityTypes,
  primaryOnly,
}: FetchSuggestAccreditationCategoryParams) => {
  const url = buildApiUrl(
    `/accreditationcategory?${queryString.stringify({
      country,
      input,
      latLng,
      maxMatches,
      entityTypes,
      primaryOnly,
    })}`,
  );
  return request(url);
};

export {
  fetchSuggestFind,
  fetchSuggestLocation,
  fetchSuggestBbb,
  fetchSuggestBbbLocation,
  fetchSuggestFileAComplaintName,
  fetchSuggestLeaveAReviewName,
  fetchSuggestAccreditationCategory,
  fetchSuggestBbbLocationByPostalCode,
  fetchSuggestApplicationAccreditationName,
};
