import clsx from 'clsx';

import './styles.css';

export default function Center({ children, className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={clsx('page-center', className)} {...props}>
      {children}
    </div>
  );
}
