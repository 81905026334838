import IconMapMarker from '@iabbb/icons/MapMarker';
import clsx from 'clsx';
import { forwardRef } from 'react';

import Button from './styles';

const ButtonComponent = forwardRef<HTMLButtonElement, React.ComponentPropsWithRef<'button'>>(
  ({ children, ...props }, ref) => {
    return (
      <Button className={clsx('active-underline', 'with-icon')} variant="unstyled" {...props} ref={ref}>
        <IconMapMarker className="text-blue-brand" />
        {children}
      </Button>
    );
  },
);

export default ButtonComponent;
