import styled from '@emotion/styled';

import { useTypeaheadContext } from '../';

// 👇 allow for `as` prop to be used for styling
const Label = styled.label``;

LabelComponent.displayName = 'TypeaheadLabel';

interface LabelComponentProps extends React.ComponentPropsWithoutRef<'label'> {
  as?: React.ElementType<any>;
  variant?: string;
}

export default function LabelComponent(props: LabelComponentProps) {
  const { getLabelProps } = useTypeaheadContext();

  // the following is populated with downshift props
  return <Label {...getLabelProps()} {...props} />;
}
