import autosuggestHighlightMatch from 'autosuggest-highlight/match';
import autosuggestHighlightParse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';

interface TypeaheadHighlighterProps {
  text: string;
  query: string;
}

const TypeaheadHighlighter = ({ text, query }: TypeaheadHighlighterProps) => {
  const matches = autosuggestHighlightMatch(text ?? '', query);
  const parts = autosuggestHighlightParse(text, matches);

  return (
    <span>
      {parts.map((part) => {
        const className = part.highlight ? 'highlight' : null;
        return (
          <span key={`query_${part.text}`} className={className ?? ''}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
};

TypeaheadHighlighter.propTypes = {
  text: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

TypeaheadHighlighter.displayName = 'TypeaheadHighlighter';
export default TypeaheadHighlighter;
