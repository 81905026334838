const isFalsy = (thing: unknown) => {
  return !thing;
};

const isEmptyObj = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const isEmptyArray = (array: any[]) => {
  return array.length === 0;
};

// Assume is not null/undefined THING
const isEmpty = (thing: any) => {
  return (Array.isArray(thing) && isEmptyArray(thing)) || (typeof thing === 'object' && isEmptyObj(thing));
};

const isNotFalsyOrEmpty = (thing: any) => {
  return !(isFalsy(thing) || isEmpty(thing));
};

export default isNotFalsyOrEmpty;
