import { CLOUDINARY_MEDIA_SERVER } from '@iabbb/utils/cloudinary/constants';

export const abSealUrls = {
  horizontal: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/ab-seal-horizontal.svg`,
  vertical: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/ab-seal-vertical.svg`,
};

export const abSealCanUrls = {
  horizontal: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/business-profile/accreditation/AB-Seal-Can-H.svg`,
  vertical: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/business-profile/accreditation/AB-badge-can-v.svg`,
};
