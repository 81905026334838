import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

const HeaderActions = styled.div`
  display: flex;

  ${media.smDown} {
    justify-content: space-between;
    width: 100%;

    & > .actions-container {
      flex-grow: 1;
      flex-basis: 0;
    }

    & > .nav-container {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      flex-basis: 0;
      margin-block: -0.75rem; // 👈 offset to equal height of section
    }
  }

  ${media.md} {
    gap: 2.9375rem;
  }
`;

export default HeaderActions;
