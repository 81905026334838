import createActionTypes from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createActionTypes';

const basePath = 'containers/Header/LocaleSelector/Modal/ChooseLocationState/HomeLocationTypeahead';
const actionTypes = createActionTypes(basePath);

export const {
  SET_INPUT,
  TYPEAHEAD_RESULTS_FETCHING,
  TYPEAHEAD_RESULTS_FETCHING_DONE,
  UPDATE_TYPEAHEAD_RESULTS,
  SELECT_ITEM,
  TYPEAHEAD_SUGGESTION_SELECTED,
  SET_TYPEAHEAD_CULTURE_ID,
} = actionTypes;
