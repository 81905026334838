import { useState, useEffect } from 'react';
import { NavigatorProvider } from '@iabbb/context/Navigator';
import { ReduxProvider } from '@iabbb/context/Redux';

import HeaderFooterWrapper from './HeaderFooterWrapper';
import createRootReducer from './createRootReducer';
import getIn from '@iabbb/utils/object/getIn';
import { fetchHeaderFooterInitialState } from '@iabbb/utils/api/InitializeApi';
import { CacheProvider } from '@emotion/react';

import BaselineStyles from './BaselineStyles';

import '@iabbb/bds/custom-props.css';
import '@iabbb/bds/cube.css';
import createEmotionCache from '@iabbb/shared/styles/createEmotionCache';

const cache = createEmotionCache();

function App({ href }: { href: string }) {
  const [initialState, setInitialState] = useState<Record<string, object>>({});
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({ baseUrl: 'https://www.bbb.org', showSearchBar: true });

  useEffect(() => {
    void (async () => {
      try {
        const baseUrl = getIn(window, ['iabbb', 'baseUrl'], 'https://www.bbb.org');
        const showSearchBar = getIn(window, ['iabbb', 'showSearchBar']);
        setConfig({ baseUrl, showSearchBar });
        setInitialState(await fetchHeaderFooterInitialState(baseUrl));
      } catch {
        // do nothing, if not here then any fetch error will cause finally to throw the exception
      }
      // No matter what happens, make sure to set loading to false
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return null;
  }

  return (
    <CacheProvider value={cache}>
      <BaselineStyles />
      <NavigatorProvider href={href}>
        <ReduxProvider initialState={initialState} createRootReducer={createRootReducer}>
          <HeaderFooterWrapper {...config} />
        </ReduxProvider>
      </NavigatorProvider>
    </CacheProvider>
  );
}

export default App;
