import { createSelector } from 'reselect';

import { getCultures } from '@iabbb/shared/states/Global/selectors';
import { getUserLocation } from '@iabbb/shared/states/User/selectors';
import getGeoUrlOrFallback from '@iabbb/utils/bbbInfo/getGeoUrlOrFallback';
import { DEFAULT_CULTURE_ID } from '@iabbb/utils/culture/constants';
import getIn from '@iabbb/utils/object/getIn';

import CultureData from '@iabbb/utils/culture/allCultureData';

const selectCultures = () => CultureData;
const selectCultureId = (state: Record<string, object>) => getIn(state, ['header', 'localeSelector', 'cultureId']);

// The geourl geo url
const getLocaleUrl = () =>
  createSelector([getUserLocation()], (savedLoc) =>
    getGeoUrlOrFallback(savedLoc, process.env.NEXT_PUBLIC_BASE_URL),
  );

const getActiveCultureOrDefault = () =>
  createSelector([selectCultures, selectCultureId], (culturesState, cultureId) => {
    const defaultCulture = getIn(culturesState, ['byId', DEFAULT_CULTURE_ID]);

    if (!cultureId) {
      return defaultCulture;
    }
    const userCulture = getIn(culturesState, ['byId', cultureId]);
    return userCulture || defaultCulture;
  });

const getMyLocationLabel = () =>
  createSelector(
    (state) => getIn(state, ['user', 'location']),
    (savedLoc) => {
      // Why is this checking for a valid city?
      if (savedLoc?.id && savedLoc.city) {
        const initialArr = [
          `${savedLoc.city || savedLoc.postalCode}`,
          `${savedLoc.state || savedLoc.stateFull}`,
          `${savedLoc.threeLetterIsoCountryCode}`,
        ];
        // filter array to ignore empty, and null
        const arr = initialArr.filter(
          (x) => x !== 'undefined' && x !== '' && x !== null && typeof x !== 'undefined',
        );
        return arr.join(', ');
      }
      return undefined;
    },
  );

export { getCultures, getMyLocationLabel, getLocaleUrl, getUserLocation, getActiveCultureOrDefault };
