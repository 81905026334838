import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { showNavMenu } from '@iabbb/shared/containers/Header/NavMenu/actions';
import hideAt from '@iabbb/shared/styles/hideAt';

import getIn from '@iabbb/utils/object/getIn';

import MyLocationButton from './Button';
import MyLocationModal from './Modal';

export function LocaleSelector() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const locBtn = useRef<HTMLButtonElement | null>(null);
  const buttonText = useSelector(
    createSelector([(state) => getIn(state, ['user', 'location'])], (loc) => {
      return loc?.id && loc.city ? (
        'My BBB'
      ) : (
        <span>
          {/* 👆 wrapper span prevents collapsing of whitespace */}
          {'Set '}
          <span className={hideAt.smDown}>{'my '}</span>location
        </span>
      );
    }),
  );

  const toggleModal = useCallback(() => {
    if (showModal) {
      locBtn.current?.focus();
    }
    return setShowModal(!showModal);
  }, [setShowModal, showModal, locBtn]);

  const handleButtonClick = useCallback(() => {
    dispatch(showNavMenu(false) as never);
    toggleModal();
  }, [dispatch, toggleModal]);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <MyLocationButton onClick={handleButtonClick} ref={locBtn}>
        {buttonText}
      </MyLocationButton>
      {showModal && <MyLocationModal toggleModal={toggleModal} />}
    </div>
  );
}

LocaleSelector.displayName = 'Header.LocaleSelector';
export default React.memo(LocaleSelector);
