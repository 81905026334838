import * as ReactDOMClient from 'react-dom/client';

import App from './App';

// test if webpack is in development mode
// @ts-expect-error - set by webpack
// biome-ignore lint/correctness/noUndeclaredVariables: webpack will set this
if (__DEV__) {
  window.__DEV__ = true;
}

// Render each app conditionally to prevent possible React _registerComponent error
const headerRoot = document.getElementById('header-root');

if (headerRoot) {
  ReactDOMClient.createRoot(headerRoot).render(<App href={window.location.href} />);
}
