import * as React from 'react';
import Icon, { IconProps } from './Icon';

export default function createIcon(Svg: React.ComponentType, displayName: string, otherProps: IconProps = {}) {
  const Component = (props: IconProps, ref: React.Ref<SVGElement>) => (
    <Icon Component={Svg} ref={ref} {...otherProps} {...props} />
  );

  Component.displayName = `Icon${displayName}`;

  return React.memo(React.forwardRef(Component));
}
