import { CLOUDINARY_MEDIA_SERVER } from '@iabbb/utils/cloudinary/constants';

export const acSealUrls = {
  horizontal: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/ac-seal-horizontal.svg`,
  vertical: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/ac-seal-vertical.svg`,
};

export const acSealCanUrls = {
  horizontal: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/ac-seal-horizontal-can.svg`,
  vertical: `https://${CLOUDINARY_MEDIA_SERVER}/terminuscontent/dist/img/ac-seal-vertical-can.svg`,
};
