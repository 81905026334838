export const INITIAL_STATE_NAME = '__PRELOADED_STATE__';

/**
 * Retrieves the initial redux state which will be used to bootstrap the store and the deletes it from memory.
 * @param {string} name The name of the initial state to retrieve
 */
export function get<T>(name: string = INITIAL_STATE_NAME): T | null {
  if (typeof window !== 'undefined') {
    if (window[name] === undefined) {
      throw new Error('Initial state expected, but not found!');
    }
    return window[name];
  }

  return null;
}

export function remove(name: string = INITIAL_STATE_NAME): boolean {
  if (typeof window !== 'undefined') {
    // Allow the initial state to be garbage-collected whenever there are no more references to window[name]
    if (name in window) {
      window[name] = undefined; // Set to undefined to effectively "remove" the property
      return true;
    }
  }
  return false;
}
