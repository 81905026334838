import styled from '@emotion/styled';
import Button from '@iabbb/bds-react/Button';
import media from '@iabbb/shared/styles/media';

export default styled(Button)`
  --height-button: 1.3125rem;
  --height-bar: calc(var(--height-button) / 6);

  color: inherit !important;
  height: var(--height-button);
  position: relative;
  width: 1.6875rem;

  ${media.md} {
    --height-button: 1.5625rem;
    width: 2.125rem;
  }

  &:focus {
    outline: 3px solid var(--bds-color-blue-light);
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: 3px solid var(--bds-color-blue-light);
  }

  > span:not(.visually-hidden) {
    background: currentColor;
    border-radius: var(--bds-radius-full);
    height: var(--height-bar);
    left: 0;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;

    &:nth-of-type(1) {
      top: 0px;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      top: calc(calc(var(--height-button) - var(--height-bar)) / 2);
    }

    &:nth-of-type(4) {
      top: calc(var(--height-button) - var(--height-bar));
    }
  }

  &[aria-expanded='true'] {
    > span {
      &:nth-of-type(1) {
        left: 50%;
        top: calc(calc(var(--height-button) - var(--height-bar)) / 2);
        width: 0%;
      }

      &:nth-of-type(2) {
        transform: rotate(45deg);
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg);
      }

      &:nth-of-type(4) {
        left: 50%;
        top: calc(calc(var(--height-button) - var(--height-bar)) / 2);
        width: 0%;
      }
    }
  }
`;
