import { useIsomorphicLayoutEffect } from '@iabbb/utils/useIsomorphicLayoutEffect';
import { createBrowserHistory, History, Listener } from 'history';
import React from 'react';

import { createStaticHistory, redirect, reload } from './utils';

type NavigatorContextState = {
  href?: string;
  historyRef: React.MutableRefObject<History | null>;
  initialKey: string;
};

export const NavigatorContext = React.createContext<NavigatorContextState>({
  href: undefined,
  historyRef: { current: null },
  initialKey: 'default',
});

type NavigatorProps = {
  children: React.ReactNode;
  href: string;
  listener?: Listener;
};

export function NavigatorProvider({ children, href: initialHref = '/', listener = () => {} }: NavigatorProps) {
  const historyRef = React.useRef<History | null>(null);

  const [href, setHref] = React.useState(initialHref);
  // Initial location key, used to figure out initial location
  const [initialKey, setInitialKey] = React.useState('default');

  // setup initial, ssr friendly history
  if (historyRef.current == null) {
    historyRef.current = createStaticHistory({ href: initialHref });
  }

  // run setup after all DOM mutations have completed
  useIsomorphicLayoutEffect(
    () => {
      // window is available now, so go ahead and createBrowserHistory
      historyRef.current = createBrowserHistory();
      // for some reason, unless createBrowserHistory() is called in a completely new browser tab/window aka, no previous history then createBrowserHistory() assigns a new key to location
      // so an 'initialLocation' according to the docs must be a completely fresh tab/window
      setInitialKey(historyRef.current.location.key);
      historyRef.current.listen((params) => {
        setHref((prevHref) => (prevHref !== window.location.href ? window.location.href : prevHref));
        listener(params);
      });
    },

    // no deps, bc this should only be run once
    [],
  );
  return (
    <NavigatorContext.Provider value={{ href, historyRef, initialKey }}>{children}</NavigatorContext.Provider>
  );
}

/**
 * Returns a navigator object to manage history, location and basic routing
 * @returns {object} navigator
 * @returns {object} navigator.isInitialLocation Verifies if navigator is looking at the initial location
 */
export function useNavigator() {
  const { href = '/', historyRef, initialKey } = React.useContext(NavigatorContext);

  const url = React.useMemo(() => new URL(href), [href]);

  return {
    redirect,
    reload,
    url,
    href,
    get history() {
      return historyRef.current;
    },
    get isInitialLocation() {
      const key = historyRef.current?.location.key;
      return !(key && key !== 'default' && key !== initialKey);
    },
  };
}
