import CaSilhouette from './assets/ca-country-silhouette.svg';
import UsSilhouette from './assets/us-country-silhouette.svg';

type Country = 'can' | 'usa';

export type CultureId = 'en-ca' | 'en-us';

export type Culture = {
  countryCode: number;
  countryName: string;
  cultureInfo: string;
  flagImgAttrs: {
    src: string;
    srcSet?: string;
  };
  id: CultureId;
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  label: string;
  twoLetterIsoCountryCode: string;
  threeLetterIsoCountryCode: string;
};

export type CultureById = {
  [key in CultureId]: Culture;
};

export type CultureDataType = {
  allIds: CultureId[];
  byId: CultureById;
  idsByCountry: {
    [key in Country]: CultureId;
  };
};

const CultureData: CultureDataType = {
  allIds: ['en-us', 'en-ca'],
  byId: {
    'en-us': {
      id: 'en-us',
      icon: UsSilhouette,
      label: 'US',
      cultureInfo: 'en-US',
      flagImgAttrs: { src: '/TerminusContent/dist/img/header/us-flag-4x3.svg' },
      twoLetterIsoCountryCode: 'US',
      threeLetterIsoCountryCode: 'USA',
      countryName: 'United States',
      countryCode: 2,
    },
    'en-ca': {
      id: 'en-ca',
      icon: CaSilhouette,
      label: 'Canada',
      cultureInfo: 'en-CA',
      flagImgAttrs: { src: '/TerminusContent/dist/img/header/ca-flag-4x3.svg' },
      twoLetterIsoCountryCode: 'CA',
      threeLetterIsoCountryCode: 'CAN',
      countryName: 'Canada',
      countryCode: 4,
    },
  },
  idsByCountry: {
    usa: 'en-us',
    can: 'en-ca',
  },
};

export default CultureData;
