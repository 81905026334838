import IconClose from '@iabbb/icons/Close';

import DialogCloseButton from './styles';

function DialogCloseButtonComponent(props: React.ComponentPropsWithoutRef<'button'>) {
  return (
    <DialogCloseButton {...props} className="text-size-40" variant="unstyled">
      <IconClose />
      <span className="visually-hidden">close</span>
    </DialogCloseButton>
  );
}

export default DialogCloseButtonComponent;
