import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

export default styled.ul`
  ${media.xsDown} {
    a {
      display: inline-block;
      margin-block: 0.5rem;
    }
  }
`;
