import hasValue from '@iabbb/utils/validators/hasValue';
import isValidObject from '@iabbb/utils/validators/isValidObject';

/**
 * Merges any number of objects together, ignoring empty keys EXCEPT in the initial obj
 */
const cleanMerge = (
  initialObj: Record<number | string, any>,
  ...objs: Record<number | string, any>[]
): Record<number | string, any> => {
  if (!isValidObject(initialObj)) {
    throw Error("'initialObj' is not a valid Object!");
  }
  return objs.reduce((result, obj, index) => {
    if (!isValidObject(obj)) {
      throw Error(`parameter[${index}] is not a valid Object!`);
    }
    return Object.keys(obj).reduce((acc, key) => {
      // only add/update key if not empty
      if (hasValue(obj[key])) {
        acc[key] = obj[key];
      }
      return acc;
    }, result);
  }, initialObj);
};

export default cleanMerge;
