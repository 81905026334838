import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

// Menu.Wrapper
export default styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;

  ${media.md} {
    position: relative;
    width: auto;
  }
`;
