import createTypeaheadActions from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createActions';
import createResultsAction from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createResultsAction';

interface CreateLocationActionsProps {
  actionTypes: any;
  suggestApiFetch?: (fetchParams) => Promise<any>;
}

const createLocationActions = ({ actionTypes, suggestApiFetch }: CreateLocationActionsProps) => {
  const {
    typeaheadSuggestionSelected,
    fetchingTypeaheadResults,
    fetchingDoneTypeaheadResults,
    updateTypeaheadResults,
    setTypeaheadInput,
    setTypeaheadSelected,
    clearTypeahead,
  } = createTypeaheadActions({ actionTypes, type: 'location' });

  const { fetchAndUpdateTypeaheadResults } = createResultsAction({
    actions: {
      fetchingTypeaheadResults,
      fetchingDoneTypeaheadResults,
      updateTypeaheadResults,
    },
    suggestApiFetch,
  });

  const { SET_TYPEAHEAD_CULTURE_ID } = actionTypes;

  function setLocationTypeaheadCultureIdAction(cultureId: string) {
    return {
      type: SET_TYPEAHEAD_CULTURE_ID,
      cultureId,
    };
  }

  function setLocationTypeaheadCultureId(cultureId: string) {
    return (dispatch) => {
      dispatch(setLocationTypeaheadCultureIdAction(cultureId));
    };
  }

  function setLocationTypeahead(location) {
    return (dispatch) => {
      dispatch(setTypeaheadInput(location.displayText ? location.displayText : ''));
      dispatch(setTypeaheadSelected(location));
      dispatch(setLocationTypeaheadCultureId(location.cultureInfoName));
    };
  }
  return {
    typeaheadSuggestionSelected,
    updateTypeaheadResults,
    setTypeaheadInput,
    setTypeaheadSelected,
    clearTypeahead,
    fetchAndUpdateTypeaheadResults,

    setLocationTypeaheadCultureIdAction,
    setLocationTypeaheadCultureId,
    setLocationTypeahead,
  };
};

export default createLocationActions;
