export default function loadScript(src: string) {
  // Get the first script element on the page
  const ref = document.getElementsByTagName('script')[0];

  // Create a new script element
  const script = document.createElement('script');

  // Set the script element `src`
  script.src = src;

  // Inject the script into the DOM
  if (ref.parentNode) {
    ref.parentNode.insertBefore(script, ref);
  }
}
