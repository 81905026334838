import styled from '@emotion/styled';

const Item = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

Item.FlagImg = styled.img`
  block-size: 0.9375rem;
  inline-size: 1.25rem;
  transform: translateY(0.05em);
`;

Item.Label = styled.span`
  margin-inline-start: 0.5rem;
`;

export default Item;
