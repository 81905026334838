import getIn from '@iabbb/utils/object/getIn';
import { createSelector } from 'reselect';

const createSelectors = ({ typeaheadStatePath }) => {
  const selectTypeaheadState = (state: Record<string, object>) => getIn(state, [...typeaheadStatePath]);

  const selectTypeaheadSelectedState = createSelector(
    [(state) => getIn(state, [...typeaheadStatePath, 'selected'])],
    (selected) => (selected?.id ? selected : {}),
  );

  const selectTypeaheadResultsState = (state: Record<string, object>) =>
    getIn(state, [...typeaheadStatePath, 'results']);

  const selectTypeaheadIsFetchingState = (state: Record<string, object>) =>
    getIn(state, [...typeaheadStatePath, 'isFetching']);

  const selectTypeaheadInputState = (state: Record<string, object>) =>
    getIn(state, [...typeaheadStatePath, 'input']) || '';

  const getTypeahead = () => selectTypeaheadState;
  const getSelected = () => selectTypeaheadSelectedState;
  const getResults = () => selectTypeaheadResultsState;

  /**
   * Gets the typeahead is fetching status
   */
  const getIsFetching = () => selectTypeaheadIsFetchingState;

  /**
   * Gets the typeahead input
   */
  const getInput = () => selectTypeaheadInputState;

  return {
    selectTypeaheadState,
    selectTypeaheadResultsState,
    selectTypeaheadIsFetchingState,
    selectTypeaheadInputState,

    getTypeahead,
    getSelected,
    getResults,
    getInput,
    getIsFetching,
  };
};

export default createSelectors;
