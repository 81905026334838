import hideAtClasses, { styles as hideAtStyles } from '@iabbb/shared/styles/hideAt';
import printClasses, { styles as printStyles } from '@iabbb/shared/styles/print';
import { Global, css } from '@emotion/react';

import CardStyles from './blocks/card';
import FormFieldStyles from './blocks/formField';
// import RevengeStyles from './blocks/revenge';
import TableContainerStyles from './blocks/tableContainer';
import ToggleButtonStyles from './blocks/toggleButton';

export const FontStyles = css`
  @font-face {
    font-display: swap;
    font-family: 'proxima-nova';
    src: url('${process.env.NEXT_PUBLIC_BASE_URL}/TerminusContent/fonts/proximanova-reg-webfont.woff2')
      format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: 'proxima-nova';
    font-weight: 600;
    src: url('${process.env.NEXT_PUBLIC_BASE_URL}/TerminusContent/fonts/proximanova-sbold-webfont.woff2')
      format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: 'proxima-nova';
    font-weight: 700;
    src: url('${process.env.NEXT_PUBLIC_BASE_URL}/TerminusContent/fonts/proximanova-bold-webfont.woff2')
      format('woff2');
  }
`;

export const PrintStyles = css`
  @media print {
    button {
      display: none !important;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }
  }

  ${Object.keys(printStyles).reduce(
    (styles, key) => css`
      ${styles}
      .${printClasses[key]} {
        ${printStyles[key]}
      }
    `,
    '',
  )}
`;

export const HideAtStyles = css`
  ${Object.keys(hideAtStyles).reduce(
    (styles, key) => css`
      ${styles}
      .${hideAtClasses[key]} {
        ${hideAtStyles[key]}
      }
    `,
    '',
  )}
`;

/**
 * "Root" styles used by headerfooter and main app
 */
export const RootStyles = css`
  background: var(--bds-color-gray-10);
  color: var(--bds-color-black);
  font-family: var(--bds-font-family-base);
  font-size: 100%;
  line-height: var(--bds-font-leading-2);

  * {
    box-sizing: border-box;
  }

  // 👇 don't allow scrolling behind open dialogs
  &:has(dialog[open]:modal) {
    overflow: hidden;
  }
`;

export const LinkStyles = css`
  a {
    color: var(--bds-color-blue-dark);
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
  }
`;

/**
 * Baseline styles for main app. Broken up so can be consumed by HeaderFooter
 */
function BaselineStyles() {
  return (
    <Global
      styles={css`
        ${FontStyles}

        html {
          -moz-text-size-adjust: none;
          -webkit-text-size-adjust: none;
          text-size-adjust: none;
        }

        ${LinkStyles}
        th {
          text-align: left;
        }
        /**
          Root id set by SSR
          */
        :root {
          ${RootStyles}
        }
        ${PrintStyles}
        ${HideAtStyles}

        @media (min-width: 62em) {
          .hide-desktop-search iabbb-header iabbb-search-bar {
            display: none;
          }
        }

        /* Remove default margin in favor of better control in authored CSS */
        body,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ul,
        ol,
        dl {
          margin: 0;
        }

        [hidden] {
          display: none !important;
        }

        [hidden] + *,
        input[type='hidden'] + * {
          --stack-space: 0;
        }

        sup {
          position: relative;
          top: -0.5em;
          vertical-align: baseline;
        }

        code,
        pre {
          font-family: var(--bds-font-family-mono);
        }

        // 👇 in Chrome/Safari, a native cancel button is shown for Find typeahead, but we have our own
        input[type='search']::-webkit-search-cancel-button,
        input[type='search']::-webkit-search-decoration {
          -webkit-appearance: none;
        }

        /* 👇 Remove all animations, transitions and smooth scroll for people that prefer not to see them */
        @media (prefers-reduced-motion: reduce) {
          *,
          *::before,
          *::after {
            animation-duration: 0.01ms !important;
            animation-iteration-count: 1 !important;
            transition-duration: 0.01ms !important;
            scroll-behavior: auto !important;
          }
        }

        .with-icon {
          /* ↓ Set the inline-flex context, which eliminates the word space */
          display: inline-flex;
          align-items: center;
          line-height: var(--bds-font-leading-1);

          .icon {
            flex-shrink: 0;
            /* ↓ Use the logical margin property and a --icon-space variable with a fallback */
            margin-inline-end: var(--icon-space, 0.375em);
          }
        }

        .with-icon[data-icon-align='first-line'] {
          align-items: flex-start;
          line-height: inherit;

          .icon {
            transform: translateY(0.15em);
          }
        }

        /* Use with caution: this has unintended effects for keyboard/screen reader users. */
        /* https://ablaze-panda-fda.notion.site/Perils-of-trunc-3db1740d77e24a48a32701ec125b9cb3 */
        .line-clamp {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: var(--max-lines, 3);
          display: -webkit-box;
          overflow: hidden;
        }

        .grecaptcha-badge {
          visibility: hidden;
        }

        .no-js .js-only {
          display: none;
        }

        .js .no-js-only {
          display: none;
        }

        input.width-15 {
          max-inline-size: 20ch;
        }

        input.width-10 {
          max-inline-size: 15ch;
        }

        // override dialog position when not polyfilled
        dialog:not([role='dialog']) {
          position: fixed;
        }

        // Uncomment when you want to debug, and also add a $ before the curly brace
        // biome doesn't like when the $ is there, even when the line is commented. 🤔
        /* {RevengeStyles} */

        ${FormFieldStyles}
        ${TableContainerStyles}
        ${ToggleButtonStyles}
        ${CardStyles}

        .list-reset {
          list-style: none;
          padding-inline-start: 0;
        }
      `}
    />
  );
}

export default BaselineStyles;
