export const DEFAULT_CULTURE_URL = '/en/us';
export const DEFAULT_CULTURE_INFO = 'en-US';
export const DEFAULT_CULTURE_ID = 'en-us';
export const DEFAULT_COUNTRY_CODE = 'USA';
export const DEFAULT_COUNTRY_CODE_ISO = 'US';
export const DEFAULT_LANGUAGE = 'en';

export const REGEX_VALID_COUNTRY = new RegExp('us|ca|mx');

export const REGEX_LANGUAGE = /^(en|es|fr)$/;
