import CookieNames from '@iabbb/utils/Storage/CookieNames';
import Cookies from 'js-cookie';

import { SET_COOKIE, SET_CULTURE_ID, SET_LOCATION, SET_COUNTRY_OF_ORIGIN, SET_COOKIES } from './actionTypes';

function setCookieAction(key, value) {
  return {
    type: SET_COOKIE,
    key,
    value,
  };
}

function setUserCultureIdAction(cultureId: number | string) {
  return {
    type: SET_CULTURE_ID,
    cultureId,
  };
}

function setUserLocationAction(location: object) {
  return {
    type: SET_LOCATION,
    location,
  };
}

function setUserCountryOfOriginAction(countryOfOrigin: string) {
  return {
    type: SET_COUNTRY_OF_ORIGIN,
    countryOfOrigin,
  };
}

function setUserCookiesAction(cookies: unknown) {
  return {
    type: SET_COOKIES,
    cookies,
  };
}

export function setUserCookies(cookies: unknown) {
  return (dispatch) => {
    dispatch(setUserCookiesAction(cookies));
  };
}

export function setUserCountryOfOrigin(countryOfOrigin: string) {
  return (dispatch) => {
    dispatch(setUserCountryOfOriginAction(countryOfOrigin));
  };
}

/**
 *
 *
 * @export
 * @param {any} cultureId
 * @returns A promise
 */
export function setUserCultureId(cultureId: string) {
  return (dispatch) => {
    dispatch(setUserCultureIdAction(cultureId));
  };
}

export function saveUserCultureIdToCookie(cultureId: string, expiration: number) {
  return (dispatch) => {
    dispatch(
      setCookie({
        name: CookieNames.USER_CULTURE,
        value: cultureId,
        expiration,
      }),
    );
  };
}
export function saveUserLocationIdToCookie(locationId: string, expiration: number) {
  return (dispatch) => {
    dispatch(
      setCookie({
        name: CookieNames.USER_LOCATION,
        value: locationId,
        expiration,
      }),
    );
  };
}

export function saveUserPostalCodeToCookie(postalCode: string, expiration: number) {
  return (dispatch) => {
    dispatch(
      setCookie({
        name: CookieNames.USER_POSTALCODE,
        value: postalCode,
        expiration,
      }),
    );
  };
}

export function saveUserBbbIdToCookie(bbbId: string, expiration: number) {
  return (dispatch) => {
    dispatch(
      setCookie({
        name: CookieNames.USER_BBB,
        value: bbbId,
        expiration,
      }),
    );
  };
}

function removeUserLocationCookie() {
  return (dispatch) => {
    dispatch(removeCookie(CookieNames.USER_LOCATION));
  };
}

/**
 * Set's the user's culture and saves the culture cookie
 *@param {string} cultureId Culture info name ex. en-us
 */
export function setUserCultureIdAndSaveCookie(cultureId: string) {
  return (dispatch) => {
    const cookieExpiration = process.env.NEXT_PUBLIC_COOKIE_EXPIRATION;
    dispatch(setUserCultureId(cultureId));
    dispatch(saveUserCultureIdToCookie(cultureId, cookieExpiration));
  };
}

export function removeUserLocation() {
  return (dispatch) => {
    dispatch(setUserLocationAction({}));
    dispatch(removeUserLocationCookie());
  };
}

export function setUserLocation(location) {
  return (dispatch) => {
    dispatch(setUserLocationAction(location));
  };
}

export function setUserLocationAndSaveCookies(location) {
  return (dispatch) => {
    const cookieExpiration = process.env.NEXT_PUBLIC_COOKIE_EXPIRATION;
    const bbbInfo = location.bbbInfo;
    dispatch(setUserLocationAction(location));
    dispatch(saveUserLocationIdToCookie(location.id, cookieExpiration));
    dispatch(saveUserPostalCodeToCookie(location.postalCode, cookieExpiration));
    if (bbbInfo?.bbbId) {
      dispatch(saveUserBbbIdToCookie(bbbInfo.bbbId, cookieExpiration));
    }
  };
}

export function setCookie({ name, value, expiration }) {
  return (dispatch) => {
    const domain = process.env.NEXT_PUBLIC_DOMAIN;
    if (domain?.startsWith('www.bbb.org') || domain?.startsWith('www.cbbbstage.com')) {
      return; // prevents duplicate cookies for www and non-www bbb urls.
    }
    if (expiration) {
      // expiration must be casted to int or Cookies.set will fail/throw an exception
      Cookies.set(name, value, { expires: Number.parseInt(expiration as string), domain, path: '/' });
    } else {
      Cookies.set(name, value, { domain, path: '/' });
    }
    dispatch(setCookieAction(name, value));
  };
}

export function removeCookie(name: string) {
  return (dispatch) => {
    const domain = process.env.NEXT_PUBLIC_DOMAIN;
    Cookies.remove(name, { domain });
    dispatch(setCookieAction(name, null));
  };
}

export function getFacCookie(bbbId: string, businessId: string, addressId: string) {
  return Cookies.get(`${CookieNames.FAC_FORM}${bbbId}-${businessId}${addressId ? `-${addressId}` : ''}`);
}
