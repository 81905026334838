import { produce } from 'immer';
import { createSelector } from 'reselect';

import { getCultures } from '@iabbb/shared/states/Global/selectors';
import CultureData from '@iabbb/utils/culture/allCultureData';
import { DEFAULT_COUNTRY_CODE_ISO } from '@iabbb/utils/culture/constants';

import createTypeaheadSelectors from '../GenericTypeahead/createSelectors';

import getIn from '@iabbb/utils/object/getIn';

const createSelectors = ({
  typeaheadStatePath,
  selectFns: {
    selectTypeaheadCultureId = (state: Record<string, object>) =>
      getIn(state, [...typeaheadStatePath, 'cultureId']),
    selectCondensedPlaceholderProp = (_, { condensedPlaceholders }: { condensedPlaceholders?: unknown } = {}) =>
      condensedPlaceholders,
  } = {},
}) => {
  const {
    selectTypeaheadState,
    selectTypeaheadResultsState,
    getTypeahead,
    getSelected,
    getResults,
    getInput,
    getIsFetching,
  } = createTypeaheadSelectors({
    typeaheadStatePath,
  });

  const getResultsWithCurrentLocation = () =>
    createSelector(
      [
        selectTypeaheadResultsState,
        (state) => getIn(state, ['user', 'physicalLocation']),
        selectTypeaheadCultureId,
      ],
      (results, userPhysicalLocation, typeaheadCultureId) => {
        const cultureId =
          typeaheadCultureId && CultureData.allIds.includes(typeaheadCultureId) ? typeaheadCultureId : 'en-us';

        const { twoLetterIsoCountryCode: typeaheadCountry } = CultureData.byId[cultureId];
        const [_city, _state, country] = (userPhysicalLocation || '').split(', ');

        if (country === typeaheadCountry) {
          // Prepend Current Location to all results
          return produce(results || [], (draft) => {
            draft.unshift({
              displayText: 'Current Location',
              currentLocation: true,
            });
          });
        }

        return results || [];
      },
      // 👇 this selector incorrectly triggers an identity function warning, which clouds up the local dev terminal
      { devModeChecks: { identityFunctionCheck: 'never' } },
    );

  const getTypeaheadCultureId = () => selectTypeaheadCultureId;

  const createTypeaheadPlaceholder = (
    cultures: Record<number | string, unknown> | null,
    id: number | string,
    condensed: unknown,
  ) => {
    const countryCode = getIn(cultures, ['byId', id, 'twoLetterIsoCountryCode'], DEFAULT_COUNTRY_CODE_ISO);

    if (condensed) {
      switch (countryCode) {
        case 'CA':
          return 'Your Location';
        default:
          break;
      }
    }

    switch (countryCode) {
      case 'CA':
        return 'city, prov or postal';
      default:
        return 'city, state or zip';
    }
  };

  /**
   * Gets the typeahead placeholder
   */
  const getPlaceholder = () =>
    createSelector(
      [getCultures(), selectTypeaheadCultureId, selectCondensedPlaceholderProp],
      createTypeaheadPlaceholder,
    );

  return {
    selectTypeaheadCultureId,
    selectCondensedPlaceholderProp,
    selectTypeaheadState,

    getTypeahead,
    getInput,
    getIsFetching,
    getSelected,
    getResults,

    getResultsWithCurrentLocation,
    getTypeaheadCultureId,
    getPlaceholder,
  };
};

export default createSelectors;
