import { produce } from 'immer';

import { SET_COOKIE, SET_CULTURE_ID, SET_LOCATION, SET_COUNTRY_OF_ORIGIN, SET_COOKIES } from './actionTypes';

const initialState = {
  cookies: {},
  defaultLocation: {},
  physicalLocation: '',
  cultureId: '',
  location: {},
  countryOfOrigin: { isoCode: '', name: '', isInEu: false },
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CULTURE_ID:
      draft.cultureId = action.cultureId;
      break;
    case SET_LOCATION:
      draft.location = action.location;
      break;
    case SET_COUNTRY_OF_ORIGIN:
      draft.countryOfOrigin = action.countryOfOrigin;
      break;
    case SET_COOKIE:
      draft.cookies[action.key] = action.value;
      break;
    case SET_COOKIES:
      draft.cookies = action.cookies;
      break;
    default:
      break;
  }
}, initialState);
