import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveUserLocationAndCultureFromLocaleSelectorAsync } from '@iabbb/shared/containers/Header/LocaleSelector/actions';
import { getMyLocationLabel } from '@iabbb/shared/containers/Header/LocaleSelector/selectors';
import TypeaheadErrorContainer from '@iabbb/shared/components/Typeahead/ErrorContainer';
import Button from '@iabbb/bds-react/Button';
import { getUserCulture } from '@iabbb/shared/states/User/selectors';
import { DisplayLocationStateName } from '@iabbb/shared/containers/Header/LocaleSelector/Modal/constants';
import getIn from '@iabbb/utils/object/getIn';
import { createSetLocationEvent } from '@iabbb/utils/events';
import Typography from '@iabbb/bds-react/Typography';

import CountrySelectionDropdown from './CountrySelectionDropdown';
import HomeLocationTypeahead from './HomeLocationTypeahead';

const localeLabelSelector = getMyLocationLabel();
const userCultureSelector = getUserCulture();

/**
 * This looks a bit silly, but is the shortest way to pick out values from userLocation.  Also possible to use lodash or a native implementation of 'pick'
 * @param {object} userLocation The user location object
 * @returns Filtered user location object
 */
const createEventData = ({
  id,
  city,
  state,
  country,
  postalCode,
  latLng,
  bbbInfo: { bbbId, bbbName, primaryCountry, primaryLanguage },
}) => {
  return {
    id,
    city,
    state,
    country,
    postalCode,
    latLng,
    bbbInfo: { bbbId, bbbName, primaryCountry, primaryLanguage },
  };
};

export const ChooseLocationState = ({ setModalState }) => {
  const dispatch = useDispatch();
  const [hasLocationError, setHasLocationError] = useState(false);

  const initialLocationId = useSelector((state: Record<string, object>) =>
    getIn(state, ['user', 'location', 'id']),
  );
  /* keep a reference to the location id*/
  const [locationId, setLocationId] = useState(initialLocationId);
  const localeLabel = useSelector(localeLabelSelector);
  const userCulture = useSelector(userCultureSelector);

  const handleSubmit = useCallback(
    (event) => {
      setHasLocationError(false);

      // an event isnt always available;
      if (event) {
        event.preventDefault();
      }
      // Uses the data in the locale selector to save a new user location and culture
      // If culture is new then redirects
      return dispatch(saveUserLocationAndCultureFromLocaleSelectorAsync() as any)
        .then(
          (userLocation) => {
            // Raise event on location change to be consumed by 3rd party/AEM
            if (userLocation.id !== locationId) {
              const eventData = createEventData(userLocation);
              document.dispatchEvent(createSetLocationEvent(eventData) as never);
              setLocationId(userLocation.id);

              if (window.webDigitalData?.user) {
                window.webDigitalData.user.my_bbb_id = userLocation.bbbInfo.bbbId;
                window.webDigitalData.user.my_bbb_name = userLocation.bbbInfo.bbbName;
                window.webDigitalData.user.city = userLocation.city;
                window.webDigitalData.user.state = userLocation.state;
                window.webDigitalData.user.country = userLocation.threeLetterIsoCountryCode;
                window.webDigitalData.user.zip = userLocation.postalCode;
                window.webDigitalData.user.culture_id = userLocation.cultureInfoName;
              }
            }

            // Switch display
            setModalState(DisplayLocationStateName);
            return Promise.resolve(userLocation);
          },
          () => {
            setHasLocationError(true);
            return Promise.reject();
          },
        )
        .catch(() => {});
    },
    [dispatch, setHasLocationError, localeLabel, userCulture, setModalState, locationId, setLocationId],
  );

  return (
    <form noValidate onSubmit={handleSubmit} className="stack">
      <Typography component="h2" variant="h5">
        Set Your Home Location:
      </Typography>
      <CountrySelectionDropdown />
      <TypeaheadErrorContainer hasError={hasLocationError} errorText="No locations matching your search found.">
        <HomeLocationTypeahead />
      </TypeaheadErrorContainer>
      <div className="center">
        <Button className="dtm-locale-selector-set-location">Set Location</Button>
      </div>
    </form>
  );
};

ChooseLocationState.propTypes = {
  setModalState: PropTypes.func.isRequired,
};
ChooseLocationState.displayName = 'LocaleSelector.Modal.ChooseLocationState';
export default React.memo(ChooseLocationState);
