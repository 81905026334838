import React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FooterComponent from '@iabbb/shared/containers/Footer';
import HeaderComponent from '@iabbb/shared/containers/Header';
import parseStrToBool from '@iabbb/utils/string/parseStrToBool';
import { setCookie } from '@iabbb/shared/states/User/actions';
import { useDispatch } from 'react-redux';

const footerRootElement = document.getElementById('footer-root');

function Footer() {
  return footerRootElement && ReactDOM.createPortal(<FooterComponent />, footerRootElement);
}

function Header({ showSearchForm }: { showSearchForm?: boolean }) {
  return <HeaderComponent showMobileSearch={showSearchForm} showDesktopSearch={showSearchForm} />;
}
const mapToHeaderProps = ({ showSearchBar }) => {
  // gives me bool or string value
  const val = parseStrToBool(showSearchBar);
  const props: { showSearchForm?: boolean } = {};

  if (!val) {
    props.showSearchForm = false;
  }
  return props;
};

function HeaderFooterWrapper({ showSearchBar = true }: { showSearchBar: boolean }) {
  const headerProps = React.useMemo(() => mapToHeaderProps({ showSearchBar }), [showSearchBar]);
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {};
    const itmParams = {};

    for (const [key, value] of urlParams.entries()) {
      if (key.startsWith('utm_')) {
        utmParams[key] = value;
      } else if (key.startsWith('itm_')) {
        itmParams[key] = value;
      }
    }

    if (Object.keys(utmParams).length > 0) {
      const utmString = Object.keys(utmParams)
        .map((key) => `${key}=${utmParams[key]}`)
        .join('&');

      dispatch(setCookie({ name: `iabbb_utm`, value: utmString, expiration: undefined }) as never);
    }

    if (Object.keys(itmParams).length > 0) {
      const itmString = Object.keys(itmParams)
        .map((key) => `${key}=${itmParams[key]}`)
        .join('&');

      dispatch(setCookie({ name: `iabbb_itm`, value: itmString, expiration: undefined }) as never);
    }
  }, [dispatch]);

  return (
    <div>
      <Header {...headerProps} />
      <Footer />
    </div>
  );
}

export default React.memo(HeaderFooterWrapper);
