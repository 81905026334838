import clsx from 'clsx';

import type { NavLink } from '../../types';
import SubNavItem from './SubNavItem';

export interface SubNavigationProps extends React.ComponentPropsWithoutRef<'ul'> {
  links: Array<NavLink>;
  isOpen: boolean;
}

function SubNavigation({ links, isOpen, className, ...props }: SubNavigationProps) {
  return (
    <ul className={clsx('list-reset', className)} {...props}>
      {links.map((navItem) => (
        <SubNavItem key={navItem.id} link={navItem} />
      ))}
    </ul>
  );
}

export default SubNavigation;
