import { produce } from 'immer';

import { SET_SPINNER_VISIBILITY } from '@iabbb/shared/states/Global/actions';

const initialState = {
  source: '',
  showSpinner: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_SPINNER_VISIBILITY:
      draft.showSpinner = action.show;
      break;
    default:
  }
}, initialState);
