import { SocialMediaByType, SocialMediaType } from '@iabbb/utils/socialMedia';

export interface IconProps {
  type: SocialMediaType;
}

function IconComponent({ type }: IconProps) {
  const Icon = SocialMediaByType[type].icon;
  return <Icon />;
}

IconComponent.displayName = 'IconComponent';
export default IconComponent;
