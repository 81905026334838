import styled from '@emotion/styled';

// Dropdown.Toggle
export default styled.button`
  align-items: center;
  border: 1px solid var(--bds-color-gray-70);
  border-radius: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 10px;
  white-space: nowrap;
  width: 100%;

  & > span {
    flex-grow: 1;
  }

  &:hover {
    background: var(--bds-color-gray-30);
  }

  &:active {
    box-shadow: var(--bds-shadow-inner);
  }
`;
