const SET_LOCATION = 'iabbb:localeselector.setlocation';

export interface LocationResource {
  bbbInfo: {
    bbbId: string;
    bbbName: string;
    primaryCountry: string;
    primaryLanguage: string;
  };
  city: string;
  country: string;
  id: string;
  latLng: string;
  postalCode: string;
  state: string;
}

type SetLocationFunction = (data: LocationResource) => void;

export const createSetLocationEvent: SetLocationFunction = (data) =>
  new CustomEvent(SET_LOCATION, { detail: data });
