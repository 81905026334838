import toLower from '@iabbb/utils/string/toLower';

// TODO: Make this more robust and match the SEO friendly sanitizer on the C# side
/**
 * Encodes, lower cases and replaces any spaces with a dash.
 * @param {String} str - segment of url
 */
const sanitizeStr = (str: string): string => (str ? encodeURIComponent(toLower(str.replace(/\s/g, '-'))) : str);

export default sanitizeStr;
