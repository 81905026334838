import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';
import clsx from 'clsx';

import Clear from './Clear';
import Label from './Label';

const StyledInputGroup = styled.div`
  align-items: stretch;
  background: var(--bds-color-white);
  border: 1px solid var(--bds-color-blue-brand);
  display: flex;
  min-block-size: 2.1875rem;
  justify-content: space-between;
  line-height: var(--bds-font-leading-1);
  position: relative;
  width: 100%;

  input,
  input:focus {
    border: none;
    background: transparent;

    /*
    ** Bold and normal font weights for our font family (Proxima Nova) seem to be set slightly differently.
    ** Bumping up the input by 1px seems to even it out.
    */
    margin-block-start: -1px;
  }
`;

/**
 * Our "contained input" field, displaying a label on the inside of the field.
 * Use only for search fields; for forms, reach for the BDS text input instead.
 */
function InputGroup({ className, ...props }) {
  return <StyledInputGroup className={clsx('input-group', className)} {...props} />;
}

InputGroup.Clear = Clear;
InputGroup.Label = Label;

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  button[data-type='search'] {
    align-self: flex-start;
    min-width: 6.625rem;
  }

  ${media.md} {
    align-items: center;
    flex-direction: row;
    gap: 1.5rem;
  }
`;

/**
 * A container to layout our typical two search field (query, location) + button group configuration.
 * Is responsive so that in small viewports, fields will stack instead of displaying in a
 */
export function InputContainer({ className, ...props }) {
  return <StyledInputContainer className={clsx('input-container', className)} {...props} />;
}

export default InputGroup;
