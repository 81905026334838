const LegacyDataLayerKeyMap = {
  bbb_id: 'bbbId',
  bbb_name: 'bbbName',
  business_id: 'businessId',
  business_name: 'businessName',
  complaint_step: 'complaintStep',
  find_method: 'findMethod',
  near_method: 'nearMethod',
  postal_code: 'postalCode',
  search_find_field: 'searchFindField',
  search_near_field: 'searchNearField',
  stars_selected: 'starsSelected',
};

export type DirectCallProps = {
  name: string;
  dataLayerProperties?: Record<string, number | string>;
};

/**
 * Performs Adobe Analytics direct call tracking
 *
 * @param {string} name - the direct call name
 * @param {obj} dataLayerProperties - values to add to the data layer
 */
const directCall = ({ name, dataLayerProperties }: DirectCallProps): void => {
  if (!window._satellite) {
    return;
  }

  if (dataLayerProperties) {
    // 👇 Update legacy data layer
    if (window.bbbDtmData) {
      const legacyDataLayerPayload = Object.entries(dataLayerProperties).reduce((result, [key, value]) => {
        if (key in LegacyDataLayerKeyMap) {
          key = LegacyDataLayerKeyMap[key];
        }

        result[key] = value;

        return result;
      }, {});

      window.bbbDtmData = {
        ...window.bbbDtmData,
        directCall: legacyDataLayerPayload,
      };
    }

    // 👇 Update data layer
    if (window.webDigitalData) {
      window.webDigitalData = {
        ...window.webDigitalData,
        direct_call: dataLayerProperties,
      };
    }
  }

  // If there are no new properties to set, clear out the data layers before firing the tracking call.
  else {
    if (window.bbbDtmData) {
      window.bbbDtmData = {
        ...window.bbbDtmData,
        directCall: {},
      };
    }

    if (window.webDigitalData) {
      window.webDigitalData = {
        ...window.webDigitalData,
        direct_call: {},
      };
    }
  }

  window._satellite.track(name);
};

export default directCall;
