import IconChevronCircleRight from '@iabbb/icons/ChevronCircleRight';
import Button from '@iabbb/bds-react/Button';
import getIn from '@iabbb/utils/object/getIn';
import { DEFAULT_BBB_ID } from '@iabbb/utils/bbbInfo/constants';
import { getMyLocationLabel } from '@iabbb/shared/containers/Header/LocaleSelector/selectors';
import { ChooseLocationStateName } from '@iabbb/shared/containers/Header/LocaleSelector/Modal/constants';
import getGeoUrlOrFallback from '@iabbb/utils/bbbInfo/getGeoUrlOrFallback';
import Typography from '@iabbb/bds-react/Typography';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from '@emotion/styled';

import clsx from 'clsx';

// View components

const PrimaryLink = styled.a`
  display: inline-block;
`;

// selectors
const myLocationLabelSelector = getMyLocationLabel();

const bbbNameSelector = createSelector(
  [
    (state) => getIn(state, ['user', 'location', 'bbbInfo', 'bbbId']),
    (state) => getIn(state, ['user', 'location', 'bbbInfo', 'bbbName']),
  ],
  (bbbId, bbbName) => (bbbId && bbbId !== DEFAULT_BBB_ID ? bbbName : ''),
);

const bbbUrlSelector = (state: Record<string, object>) =>
  getIn(state, ['user', 'location', 'bbbInfo', 'urls', 'bbbUrl']) || process.env.NEXT_PUBLIC_BASE_URL;

const myLocationUrlSelector = createSelector([(state) => getIn(state, ['user', 'location'])], (savedLoc) =>
  getGeoUrlOrFallback(savedLoc, process.env.NEXT_PUBLIC_BASE_URL),
);

export function DisplayLocationState({ setModalState }) {
  const myLocationDisplayText = useSelector(myLocationLabelSelector);
  const myLocationUrl = useSelector(myLocationUrlSelector);
  const myBbbName = useSelector(bbbNameSelector);
  const myBbbUrl = useSelector(bbbUrlSelector);

  const handleChangeLocationClick = () => {
    setModalState(ChooseLocationStateName);
  };

  return (
    <div className="stack" style={{ '--stack-space': '1.5rem' } as React.CSSProperties}>
      <div className="stack" style={{ '--stack-space': '0.25rem' } as React.CSSProperties}>
        <Typography component="h2" variant="h5">
          My Home Location
        </Typography>
        <div>
          <PrimaryLink
            className={clsx('active-underline', 'dtm-locale-selector-location-name')}
            href={myLocationUrl}
          >
            <Typography className="text-size-30" component="span">
              {myLocationDisplayText as string}
            </Typography>
            <IconChevronCircleRight
              className="text-size-30"
              style={{ marginInlineStart: '0.375rem', transform: 'translateY(0.125em)' }}
            />
          </PrimaryLink>
          <Button
            className="text-black"
            onClick={handleChangeLocationClick}
            style={{ display: 'block' }}
            variant="unstyled"
          >
            Change home location
          </Button>
        </div>
      </div>
      <div className="stack">
        <Typography component="h2" variant="h5">
          My BBB
        </Typography>
        <PrimaryLink
          className="text-size-30"
          href={myBbbUrl}
          style={{ '--stack-space': '0.25rem' } as React.CSSProperties}
        >
          {myBbbName}
          <IconChevronCircleRight
            className="text-size-30"
            style={{ marginInlineStart: '0.375rem', transform: 'translateY(0.125em)' }}
          />
        </PrimaryLink>
      </div>
    </div>
  );
}

DisplayLocationState.displayName = 'LocaleSelector.Modal.DisplayLocationState';

export default DisplayLocationState;
