import Center from '@iabbb/shared/components/General/Center';
import Button from '@iabbb/bds-react/Button';
import clsx from 'clsx';

import FindTypeahead from '../FindTypeahead';
import NearTypeahead from '../NearTypeahead';
import SearchForm from '../SearchForm';
import SearchBar from './styles';

function SearchBarComponent({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <SearchBar className={clsx('bg-blue-medium-87', 'shadow-base', className)} {...props}>
      <Center>
        <SearchForm className={clsx('cluster', 'gutter-24')}>
          <div className="switcher" style={{ flexGrow: '1' }}>
            <FindTypeahead />
            <NearTypeahead />
          </div>
          <Button type="submit" variant="search">
            Search
          </Button>
        </SearchForm>
      </Center>
    </SearchBar>
  );
}

SearchBarComponent.displayName = 'SearchBar';

export default SearchBarComponent;
