import { FeatureFlagName, useFeatureFlag } from '@iabbb/context/FeatureFlag';
import Center from '@iabbb/shared/components/General/Center';
import { breakpointsInPixels } from '@iabbb/shared/styles/media/breakpoints';
import print from '@iabbb/shared/styles/print';
import useEnhancedResizeListener from '@iabbb/utils/useEnhancedResizeListener';
import getIn from '@iabbb/utils/object/getIn';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CookiesMessage from '../CookiesMessage';
import BbbLinksToolbar from './BbbLinksToolbar';
import { getInput } from './FindTypeahead/selectors';
import LinkResources from './LinkResources';
import LocaleSelector from './LocaleSelector';
import LogoDesktop from './LogoDesktop';
import LogoMobile from './LogoMobile';
import MobileSearch from './MobileSearch';
import NavMenu from './NavMenu';
import { showNavMenu } from './NavMenu/actions';
import SearchBar from './SearchBar';
import HeaderActions from './styles/HeaderActions';
import HeaderSection from './styles/HeaderSection';
import StyledHeader from './styles/StyledHeader';
import OutageDisclaimer from '../OutageDisclaimer';

export type HeaderProps = {
  showDesktopSearch?: boolean;
  showMobileSearch?: boolean;
};

function Header({
  className = undefined,
  showDesktopSearch = true,
  showMobileSearch = true,
}: HeaderProps & React.ComponentPropsWithoutRef<'header'>) {
  const pageHasNewHeaderFooter = useSelector((state: any) => getIn(state, ['newHeaderFooter', 'enabled']));

  const outageMessageFeatureFlag = useFeatureFlag<{ enabled: boolean }>(FeatureFlagName.OutageMessage);
  const preOutageMessageFeatureFlag = useFeatureFlag<{ enabled: boolean }>(FeatureFlagName.PreOutageMessage);
  const customOutageMessageFeatureFlag = useFeatureFlag<{ enabled: boolean }>(FeatureFlagName.CustomOutageMessage);
  const navLinks = useSelector((state: any) =>
    getIn(state, ['user', 'location', 'bbbInfo', 'navigation', 'mainNavLinks']),
  );
  const navMenuExpanded = useSelector((state: any) => getIn(state, ['header', 'navMenu', 'menuOpen']));

  const [showMobileSearchDialog, setShowMobileSearchDialog] = useState(false);

  const input: string = useSelector(getInput());
  const dispatch = useDispatch();

  const onClickToggleMobileSearch = useCallback(() => {
    // If the menu is open, close it.
    dispatch(showNavMenu(false) as never);
    setShowMobileSearchDialog((isVisible) => !isVisible);
  }, [dispatch]);

  // 👇 Simply hiding the dialog with CSS presents accessibility problems,
  // because the rest of the document remains unfocusable.
  // It's better to close the modal entirely.
  useEnhancedResizeListener(typeof window !== 'undefined' ? document.body : null, (element: Element) => {
    if (!showMobileSearchDialog) {
      return;
    }

    if (element.clientWidth > breakpointsInPixels.md) {
      setShowMobileSearchDialog(false);
    }
  });

  if (pageHasNewHeaderFooter) {
    return null;
  }

  return (
    <>
      <CookiesMessage />
      <StyledHeader className="iabbb-header">
        <BbbLinksToolbar />
        <HeaderSection className={className}>
          <Center
            className="repel"
            style={{ '--gutter': '1rem', '--repel-vertical-alignment': 'stretch' } as React.CSSProperties}
          >
            <LogoDesktop hasDesktopSearch={showDesktopSearch} />
            <HeaderActions>
              <div
                className={clsx('cluster', 'actions-container', print.nothing)}
                style={{ '--gutter': '2rem', flexWrap: 'nowrap' } as React.CSSProperties}
              >
                <LinkResources />
                <LocaleSelector />
              </div>
              <LogoMobile />
              <div className="nav-container">
                <NavMenu isOpen={navMenuExpanded} links={navLinks} />
              </div>
            </HeaderActions>
          </Center>
          {showMobileSearch && (
            <Center>
              <MobileSearch
                onClickToggleModal={onClickToggleMobileSearch}
                showModal={showMobileSearchDialog}
                value={input}
              />
            </Center>
          )}
          {showDesktopSearch && <SearchBar />}
        </HeaderSection>
      </StyledHeader>
      {(outageMessageFeatureFlag?.options.enabled ||
        preOutageMessageFeatureFlag?.options.enabled ||
        customOutageMessageFeatureFlag?.options.enabled) && <OutageDisclaimer />}
    </>
  );
}

export default React.memo(Header);
