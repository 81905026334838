import LogoTorch from '@iabbb/shared/components/Graphics/Logos/Torch';
import clsx from 'clsx';

import LogoDesktop from './styles';

export interface LogoDesktopProps {
  hasDesktopSearch: boolean;
}

function LogoDesktopComponent({
  hasDesktopSearch,
  ...props
}: LogoDesktopProps & React.ComponentPropsWithoutRef<'a'>) {
  return (
    <LogoDesktop href={process.env.NEXT_PUBLIC_BASE_URL} {...props}>
      <div className={clsx('logo-img', 'dtm-header-logo', { 'shadow-base': !hasDesktopSearch })}>
        <LogoTorch aria-hidden="true" title={undefined} />
      </div>
      <div className="logo-text" translate="no">
        Better Business Bureau<sup>&reg;</sup>
        <span className="visually-hidden">Homepage</span>
      </div>
    </LogoDesktop>
  );
}

export default LogoDesktopComponent;
