import { FeatureFlagName, useFeatureFlag } from '@iabbb/context/FeatureFlag';
import styled from '@emotion/styled';
import Center from '@iabbb/shared/components/General/Center';
import useCookie from '@iabbb/shared/states/User/useCookie';
import CookieNames from '@iabbb/utils/Storage/CookieNames';
import DialogCloseButton from '@iabbb/shared/components/General/DialogCloseButton';

const OutageDisclaimerContainer = styled.div`
  padding: 1.25rem;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function OutageDisclaimer() {
  const outageMessageFeatureFlag = useFeatureFlag<{ enabled: boolean }>(FeatureFlagName.OutageMessage);
  const preOutageMessageFeatureFlag = useFeatureFlag<{ enabled: boolean }>(FeatureFlagName.PreOutageMessage);
  const customOutageMessageFeatureFlag = useFeatureFlag<{ enabled: boolean; message: string | null }>(
    FeatureFlagName.CustomOutageMessage,
  );

  const customOutageMessage = customOutageMessageFeatureFlag?.options.message ?? null;
  const [dismissedOutageDisclaimer, setDismissedOutageDisclaimer] = useCookie(CookieNames.OUTAGE_DISCLAIMER) as [
    string,
    (value: boolean) => void,
    () => void,
  ];

  const handleClose = () => {
    setDismissedOutageDisclaimer(true);
  };

  if (dismissedOutageDisclaimer) {
    return null;
  }

  return (
    <OutageDisclaimerContainer className="bg-green-dark font-bold text-white">
      <Center>
        <DialogCloseButton style={{ top: 'auto' }} onClick={handleClose} />
        {outageMessageFeatureFlag
          ? `We are performing scheduled maintenance, which may temporarily impact the functionality of certain features on the site. Thank you.`
          : preOutageMessageFeatureFlag
            ? `Tonight at 9pm ET we will be performing maintenance, which may temporarily impact the functionality of certain features on the site.`
            : customOutageMessageFeatureFlag
              ? customOutageMessage
              : null}
      </Center>
    </OutageDisclaimerContainer>
  );
}

export default OutageDisclaimer;
