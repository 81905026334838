import IconUser from '@iabbb/icons/User';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import validator from '@iabbb/utils/validators/validator';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import urljoin from 'url-join';

import { getIsUcpEnabled } from '../selectors';
import { DEFAULT_BBB_NAV_LINKS, GLOBAL_NAV_LINKS } from './constants';
import NavItem from './NavItem';
import DropdownMenu from './styles';
import type { NavLink } from './types';

export interface DropdownMenuProps {
  bbbLinks?: Array<NavLink>;
  handleDismiss: () => void;
  isOpen: boolean;
}

function DropdownMenuComponent({ bbbLinks = [], handleDismiss, isOpen }: DropdownMenuProps) {
  const isUcpEnabled = useSelector(getIsUcpEnabled());
  const [isAnimating, setIsAnimating] = useState(false);

  // 👇 tracking separately from `isOpen` prevents stuttering when `isAnimating` changes
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    if (isVisible === isOpen) {
      return;
    }

    setIsAnimating(true);
    setIsVisible(isOpen);

    setTimeout(() => {
      setIsAnimating(false);
      setIsVisible(isOpen);
    }, 400);
  }, [isOpen, isVisible]);

  const links = useMemo(() => {
    const bbbLinksOrDefault = validator(isNotFalsyOrEmpty)(bbbLinks) ? bbbLinks : DEFAULT_BBB_NAV_LINKS;

    // add UCP link to nav links obj if applicable
    let UPDATED_GLOBAL_NAV_LINKS = GLOBAL_NAV_LINKS;
    if (isUcpEnabled) {
      UPDATED_GLOBAL_NAV_LINKS = [
        {
          id: 'kqio5s9bg',
          href: urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/account/register'),
          Icon: IconUser,
          text: 'Consumer Account',
        },
        ...GLOBAL_NAV_LINKS,
      ];
    }

    return UPDATED_GLOBAL_NAV_LINKS.concat(
      bbbLinksOrDefault.filter(
        (navLink) => !GLOBAL_NAV_LINKS.find((defaultNavLink) => defaultNavLink.href === navLink.href),
      ),
    );
  }, [bbbLinks, isUcpEnabled]);

  return (
    <>
      <DropdownMenu.Container
        data-is-animating={isAnimating ? '' : undefined}
        data-is-visible={isVisible ? '' : undefined}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            e.preventDefault();
            handleDismiss();
          }
        }}
      >
        <DropdownMenu.List className={clsx('list-reset', 'bg-blue-dark', 'shadow-base', 'text-white')}>
          {links.map((navItem) => (
            <NavItem key={navItem.id} link={navItem} />
          ))}
        </DropdownMenu.List>
      </DropdownMenu.Container>
    </>
  );
}

export default DropdownMenuComponent;
