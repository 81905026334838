import IconBriefcase from '@iabbb/icons/Briefcase';
import hideAt from '@iabbb/shared/styles/hideAt';
import clsx from 'clsx';
import urljoin from 'url-join';

export default function LinkResourcesComponent() {
  return (
    <a
      className={clsx('text-black', 'active-underline', 'with-icon', hideAt.smDown)}
      href={urljoin(process.env.NEXT_PUBLIC_BASE_URL, '/all/business-resources')}
    >
      <IconBriefcase className="text-size-20 text-blue-brand" />
      Resources for Businesses
    </a>
  );
}
