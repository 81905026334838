import { combineReducers } from 'redux';

import findTypeahead from '@iabbb/shared/containers/Header/FindTypeahead/reducer';
import findYourBbbTypeahead from '@iabbb/shared/containers/Header/FindYourBbbTypeahead/reducer';
import localeSelector from '@iabbb/shared/containers/Header/LocaleSelector/reducer';
import navMenu from '@iabbb/shared/containers/Header/NavMenu/reducer';
import nearTypeahead from '@iabbb/shared/containers/Header/NearTypeahead/reducer';
import isUcpEnabled from '@iabbb/shared/containers/Header/Ucp/reducer';

const header = combineReducers({
  findTypeahead,
  findYourBbbTypeahead,
  localeSelector,
  navMenu,
  nearTypeahead,
  isUcpEnabled,
});

export default header;
