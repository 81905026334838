import { produce } from 'immer';

import { SHOW_MENU } from '@iabbb/shared/containers/Header/NavMenu/actions';
import { SHOW_MENU as LOCALE_SHOW_MENU } from '@iabbb/shared/containers/Header/LocaleSelector/actions';

const initialState = {
  menuOpen: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SHOW_MENU:
      draft.menuOpen = action.show;
      break;
    case LOCALE_SHOW_MENU:
      if (action.show) {
        draft.menuOpen = false;
      }
      break;
    default:
  }
}, initialState);
