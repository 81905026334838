import createActions from '@iabbb/shared/components/Typeahead/factories/NameTypeahead/createActions';
import {
  ORGANIZATION_ENTITY_TYPE,
  CATEGORY_ENTITY_TYPE,
  NEAR_ME_ENTITY_TYPE,
} from '@iabbb/shared/containers/Header/FindTypeahead/entityType';
import * as actionTypes from '@iabbb/shared/containers/Header/FindTypeahead/actionTypes';
import { showSpinner } from '@iabbb/shared/states/Global/actions';
import { redirect } from '@iabbb/utils/browser/location';
import getIn from '@iabbb/utils/object/getIn';
import validator from '@iabbb/utils/validators/validator';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';

import { MAX_TYPEAHEAD_RESULTS } from './constants';
import { createSearchPageUrl } from '@iabbb/utils/search/searchUrlUtils';

// build the actions

const {
  typeaheadSuggestionSelected,
  updateTypeaheadResults,
  setTypeaheadInput,
  setTypeaheadSelected,
  clearTypeahead,
  fetchAndUpdateTypeaheadResults,
} = createActions({
  actionTypes,
  entityTypes: [CATEGORY_ENTITY_TYPE, ORGANIZATION_ENTITY_TYPE],
});

export const setFindTypeaheadInput = setTypeaheadInput;
export const clearFindTypeahead = clearTypeahead;
export const updateFindTypeaheadResults = updateTypeaheadResults;
export const findTypeaheadSuggestionSelected = typeaheadSuggestionSelected;

export function fetchAndUpdateFindTypeaheadResults(searchTerm) {
  return (dispatch, getState) => {
    const state = getState();
    const cultureId = getIn(state, ['header', 'nearTypeahead', 'cultureId']);
    const latLng = getIn(state, ['header', 'nearTypeahead', 'selected', 'latLng']);
    dispatch(
      fetchAndUpdateTypeaheadResults({
        searchTerm,
        cultureId,
        latLng,
        maxMatches: MAX_TYPEAHEAD_RESULTS,
      } as never),
    );
  };
}

// add additional actions
function redirectUser({ url }) {
  return (dispatch) => {
    // Only redirect if url is valid
    if (url && url !== 'http://' && url !== 'https://') {
      // If we have a valid URL, throw up a spinner to let the user know we are trying our darnedest.
      // No need to ever hide the spinner since we are going to a new page anyway.
      dispatch(showSpinner());

      redirect(url);

      dispatch({
        type: actionTypes.REDIRECT_USER,
        url,
      });
    }
  };
}

function selectedCategory(selected) {
  return {
    type: actionTypes.SELECT_CATEGORY,
    text: selected.title,
  };
}

export function setSelectedCategory(selected) {
  return (dispatch) => {
    dispatch(selectedCategory(selected));
  };
}

export function setFindTypeaheadSelected(selected) {
  return (dispatch) => {
    dispatch(setTypeaheadSelected(selected));
  };
}

/**
 * Navigates to the selected business profile url if possible
 * @param {array} selected - selected item from the find typeahead
 * @returns {*}
 */
export const goToOrganizationUrlActionCreator = (selected) => (dispatch) => {
  // Check if selected item is valid
  if (validator(isNotFalsyOrEmpty)(selected)) {
    const redirectInfo = {
      url: '',
      type: 'Undefined Type', // Used for GA to describe redirection event
    };
    // Most likely fine, but verify that the selected type is an 'Organization
    if (selected.entityType === ORGANIZATION_ENTITY_TYPE && selected.url) {
      redirectInfo.url = selected.url;
      redirectInfo.type = 'Business Profile';
      return dispatch(redirectUser(redirectInfo));
    }
  }
  return null;
};

/**
 * Navigates to the selected business profile url if possible
 * @param {array} selected - selected item from the find typeahead
 * @returns {*}
 */
export const goToNearMeActionCreator = (selected) => (dispatch) => {
  // Check if selected item is valid
  if (validator(isNotFalsyOrEmpty)(selected)) {
    const redirectInfo = {
      url: '',
      type: 'Undefined Type', // Used for GA to describe redirection event
    };
    // Most likely fine, but verify that the selected type is an 'Organization
    if (selected.entityType === NEAR_ME_ENTITY_TYPE && selected.url) {
      redirectInfo.url = selected.url;
      redirectInfo.type = 'Near Me';
      return dispatch(redirectUser(redirectInfo));
    }
  }
  return null;
};

export const goToPopularCategoryUrlActionCreator = (selected) => (dispatch) => {
  if (validator(isNotFalsyOrEmpty)(selected)) {
    const redirectInfo = {
      url: '',
      type: 'Undefined Type', // Used for GA to describe redirection event
    };
    if (selected.url) {
      redirectInfo.url = selected.url;
      redirectInfo.type = 'Popular Category';
      return dispatch(redirectUser(redirectInfo));
    }
  }
  return null;
};

export const goToNearMeTransformedUrlActionCreator = () => (dispatch, getState) => {
  const state = getState();
  const redirectInfo = {
    url: '',
    type: 'Undefined Type', // Used for GA to describe redirection event
  };
  const newUrl = createSearchPageUrl({ state: state });

  if (newUrl) {
    redirectInfo.url = newUrl;
    redirectInfo.type = 'Popular Category';
    dispatch(redirectUser(redirectInfo));
  }
};
