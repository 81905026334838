const USER_LOCATION = 'iabbb_user_location';
const USER_PHYSICAL_LOCATION = 'iabbb_user_physical_location';
const USER_CULTURE = 'iabbb_user_culture';
const USER_BBB = 'iabbb_user_bbb';
const USER_POSTALCODE = 'iabbb_user_postalcode';
const FIND_LOCATION = 'iabbb_find_location';
const FINDYOURBBB_LOCATION = 'iabbb_findyourbbb_location';
const ACCREDITED_TOGGLE_STATE = 'iabbb_accredited_toggle_state'; // Accredited toggle state for this session, used to control highlighting #WEB-3697
const ACCREDITED_SEARCH = 'iabbb_accredited_search'; //  #WEB-3696
const UNLISTED_BUSINESS = 'iabbb_unlistedbusiness';
const FAC_FORM = 'iabbb_fac_';
const OUTAGE_DISCLAIMER = 'iabbb_outage_disclaimer';
const SESSION_ID = 'iabbb_session_id';
const COOKIES_PREFERENCES_SET = 'iabbb_cookies_preferences_set';
const COOKIES_POLICY = 'iabbb_cookies_policy';

const CookieNames = {
  ACCREDITED_TOGGLE_STATE,
  ACCREDITED_SEARCH,
  FIND_LOCATION,
  FINDYOURBBB_LOCATION,
  USER_BBB,
  USER_CULTURE,
  USER_LOCATION,
  USER_PHYSICAL_LOCATION,
  USER_POSTALCODE,
  UNLISTED_BUSINESS,
  FAC_FORM,
  OUTAGE_DISCLAIMER,
  SESSION_ID,
  COOKIES_PREFERENCES_SET,
  COOKIES_POLICY,
};

export default CookieNames;
