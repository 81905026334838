import SocialLink, { SocialLinkProps } from '@iabbb/shared/components/General/SocialLink';

export type FooterSocialLinkProps = {
  socialLink: Omit<SocialLinkProps, 'color'>;
};

function FooterSocialLink({ socialLink }: FooterSocialLinkProps) {
  return <SocialLink {...socialLink} className="dtm-footer-social-link" />;
}

export default FooterSocialLink;
