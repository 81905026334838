/**
 * Retrieves a typically nested value safely, without throwing an error.
 * Returns undefined or defaultValue if path cannot be found or value found is undefined
 * @param {object} obj Object we are looking in
 * @param {string[]} keyPath Key path to the value
 * @param {*} defaultValue The default value if the result is undefined
 */

function getIn(
  obj: Record<number | string, any> | null,
  keyPath: (string | number)[] = [],
  defaultValue: any = undefined,
) {
  // check basic validity of obj
  if (!obj) {
    return defaultValue;
  }

  let o: Record<number | string, any> | null = obj;
  let i = 0;

  // cycle through each key in array of keys, unless the result is null/undefined
  // output != null returns false for undefined != null && null != null
  while (o != null && i < keyPath.length) {
    // not use `in` operator here, such as if(key in obj), since `in` searches the entire prototype chain
    o = o[keyPath[i++]];
  }

  // index is greater than 0 and we have finished looping, then return the output
  const result = i && i === keyPath.length ? o : undefined;

  return result !== undefined ? o : defaultValue;
}

export default getIn;
