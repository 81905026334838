import type { IconProps } from '@iabbb/icons/utils/Icon';
import React from 'react';

import { CATEGORIES, DEFAULT_ICON } from './constants';

export interface CategoryIconComponentProps extends IconProps {
  categoryName: string;
  style?: React.CSSProperties;
}

function CategoryIconComponent({ categoryName, ...props }: CategoryIconComponentProps) {
  if (!categoryName) {
    return;
  }
  const Icon = CATEGORIES[categoryName.toLowerCase()]?.icon ?? DEFAULT_ICON;
  return <Icon {...props} />;
}

export default React.memo(CategoryIconComponent);
