import IconClose from '@iabbb/icons/Close';
import Button from '@iabbb/bds-react/Button';
import { useDialog } from '@iabbb/shared/components/UI/Dialog';
import SearchForm from '@iabbb/shared/containers/Header/SearchForm';
import clsx from 'clsx';
import { useRef } from 'react';

import MobileFindTypeahead from './FindTypeahead';
import MobileNearTypeahead from './NearTypeahead';
import { MobileSearchDialog, MobileSearchContainer } from './styles';

export type MobileSearchDialogProps = {
  handleCloseModal: () => void;
  open: boolean;
};

function MobileSearchDialogComponent({ handleCloseModal, open }: MobileSearchDialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dialogProps = useDialog(dialogRef, open, handleCloseModal, { autoFocusDialogElement: false });

  return (
    <MobileSearchDialog ref={dialogRef} {...dialogProps}>
      <MobileSearchContainer>
        <SearchForm>
          <div className="stack" style={{ '--stack-space': '0.5rem' } as React.CSSProperties}>
            <div className="repel">
              <Button
                className={clsx('text-white', 'text-size-60', 'leading-1')}
                type="button"
                variant="unstyled"
                onClick={handleCloseModal}
              >
                <IconClose />
                <span className="visually-hidden">close search</span>
              </Button>
              <Button style={{ minWidth: '0', padding: '0.3125rem 0.5rem' }} type="submit" variant="search">
                Search
              </Button>
            </div>
            <MobileFindTypeahead />
            <MobileNearTypeahead />
          </div>
        </SearchForm>
      </MobileSearchContainer>
    </MobileSearchDialog>
  );
}

MobileSearchDialogComponent.displayName = 'MobileSearchDialog';

export default MobileSearchDialogComponent;
