import { produce } from 'immer';

export interface ActionTypes {
  SET_INPUT: string;
  UPDATE_TYPEAHEAD_RESULTS: string;
  SELECT_ITEM: string;
  TYPEAHEAD_RESULTS_FETCHING: string;
  TYPEAHEAD_RESULTS_FETCHING_DONE: string;
}

export interface State {
  input?: string;
  results?: unknown;
  method?: string;
  selected?: unknown;
  isFetching?: boolean;
}

const createReducer = ({ actionTypes, initialState = {} }: { actionTypes: ActionTypes; initialState?: State }) =>
  produce((draft, action) => {
    const {
      SET_INPUT,
      UPDATE_TYPEAHEAD_RESULTS,
      SELECT_ITEM,
      TYPEAHEAD_RESULTS_FETCHING,
      TYPEAHEAD_RESULTS_FETCHING_DONE,
    } = actionTypes;

    switch (action.type) {
      case SET_INPUT:
        draft.input = action.input;
        break;
      case UPDATE_TYPEAHEAD_RESULTS:
        draft.results = action.results;
        break;
      case SELECT_ITEM:
        draft.method = 'selected';
        draft.selected = action.selected;
        break;
      case TYPEAHEAD_RESULTS_FETCHING:
        draft.isFetching = true;
        break;
      case TYPEAHEAD_RESULTS_FETCHING_DONE:
        draft.isFetching = false;
        break;
      default:
    }
  }, initialState);

export default createReducer;
