import { expandUrlMixin } from '@iabbb/shared/styles/utils';

// Hide the element except when printing
const only = `
  @media not print {
    display: none !important;
  }
`;

// Hide the element when printing
const nothing = `
  @media print {
    display: none !important;
  }
`;

// Hides shadow/padding/etc from a card
const collapseContainer = `
  @media print {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }
`;

// Show the URLs of any anchors within the component. You may also control which URLs get displayed by setting the prop to `absolute` or `relative`.
const showLinkUrls = `
  @media print {
    ${expandUrlMixin('a', 'href', true)}
  }
`;

const classNames = {
  collapseContainer: 'bbb__print-collapseContainer',
  showLinkUrls: 'bbb__print-showLinkUrls',
  only: 'bbb__print-only',
  nothing: 'bbb__print-nothing',
  displayBlock: 'bbb__print-display-block',
};

export const styles = {
  collapseContainer,
  nothing,
  only,
  showLinkUrls,
};

export default classNames;
