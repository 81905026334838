import createResultsAction from './createResultsAction';
import createTypeaheadActions from '../GenericTypeahead/createActions';

interface CreateActionsProps {
  actionTypes?: any;
  entityTypes?: any;
  suggestNameApiFetch?: (fetchParams) => Promise<any>;
}
const createActions = ({ actionTypes, entityTypes, suggestNameApiFetch }: CreateActionsProps) => {
  const {
    typeaheadSuggestionSelected,
    fetchingTypeaheadResults,
    fetchingDoneTypeaheadResults,
    updateTypeaheadResults,
    setTypeaheadInput,
    setTypeaheadSelected,
    clearTypeahead,
  } = createTypeaheadActions({ actionTypes, type: 'name' });

  const { fetchAndUpdateTypeaheadResults } = createResultsAction({
    entityTypes,
    actions: {
      fetchingTypeaheadResults,
      fetchingDoneTypeaheadResults,
      updateTypeaheadResults,
    },
    suggestNameApiFetch,
  });

  return {
    typeaheadSuggestionSelected,
    fetchingTypeaheadResults,
    fetchingDoneTypeaheadResults,
    updateTypeaheadResults,
    setTypeaheadInput,
    setTypeaheadSelected,
    clearTypeahead,
    fetchAndUpdateTypeaheadResults,
  };
};

export default createActions;
