import { sendClickNotification } from '@iabbb/utils/api/AdobeTargetApi';
import { createContext, useContext } from 'react';

import { FeatureFlagName as OFeatureFlagName } from './constants';

type FeatureFlagName = (typeof OFeatureFlagName)[keyof typeof OFeatureFlagName];

type FeatureFlag = {
  name: FeatureFlagName;
  metrics: { type: string; eventToken: string }[];
  options: unknown;
};

type FeatureFlagContextProps = {
  featureFlags: FeatureFlag[];
  tntId: string;
};

export const FeatureFlagContext = createContext<FeatureFlagContextProps | null | undefined>(undefined);

type FeatureFlagProviderProps = {
  children: React.ReactNode;
  value: FeatureFlagContextProps | null;
};

export function FeatureFlagProvider({ value, ...props }: FeatureFlagProviderProps) {
  return <FeatureFlagContext.Provider value={value} {...props} />;
}

export function useFeatureFlag<T = unknown>(name: FeatureFlagName) {
  const context = useContext(FeatureFlagContext);

  if (context === undefined) {
    return null;
  }

  // The provider is defined, but we don't have flags: to request failed, user blocked them, etc.
  if (context === null) {
    return null;
  }

  // The next line prevents lint errors when there is only 1 feature flag.
  const flag = context.featureFlags.find((x) => x.name === name);

  if (!flag) {
    return null;
  }

  return {
    options: flag.options as T,
    trackConversion: async () => {
      await sendClickNotification(
        [
          {
            id: flag.name,
            eventTokens: flag.metrics.filter((x) => x.type === 'click').map(({ eventToken }) => eventToken),
          },
        ],
        context.tntId,
      );
    },
  };
}
