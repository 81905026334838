import { URL_SEGMENT_CATEGORY } from '@iabbb/utils/search/constants';

/**
 * Checks if the search is a category url (routed) ex: /us/md/takoma-park/category/tailor, or regular search.
 * Coerces pathname into a boolean
 * @param {Object} location Window.location
 * @param {string} location.pathname The location pathname
 */
const isCategoryUrl = ({ pathname }: { pathname?: string } = {}) =>
  !!pathname && pathname.includes(`/${URL_SEGMENT_CATEGORY}/`);

export default isCategoryUrl;
