import styled from '@emotion/styled';
import clsx from 'clsx';
import { useCallback, useId, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { showNavMenu } from './actions';
import DropdownMenu from './DropdownMenu';
import MenuButton from './MenuButton';

type StyledNavProps = {
  $isOpen: boolean;
};

const StyledNav = styled('nav', { shouldForwardProp: (prop) => prop !== '$isOpen' })<StyledNavProps>`
  align-items: center;
  block-size: 100%;
  color: var(--bds-color-blue-dark);
  display: flex;
  margin-inline-end: -1rem;
  padding-inline: 1rem;
  position: relative;

  ${(props) =>
    props.$isOpen &&
    `
    && { 
      background: var(--bds-color-blue-dark);
      color: var(--bds-color-white);
    }
  `}
`;

export default function NavMenu({ isOpen, links }) {
  const dispatch = useDispatch();
  const navMenuHeadingId = useId();
  const toggleButtonRef = useRef<HTMLButtonElement>(null);

  const handleToggleClick = useCallback(() => {
    dispatch(showNavMenu(!isOpen) as never);
  }, [dispatch, isOpen]);

  const handleDismissExpandedMenu = () => {
    handleToggleClick();

    toggleButtonRef.current?.focus();
  };

  return (
    <StyledNav aria-labelledby={navMenuHeadingId} $isOpen={isOpen}>
      <h2 className="visually-hidden" id={navMenuHeadingId}>
        Navigation menu
      </h2>
      <MenuButton
        aria-expanded={isOpen}
        className={clsx(isOpen ? 'dtm-header-menu-button-opened' : 'dtm-header-menu-button-closed')}
        onClick={handleToggleClick}
        ref={toggleButtonRef}
      />
      <DropdownMenu bbbLinks={links} handleDismiss={handleDismissExpandedMenu} isOpen={isOpen} />
    </StyledNav>
  );
}
