import isValidBbb from '@iabbb/utils/bbbInfo/isValidBbb';
import buildGeoUrl from '@iabbb/utils/bbbInfo/buildGeoUrl';
import { BbbResource } from './types';
/**
 * Creates the geo url ex bbb.org/en/us/md/takoma park or defaults to the fallback url or country url
 * @param {Object} location - location object
 * @param {string} baseUrlWithCulture
 * @param {string} fallbackUrl - The url that will be returned if bbb is invalid
 */
const getGeoUrlOrFallback = (bbb: BbbResource, baseUrl: string, fallbackUrl?: string): string => {
  if (isValidBbb(bbb)) {
    return buildGeoUrl(baseUrl, bbb.country, bbb.state, bbb.citySeo, bbb.cities);
  }
  // default to country landing page if no fallback url
  return fallbackUrl ?? baseUrl;
};

export default getGeoUrlOrFallback;
