import styled from '@emotion/styled';
import media from '@iabbb/shared/styles/media';

export const Top = styled.div`
  a {
    color: var(--bds-color-white);
    text-decoration: none;

    &:hover {
      color: var(--bds-color-orange-light);
    }
  }

  padding-block: 1.875rem;

  ${media.sm} {
    padding-block: 2.25rem;
  }
`;

export const TopContainer = styled.div`
  display: flex;

  > a {
    display: none;
  }

  > div {
    flex-grow: 1;
  }

  ${media.md} {
    align-items: flex-start;
    flex-direction: row;
    gap: 6rem;

    > a {
      display: block;
    }
  }
`;

export const Bottom = styled.div`
  padding-block: 0.75rem;

  a {
    color: var(--bds-color-white);
  }
`;

export const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .copyright {
    text-align: center;
  }

  ${media.md} {
    align-items: flex-start;
    flex-direction: row;

    .copyright {
      flex-basis: 60ch;
      text-align: right;
    }
  }
`;

export const SocialLinkList = styled.ul`
  --gutter: 0.5rem;
  margin-top: 0.625rem;
`;
