import urljoin from 'url-join';

import { API_BASE_URL } from '@iabbb/utils/api/constants';
import request from '@iabbb/utils/api/request';

const buildApiUrl = (baseUrl: string, ...segments: string[]) =>
  urljoin(baseUrl, API_BASE_URL, 'initialize', ...segments);

/**
 * Fetches initialization data for a given app
 * @param {string} baseUrl - base url of the endpoint
 */
export const fetchHeaderFooterInitialState = (baseUrl: string) => {
  const url = buildApiUrl(baseUrl, '/headerfooter');
  return request(url, { credentials: 'include' });
};
