import styled from '@emotion/styled';

// DropDown.List
export default styled.ul`
  background-clip: padding-box;
  border: 1px solid var(--bds-color-gray-60);
  left: 0;
  list-style: none;
  margin: 2px 0 0;
  min-width: 160px;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 100%;
  width: 100%;
  z-index: 1000;
`;
