import Suggestion from '@iabbb/shared/components/Typeahead/Suggestion/styles';
import TypeaheadHighlighter from '@iabbb/shared/components/Typeahead/Highlighter';

export type SuggestionProps = {
  suggestion: any;
  query: string;
};

const SuggestionComponent = ({ suggestion = {}, query }: SuggestionProps) => {
  return (
    <Suggestion>
      <Suggestion.Title>
        <TypeaheadHighlighter text={suggestion.displayText} query={query} />
      </Suggestion.Title>
    </Suggestion>
  );
};

SuggestionComponent.displayName = 'Suggestion';
export default SuggestionComponent;
