import FindTypeahead from '@iabbb/shared/containers/Header/FindTypeahead';

import { MobileTypeahead, MobileTypeaheadWrapper } from '../styles';

function MobileFindTypeaheadComponent() {
  return (
    <MobileTypeaheadWrapper>
      <FindTypeahead
        otherInputProps={{ autoFocus: true }}
        showIconLabel
        styled={MobileTypeahead}
        withPopularCategoriesTitle
      />
    </MobileTypeaheadWrapper>
  );
}

export default MobileFindTypeaheadComponent;
