import IconCloseMedium from '@iabbb/icons/CloseMedium';

import { useTypeaheadContext } from '../';

function ClearComponent() {
  const {
    inputProps: { value: inputValue },
    onClickClear,
    styled: { Clear },
  } = useTypeaheadContext();

  return (
    !!inputValue && (
      <Clear aria-label="clear search" type="button" onClick={onClickClear}>
        <IconCloseMedium />
      </Clear>
    )
  );
}

export default ClearComponent;
