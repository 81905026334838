const createActionTypes = (basePath: string) => ({
  SET_INPUT: `${basePath}/SET_INPUT`,
  TYPEAHEAD_RESULTS_FETCHING: `${basePath}/TYPEAHEAD_RESULTS_FETCHING`,
  TYPEAHEAD_RESULTS_FETCHING_DONE: `${basePath}/TYPEAHEAD_RESULTS_FETCHING_DONE`,
  UPDATE_TYPEAHEAD_RESULTS: `${basePath}/UPDATE_TYPEAHEAD_RESULTS`,
  SELECT_ITEM: `${basePath}/SELECT_ITEM`,
  TYPEAHEAD_SUGGESTION_SELECTED: `${basePath}/TYPEAHEAD_SUGGESTION_SELECTED`,
});

export default createActionTypes;
