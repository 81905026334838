import createActions from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createActions';
import { removeCookie, setCookie } from '@iabbb/shared/states/User/actions';
import getIn from '@iabbb/utils/object/getIn';
import CookieNames from '@iabbb/utils/Storage/CookieNames';

import * as actionTypes from './actionTypes';
import { TYPEAHEAD_STATE_PATH, MAX_TYPEAHEAD_RESULTS } from './constants';

const {
  typeaheadSuggestionSelected,
  updateTypeaheadResults,
  setTypeaheadInput,
  setTypeaheadSelected,
  clearTypeahead,
  setLocationTypeaheadCultureId,
  setLocationTypeahead,
  fetchAndUpdateTypeaheadResults,
} = createActions({ actionTypes });

export const setNearTypeaheadInput = setTypeaheadInput;
export const clearNearTypeaheadExceptCultureId = clearTypeahead;
export const updateNearTypeaheadResults = updateTypeaheadResults;
export const nearTypeaheadSuggestionSelected = typeaheadSuggestionSelected;
export const setNearTypeaheadCultureId = setLocationTypeaheadCultureId;
export const setNearTypeahead = setLocationTypeahead;
export const fetchAndUpdateLocationResults = fetchAndUpdateTypeaheadResults;

// COMPONENT SPECIFIC

export function clearNearTypeaheadAndRemoveCookie() {
  return (dispatch) => {
    dispatch(removeCookie(CookieNames.FIND_LOCATION));
    dispatch(clearNearTypeaheadExceptCultureId());
  };
}

function saveFindLocationIdToCookie(locationId: string, expiration: number) {
  return (dispatch) => {
    dispatch(
      setCookie({
        name: CookieNames.FIND_LOCATION,
        value: locationId,
        expiration,
      }),
    );
  };
}

export function setNearTypeaheadAndSaveCookie(location) {
  return (dispatch) => {
    dispatch(setNearTypeahead(location));
    const cookieExpiration = process.env.NEXT_PUBLIC_COOKIE_EXPIRATION;
    dispatch(saveFindLocationIdToCookie(location.id, cookieExpiration));
  };
}

export function fetchAndUpdateNearTypeaheadResults(searchTerm: string) {
  return (dispatch, getState) => {
    const state = getState();
    const cultureId = getIn(state, [...TYPEAHEAD_STATE_PATH, 'cultureId']);
    const latLng = getIn(state, ['user', 'location', 'latLng']);
    const bbbId = getIn(state, ['user', 'location', 'bbbId']);
    dispatch(
      fetchAndUpdateTypeaheadResults({ searchTerm, maxMatches: MAX_TYPEAHEAD_RESULTS, cultureId, latLng, bbbId }),
    );
  };
}

/**
 * Sets the near typeahead selected field and saves the find location id to a cookie.
 * @param {Object} location User location type object
 */
export function setNearTypeaheadSelected(location) {
  return (dispatch) => {
    dispatch(setTypeaheadSelected(location));

    // WEB-3361: saves cookie on typeahead selection
    if (location.id) {
      const cookieExpiration = process.env.NEXT_PUBLIC_COOKIE_EXPIRATION;
      dispatch(saveFindLocationIdToCookie(location.id, cookieExpiration));
    }
  };
}
