import CategoryIcon from '@iabbb/main/pages/GeoLanding/Hero/TopCategories/CategoryIcon';
import { useTypeaheadContext } from '@iabbb/shared/components/Typeahead/Provider';
import React, { forwardRef } from 'react';

interface AutocompleteProps {
  withPopularCategoriesTitle?: boolean;
}

const Autocomplete = forwardRef<HTMLDivElement, AutocompleteProps>(
  ({ withPopularCategoriesTitle = false }, ref) => {
    const {
      getSectionSuggestions,
      inputProps,
      isFetching,
      multiSection,
      renderSectionTitle,
      renderSuggestion,
      styled: { Container, Input, Suggestions },
      suggestions,
      popularSuggestions,
      getInputProps,
      getItemProps,
      getMenuProps,
      isOpen,
      highlightedIndex,
      openMenu,
    } = useTypeaheadContext();

    const menuProps = getMenuProps();

    return (
      <Container>
        <Input
          {...getInputProps({
            autoCapitalize: 'none',
            autoComplete: 'off',
            onFocus: openMenu,
            ...inputProps,
            'aria-labelledby': undefined, // 👈 already associated with label's "for" attribute
            // downshift places these on the root, but they need to be on the input
            // seems like Downshift may have fixed this with their `use...` hooks
            // if we migrate to those, we probably won't have to monkey patch this
            role: 'combobox',
            'aria-expanded': isOpen,
            'aria-owns': menuProps.id,
          })}
          ref={ref}
        />
        <Suggestions {...menuProps}>
          <Suggestions.Ul>
            {(() => {
              if (!isOpen) {
                return null;
              }

              if (isFetching) {
                return <Suggestions.Li>Loading...</Suggestions.Li>;
              }

              if (isOpen && suggestions.length === 0) {
                return (
                  <>
                    {withPopularCategoriesTitle && (
                      <Suggestions.SectionTitle>Popular categories</Suggestions.SectionTitle>
                    )}
                    {popularSuggestions.map((item, index) => {
                      const isActive = highlightedIndex === index;
                      return (
                        <Suggestions.Li
                          key={item.title}
                          isActive={isActive}
                          {...getItemProps({
                            index,
                            item,
                            className: 'ta-suggestion',
                          })}
                        >
                          <span className="with-icon">
                            <CategoryIcon
                              categoryName={item.title}
                              style={{ '--icon-space': '.5em' } as React.CSSProperties}
                            />
                            {renderSuggestion(item, { query: inputProps.value })}
                          </span>
                        </Suggestions.Li>
                      );
                    })}
                  </>
                );
              }
              if (multiSection) {
                return suggestions.map((set, setIndex) => {
                  return (
                    <React.Fragment key={set.title}>
                      <Suggestions.SectionTitle className="ta-suggestions-title">
                        {renderSectionTitle(set)}
                      </Suggestions.SectionTitle>
                      {getSectionSuggestions(set).map((item, index) => {
                        const isActive =
                          highlightedIndex ===
                          (setIndex >= 1 ? suggestions[setIndex - 1].suggestions.length + index : index);

                        return (
                          <Suggestions.Li
                            key={item.id}
                            isActive={isActive}
                            {...getItemProps({
                              index: setIndex >= 1 ? suggestions[setIndex - 1].suggestions.length + index : index,
                              item,
                              className: 'ta-suggestion',
                            })}
                          >
                            {renderSuggestion(item, { query: inputProps.value })}
                          </Suggestions.Li>
                        );
                      })}
                    </React.Fragment>
                  );
                });
              }

              return suggestions.map((item, index) => {
                const isActive = highlightedIndex === index;
                return (
                  <Suggestions.Li
                    key={item.displayText}
                    isActive={isActive}
                    {...getItemProps({
                      index,
                      item,
                      className: 'ta-suggestion',
                    })}
                  >
                    {renderSuggestion(item, { query: inputProps.value })}
                  </Suggestions.Li>
                );
              });
            })()}
          </Suggestions.Ul>
        </Suggestions>
      </Container>
    );
  },
);

export default Autocomplete;
