import media from '@iabbb/shared/styles/media';
import styled from '@emotion/styled';

import CloseButton from './CloseButton';
import Header from './Header';
import Wrapper from './Wrapper';

const Modal = styled.div`
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.42);
  display: block;
  padding: 28px 20px 26px 20px;
  position: fixed;
  left: 10px;
  right: 10px;
  z-index: 601;

  ${media.md} {
    position: absolute;
    top: 0;
    left: auto;
    min-width: 306px;
    right: 0;
  }
`;

Modal.CloseButton = CloseButton;
Modal.Header = Header;
Modal.Wrapper = Wrapper;

export default Modal;
