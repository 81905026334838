import { useId } from 'react';

/**
 * A honeypot is a field that is not available to users but a bot would be tricked into filling out and submitting.
 * Since this field is hidden visually, the only time that it would be filled out is by a bot.
 * We can reject any submission that has this field filled out.
 * `aria-hidden` hides the field from screen reader users, `.visually-hidden` hides it from sighted users,
 * and `tabIndex=-1` makes it unfocusable for keyboard users.
 * Read more: https://front-end.social/@heydon/111204680660492741
 * @param props - Props to pass to the <input /> component
 * @returns Input and label for visually hidden, non-focusable honeypot field.
 */
export default function HoneypotField(props: React.ComponentPropsWithoutRef<'input'>) {
  const id = useId();

  return (
    <label htmlFor={id} aria-hidden className="visually-hidden">
      Honeypot
      <input id={id} type="radio" name="honeypot" tabIndex={-1} value="1" {...props} />
    </label>
  );
}

HoneypotField.displayName = 'HoneypotField';
