import styled from '@emotion/styled';

// DropDown.Item
export default styled.li`
  color: var(--bds-color-blue-dark);
  cursor: pointer;
  font-weight: bold;
  justify-content: flex-start;
  padding: 0.75rem;
  text-align: left;
  transition: color 300ms;
  width: 100%;

  &:focus {
    background: var(--bds-color-gray-30);
  }

  & + & {
    border-top: 1px solid var(--bds-color-gray-40);
  }
`;
