import type { ImgAttributes } from './types';
import { isCloudinaryUrl } from './utils';

/**
 * Returns Cloudinary-driven src and srcSet attributes, optimizing images according to user-supported pixel density and provided widths. For examples of usage, check the `README.md` in this folder.
 *
 * @param url - The image URL to transform
 * @param alt - The image alt text
 * @param width - The width to use for image optimization. Can either be A) a number or B) an array of objects containing conditions and image sizes.
 * @param additionalTransformations - Additional transformations to pass to every image
 * @param includeHighResVersion - Should a high res verison of the image be included?
 *   Should be true in most cases, but in some cases, we can't guarantee that we receive high res images, and serving high res versions of small images degrades UX.
 * @returns The Cloudinary-driven src and srcSet attributes
 *
 */
export default function buildCloudinaryImgAttributes(
  url: string,
  width: number | null,
  additionalTransformations: string[] | undefined = [],
  includeHighResVersion = true,
): ImgAttributes {
  if (!isCloudinaryUrl(url)) {
    return {
      src: url,
    };
  }

  const transformations = [...additionalTransformations, ...(width ? [`w_${width}`] : [])];

  return {
    src: `${url}?tx=${transformations.join(',')}`,
    srcSet: includeHighResVersion ? `${url}?tx=${[...transformations, 'dpr_2.0'].join(',')} 2x` : undefined,
  };
}
