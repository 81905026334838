import IconClose from '@iabbb/icons/Close';
import Button from '@iabbb/bds-react/Button';
import clsx from 'clsx';
import { useRef } from 'react';

import useDialog from './useDialog';

import './styles.css';

export interface DialogProps extends React.ComponentPropsWithoutRef<'dialog'> {
  autoFocusDialogElement?: boolean;
  close: () => void;
  displayOnPageLoad?: boolean;
  isOpen: boolean;
  pageLoadPriority?: number;
  withCloseButton?: boolean;
  withTransitions?: boolean;
}

export default function DialogComponent({
  autoFocusDialogElement = true,
  children,
  className,
  close,
  displayOnPageLoad = false,
  isOpen = false,
  pageLoadPriority,
  withCloseButton = true,
  withTransitions = false,
  ...props
}: DialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  const dialogProps = useDialog(dialogRef, isOpen, close, {
    autoFocusDialogElement,
    displayOnPageLoad,
    pageLoadPriority,
    withTransitions,
  });

  return (
    <dialog className={clsx('dialog', className)} ref={dialogRef} {...dialogProps} {...props}>
      {withCloseButton && (
        <Button className="dialog-close" onClick={close} type="button" variant="unstyled">
          <IconClose className="text-size-40" />
          <span className="visually-hidden">close</span>
        </Button>
      )}
      {children}
    </dialog>
  );
}
