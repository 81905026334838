export const UPDATE_LEGACY_DATA_LAYER_FIND_METHOD = 'states/Analytics/UPDATE_LEGACY_DATA_LAYER_FIND_METHOD';
export const UPDATE_LEGACY_DATA_LAYER_FIND_TYPEAHEAD_INPUT =
  'states/Analytics/UPDATE_LEGACY_DATA_LAYER_FIND_TYPEAHEAD_INPUT';
export const UPDATE_LEGACY_DATA_LAYER_NEAR_METHOD = 'states/Analytics/UPDATE_LEGACY_DATA_LAYER_NEAR_METHOD';
export const UPDATE_LEGACY_DATA_LAYER_NEAR_TYPEAHEAD_INPUT =
  'states/Analytics/UPDATE_LEGACY_DATA_LAYER_NEAR_TYPEAHEAD_INPUT';
export const UPDATE_LEGACY_DATA_LAYER_SEARCH_RESULTS = 'states/Analytics/UPDATE_LEGACY_DATA_LAYER_SEARCH_RESULTS';
export const UPDATE_LEGACY_DATA_LAYER_SORT = 'states/Analytics/UPDATE_LEGACY_DATA_LAYER_SORT';
export const UPDATE_LEGACY_DATA_LAYER_USED_TYPEAHEADS =
  'states/Analytics/UPDATE_LEGACY_DATA_LAYER_USED_TYPEAHEADS';
