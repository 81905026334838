import createTypeaheadActionTypes from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createActionTypes';

const createActionTypes = (basePath: string) => {
  const typeaheadActions = createTypeaheadActionTypes(basePath);

  return {
    ...typeaheadActions,
    SET_TYPEAHEAD_CULTURE_ID: `${basePath}/SET_TYPEAHEAD_CULTURE_ID`,
  };
};

export default createActionTypes;
