import PropTypes from 'prop-types';
import React from 'react';

import Item from './styles';

const CountryDropdownItem = React.memo(({ culture }) => {
  return (
    <Item>
      <Item.FlagImg {...culture.flagImgAttrs} alt="" height="30" width="40" />
      <Item.Label>{culture.countryName}</Item.Label>
    </Item>
  );
});

CountryDropdownItem.propTypes = { culture: PropTypes.object.isRequired };

CountryDropdownItem.displayName = 'Country Dropdown Item';
export default CountryDropdownItem;
