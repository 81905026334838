/**
 * Removes all HTML tags from a string and leaves the inner text.
 * @param {string} str String containing possible HTML tags
 */
function removeHtmlTags(str: string): string {
  if (typeof str === 'string') {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  }

  return str;
}

export default removeHtmlTags;
