const TRIM_START = 'TRIM_START';
const TRIM_END = 'TRIM_END';

const trim = (str: string, chars: string, side: string | null = null): string => {
  let result = str;

  if (side !== TRIM_END) {
    result = result.replace(new RegExp(`^[${chars}]+`), '');
  }

  if (side !== TRIM_START) {
    result = result.replace(new RegExp(`[${chars}]+$`), '');
  }

  return result;
};

export const trimStart = (str, chars) => trim(str, chars, TRIM_START);

export const trimEnd = (str, chars) => trim(str, chars, TRIM_END);

export default trim;
