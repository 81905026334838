import createActionTypes from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createActionTypes';

const basePath = 'Header/FindTypeahead';
const actionTypes = createActionTypes(basePath);

export const REDIRECT_USER = `${basePath}/REDIRECT_USER`;
export const SELECT_CATEGORY = `${basePath}/SELECT_CATEGORY`;

export const {
  SET_INPUT,
  TYPEAHEAD_RESULTS_FETCHING,
  TYPEAHEAD_RESULTS_FETCHING_DONE,
  UPDATE_TYPEAHEAD_RESULTS,
  SELECT_ITEM,
  TYPEAHEAD_SUGGESTION_SELECTED,
} = actionTypes;
