import media from '@iabbb/shared/styles/media';
import styled from '@emotion/styled';

export default styled.a`
  display: none;
  gap: 1.6875rem;
  height: 100%;
  text-decoration: none;

  ${media.md} {
    display: flex;
  }

  .logo-img {
    background: var(--bds-color-blue-brand);
    color: var(--bds-color-white);
    display: flex;
    margin-block-start: calc(-1 * var(--utility-bar-height));
    padding-inline: 1.3125rem 1rem; // 👈 inline end padding is smaller to account for the reg mark

    svg {
      width: 2.875rem;
    }
  }

  .logo-text {
    color: var(--bds-color-black);
    font-size: var(--bds-font-size-70);
    padding-block: 1rem 1.1875rem;

    sup {
      font-size: var(--bds-font-size-40);
      top: -0.25em;
    }
  }
`;
