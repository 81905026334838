import styled from '@emotion/styled';
import Button from '@iabbb/bds-react/Button';
import media from '@iabbb/shared/styles/media';

export default styled(Button)`
  && {
    --icon-space: 0.4375rem;
    color: var(--bds-color-black);

    ${media.md} {
      --icon-space: 0.25rem;

      svg {
        font-size: var(--bds-font-size-30);
      }
    }
  }
`;
