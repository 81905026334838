import styled from '@emotion/styled';

const StyledHeader = styled.header`
  --utility-bar-height: 1.625rem;
  background-color: var(--bds-color-white);
  position: relative;
  z-index: 3; // WEB-7604: ensure navigation shows over AEM videos

  &:last-of-type {
    box-shadow: var(--bds-shadow-base);
  }
`;

export default StyledHeader;
