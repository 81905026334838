import styled from '@emotion/styled';
import { css } from '@emotion/react';

type LabelProps = {
  variant?: 'location';
};

// InputGroup.Label
export default styled('label', { shouldForwardProp: (prop) => prop !== 'variant' })<LabelProps>`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-weight: var(--bds-font-weight-bold);
  margin-inline-start: 0.75rem;

  ${({ variant }) => {
    switch (variant) {
      case 'location':
        return css`
          color: var(--bds-color-blue-medium-87);
          font-size: 0;
          justify-content: center;
          margin: 0;
          padding: 0;
          width: 35px;

          svg {
            font-size: var(--bds-font-size-30);
          }

          + .ta-container {
            border-left: 1px solid var(--bds-color-gray-60);
            margin-left: 0 !important;
            padding-left: 0.75rem;
          }
        `;
      default:
        return '';
    }
  }}
`;
