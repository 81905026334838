import { produce } from 'immer';
import reduceReducers from '@iabbb/context/Redux/reduceReducers';
import createTypeaheadReducer, {
  ActionTypes,
  State,
} from '@iabbb/shared/components/Typeahead/factories/GenericTypeahead/createReducer';

interface TypeaheadActionTypes extends ActionTypes {
  SET_TYPEAHEAD_CULTURE_ID: string;
}

interface TypeaheadInitialState extends State {
  cultureId?: string;
}

const createReducer = ({
  actionTypes,
  initialState = {},
}: {
  actionTypes: TypeaheadActionTypes;
  initialState: TypeaheadInitialState;
}) => {
  const typeaheadReducer = createTypeaheadReducer({ actionTypes, initialState });
  const locationReducer = produce((draft, action) => {
    const { SET_TYPEAHEAD_CULTURE_ID } = actionTypes;

    switch (action.type) {
      case SET_TYPEAHEAD_CULTURE_ID:
        draft.cultureId = action.cultureId;
        break;
      default:
    }
  }, initialState);

  return reduceReducers(typeaheadReducer, locationReducer, initialState as any);
};

export default createReducer;
