import queryString from '@iabbb/utils/url/qs';

import { FILTER_URL_SEGMENTS } from './constants';

/*
 * Checks to see if the current URL has filters in it;
 * if it does, it returns an object containing them.
 */
const getFiltersFromCurrentUrl = (location: Location | URL) => {
  const currentQueryParams = queryString.parse(location.search);
  // If there's no filter IDs in the URL or no filter URL segments, let's just return null now.

  if (!FILTER_URL_SEGMENTS.some((param) => param in currentQueryParams)) {
    return null;
  }
  // Otherwise, we return a compact object with the mapped filter values.
  return FILTER_URL_SEGMENTS.reduce((acc, filterUrlKey) => {
    const value = currentQueryParams[filterUrlKey];
    if (value) {
      acc[filterUrlKey] = value;
    }
    return acc;
  }, {});
};

export default getFiltersFromCurrentUrl;
