import getIn from '@iabbb/utils/object/getIn';
import createActions from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createActions';
import * as actionTypes from './actionTypes';
import { MAX_TYPEAHEAD_RESULTS } from './constants';

const {
  updateTypeaheadResults,
  setTypeaheadInput,
  setTypeaheadSelected,
  clearTypeahead,
  setLocationTypeahead,
  fetchAndUpdateTypeaheadResults,
} = createActions({ actionTypes });

export const setHomeLocationTypeaheadInput = setTypeaheadInput;
export const clearHomeLocationTypeahead = clearTypeahead;
export const updateHomeLocationTypeaheadResults = updateTypeaheadResults;
export const setHomeLocationTypeahead = setLocationTypeahead;
export const setHomeLocationTypeaheadSelected = setTypeaheadSelected;

export function fetchAndUpdateHomeLocationTypeaheadResults(searchTerm: string) {
  return (dispatch, getState) => {
    const state = getState();
    const cultureId = getIn(state, ['header', 'localeSelector', 'cultureId']);
    const bbbId = getIn(state, ['user', 'location', 'bbbId']);
    dispatch(fetchAndUpdateTypeaheadResults({ searchTerm, maxMatches: MAX_TYPEAHEAD_RESULTS, cultureId, bbbId }));
  };
}
