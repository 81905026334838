import styled from '@emotion/styled';
import Button from '@iabbb/bds-react/Button';

export default styled(Button)`
  && {
    color: inherit;
    line-height: 1;
    padding: 0.5rem;
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
  }
`;
