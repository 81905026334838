import getIn from '@iabbb/utils/object/getIn';
import isNotFalsyOrEmpty from '@iabbb/utils/validators/isNotFalsyOrEmptyValidator';
import getCountryCodeFromCultureOrDefault from '@iabbb/utils/culture/getCountryCodeFromCultureOrDefault';
import validator from '@iabbb/utils/validators/validator';
import createActions from '@iabbb/shared/components/Typeahead/factories/LocationTypeahead/createActions';
import { fetchSuggestBbbLocation } from '@iabbb/utils/api/SuggestApi';
import { TYPEAHEAD_STATE_PATH, MAX_TYPEAHEAD_RESULTS } from './constants';
import * as actionTypes from './actionTypes';

const {
  updateTypeaheadResults,
  setTypeaheadInput,
  setTypeaheadSelected,
  setLocationTypeaheadCultureId,
  setLocationTypeahead,
  fetchAndUpdateTypeaheadResults,
} = createActions({ actionTypes, suggestApiFetch: fetchSuggestBbbLocation });

export const setLocateBbbTypeaheadInput = setTypeaheadInput;
export const updateLocateBbbTypeaheadResults = updateTypeaheadResults;
export const setLocateBbbTypeaheadCultureId = setLocationTypeaheadCultureId;
export const setLocateBbbTypeahead = setLocationTypeahead;
export const setLocateBbbTypeaheadSelected = setTypeaheadSelected;
export const fetchAndUpdateLocationResults = fetchAndUpdateTypeaheadResults;

// COMPONENT SPECIFIC

export function clearLocateBbbTypeaheadResultsIfNotEmpty() {
  return (dispatch, getState) => {
    const results = getIn(getState(), [...TYPEAHEAD_STATE_PATH, 'results']);
    if (validator(isNotFalsyOrEmpty)(results)) {
      dispatch(updateLocateBbbTypeaheadResults([]));
    }
  };
}
export function fetchAndUpdateLocateBbbTypeaheadResults(searchTerm: string) {
  return (dispatch, getState) => {
    const state = getState();
    const cultureId = getIn(state, [...TYPEAHEAD_STATE_PATH, 'cultureId']);
    const bbbId = getIn(state, ['user', 'location', 'bbbId']);
    dispatch(fetchAndUpdateTypeaheadResults({ searchTerm, maxMatches: MAX_TYPEAHEAD_RESULTS, cultureId, bbbId }));
  };
}
/**
 * Try to get the bbb location any way possible
 *
 * @export tryGetBbbLocation
 * @returns Promise(location)
 */
export function tryGetBbbLocationAsync() {
  return (_dispatch, getState) => {
    const state = getState();
    const lbTypeaheadState = getIn(state, [...TYPEAHEAD_STATE_PATH]);
    const selected = lbTypeaheadState.selected;

    // User has for sure selected a bbb from the locate bbb on map widget so no need to dispatch more then one action
    if (validator(isNotFalsyOrEmpty)(selected)) {
      return Promise.resolve(selected);
    }

    const cultureId = lbTypeaheadState.cultureId;
    const input = lbTypeaheadState.input;
    const userLocationBbbId = getIn(state, ['user', 'location', 'bbbId']);

    const country = getCountryCodeFromCultureOrDefault(cultureId);

    if (input) {
      // Get best match of location
      return fetchSuggestBbbLocation({
        country,
        input,
        maxMatches: 1,
        bbbId: userLocationBbbId,
      }).then((responseData) => {
        if (validator(isNotFalsyOrEmpty)(responseData)) {
          return Promise.resolve(responseData[0]);
        }
        return Promise.reject();
      });
    }
    return Promise.reject();
  };
}

export function tryGetBbbLocationFromQueryAsync(query: string) {
  return (_dispatch, getState) => {
    const state = getState();
    const lbTypeaheadState = getIn(state, [...TYPEAHEAD_STATE_PATH]);

    const cultureId = lbTypeaheadState.cultureId;
    const userLocationBbbId = getIn(state, ['user', 'location', 'bbbId']);

    const country = getCountryCodeFromCultureOrDefault(cultureId);

    // Get best match of location
    return fetchSuggestBbbLocation({
      country,
      input: query,
      maxMatches: 1,
      bbbId: userLocationBbbId,
    }).then((responseData) => {
      if (validator(isNotFalsyOrEmpty)(responseData)) {
        return Promise.resolve(responseData[0]);
      }
      return Promise.reject();
    });
  };
}
